import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';

@Injectable()
export class MetaService {
  constructor(
    private titleService: Title,
    private meta: Meta,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  updateTitle(title?: string) {
    if (!title) {
      this.router.events
        .pipe(
          filter(event => event instanceof NavigationEnd),
          map(() => this.activatedRoute),
          map(route => {
            while (route.firstChild) {
              route = route.firstChild;
            }
            return route;
          }),
          filter(route => route.outlet === 'primary'),
          mergeMap(route => route.data)
        )
        .subscribe(event => {
          this.titleService.setTitle(event['title']);
          this.meta.updateTag({
            name: 'description',
            content: event['description']
          });
        });
    } else {
      this.titleService.setTitle(title);
    }
  }

  addMeta(name: string, content: string) {
    this.meta.addTag({ name, content });
  }
}
