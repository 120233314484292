import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'timeSubtext'
})
export class TimeSubtextPipe implements PipeTransform {
  transform(date) {
    const localDate = moment();
    if (date.day() === localDate.day()) return '';
    else if (
      date.day() - localDate.day() === 1 ||
      date.day() - localDate.day() === -6
    )
      return 'tomorrow';
    return date.format('ddd DD MMM');
  }
}
