import {
  Component,
  Output,
  EventEmitter,
  OnInit,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges
} from '@angular/core';
import { FlightManagerService } from '../../services/flight-manager/flight-manager.service';
import { Flight } from 'app/shared';
import * as moment from 'moment';

import { Subscription } from 'rxjs';
import { Location } from '@angular/common';

@Component({
  selector: 'app-basket-flight',
  templateUrl: './basket-flight.component.html',
  styleUrls: ['./basket-flight.component.css']
})
export class BasketFlightComponent implements OnInit, OnChanges, OnDestroy {
  @Input() flight: Flight;
  @Input() passengerPage: boolean;
  @Input() paymentPage: boolean;
  @Input() isOneWay: boolean;
  @Input() conditionsVisible: boolean;
  @Input() totalDuration: moment.Duration = moment.duration(0, 'hours');
  @Input() totalDurationOfReturnFlights: moment.Duration = moment.duration(
    0,
    'hours'
  );
  @Output() showFlightDetailsOutput: EventEmitter<void> = new EventEmitter();
  @Output() showBreakdownDetailsOutput: EventEmitter<void> = new EventEmitter();
  @Output() showConditions: EventEmitter<void> = new EventEmitter();

  adult: number;
  child: number;
  infants: number;
  ticketCount: number;

  passengers: Array<object>;

  priceBreakdownDetails: any;
  priceBreakdownSub: Subscription;

  outboundWillArriveNextDay: boolean = false;
  inboundWillArriveNextDay: boolean = false;

  dayOfOutboundArrival: number;
  dayOfInboundArrival: number;

  constructor(
    private flightManagerService: FlightManagerService,
    private location: Location
  ) {}

  ngOnInit() {
    this.passengers = this.flightManagerService.passengers;
    this.priceBreakdownSub =
      this.flightManagerService.passengerDetails.subscribe(data => {
        this.priceBreakdownDetails = data;
      });
  }

  showFlightDetails() {
    this.showFlightDetailsOutput.emit();
  }

  showBreakDownDetails() {
    this.showBreakdownDetailsOutput.emit();
  }

  willArriveNextDay(
    flight: Flight,
    outboundTicket: boolean,
    returnTicket: boolean
  ) {
    if (
      this.flight &&
      this.flight.outboundTickets.length &&
      outboundTicket === true
    ) {
      const result = this.flightManagerService.willArriveNextDay(
        flight.outboundTickets[0],
        flight.outboundTickets[flight.outboundTickets.length - 1]
      );
      this.outboundWillArriveNextDay = result?.willArriveNextDay;
      this.dayOfOutboundArrival = result?.dayOfArrival;
    }

    if (flight && flight.returnTickets.length && returnTicket === true) {
      const result = this.flightManagerService.willArriveNextDay(
        flight.returnTickets[0],
        flight.returnTickets[flight.returnTickets.length - 1]
      );
      this.inboundWillArriveNextDay = result?.willArriveNextDay;
      this.dayOfInboundArrival = result?.dayOfArrival;
    }
  }

  navigateToPassangerPage() {
    this.location.back();
  }

  showFareRules(e) {
    this.showConditions.emit(e);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.flight.currentValue) {
      if (
        changes.flight.currentValue.outboundTickets.length &&
        !changes.flight.currentValue.returnTickets.length
      ) {
        this.willArriveNextDay(changes.flight.currentValue, true, false);
      } else if (
        changes.flight.currentValue.outboundTickets.length &&
        changes.flight.currentValue.returnTickets.length
      ) {
        this.willArriveNextDay(changes.flight.currentValue, true, true);
      }
    }
  }

  ngOnDestroy() {
    this.priceBreakdownSub.unsubscribe();
  }
}
