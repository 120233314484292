import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[numberOnly]'
})
export class NumberOnlyDirective {
  constructor(private elementRef: ElementRef) {}

  @HostListener('keydown', ['$event'])
  onKeyDown(e: KeyboardEvent) {
    const inputValue = (e.target as HTMLInputElement).value;
    const key = e.key;
    const pattern = new RegExp(/^[0-9]$/);
    // Allow numeric keys, backspace, and arrow keys
    if (pattern.test(key) || key === 'Backspace' || /^Arrow/.test(key)) {
      return;
    }

    e.preventDefault();

    const regexp = /^[0-9]\d*$/;
    const match = regexp.test(inputValue);

    if (match) {
      this.elementRef.nativeElement.value = inputValue;
    }
  }
}
