import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterArrayPipe } from './filter-array/filter-array.pipe';
import { AmDifferenceCustomPipe } from './am-difference-custom/am-difference-custom.pipe';
import { LimitToPipe } from './limit-to/limit-to.pipe';
import { MomentDiffPipe } from './moment-diff/moment-diff.pipe';
import { DecimalToTimespanPipe } from './decimal-to-timespan/decimal-to-timespan.pipe';
import { ChangeToDecimal } from './change-date-to-decimal/change-date-to-decimal.pipe';
import { PriceRoundPipe } from './price-round/price-round.pipe';
import { RoundCentsPipe } from './round-cents/round-cents.pipe';
import { AddNewLine } from './add-new-line/add-new-line.pipe';
import { TimeSubtextPipe } from './time-subtext/time-subtext.pipe';
import { HighlightPipe } from './highlight/highlight.pipe';
import { FilterAirportsPipe } from './filter-airports/filter-airpots.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [
    FilterArrayPipe,
    AmDifferenceCustomPipe,
    LimitToPipe,
    MomentDiffPipe,
    DecimalToTimespanPipe,
    ChangeToDecimal,
    PriceRoundPipe,
    RoundCentsPipe,
    AddNewLine,
    TimeSubtextPipe,
    HighlightPipe,
    FilterAirportsPipe
  ],
  exports: [
    FilterArrayPipe,
    AmDifferenceCustomPipe,
    LimitToPipe,
    MomentDiffPipe,
    DecimalToTimespanPipe,
    ChangeToDecimal,
    PriceRoundPipe,
    RoundCentsPipe,
    AddNewLine,
    TimeSubtextPipe,
    HighlightPipe,
    FilterAirportsPipe
  ]
})
export class PipesModule {}
