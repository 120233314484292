import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HttpClient } from '@angular/common/http';

import { ApiSessionService } from 'services/api-session/api-session.service';
import { RestService } from 'app/services/rest/rest.service';
import { ConfigService } from 'app/services/config/config.service';
import { FlightManagerService } from './flight-manager/flight-manager.service';
import { AlertService } from 'app/services/alert/alert.service';
import { ClockService } from 'services/clock/clock.service';
import { GlobalErrorHandler } from 'app/services/global-error-handler/global-error-handler.service';
import { MetaService } from 'services/meta/meta.service';
import { SessionExpirationService } from 'services/session-expiration/session-expiration.service';
import { FiltersService } from './filters/filters.service';

@NgModule({
  imports: [CommonModule, HttpClientModule],
  providers: [
    ConfigService,
    GlobalErrorHandler,
    HttpClient,
    RestService,
    MetaService,
    ApiSessionService,
    ClockService,
    AlertService,
    FlightManagerService,
    FiltersService,
    SessionExpirationService
  ]
})
export class ServicesModule {}
