import { Component, OnInit } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  UntypedFormBuilder
} from '@angular/forms';
import { CustomValidators } from 'ng2-validation';
//import { CustomValidators } from 'ngx-custom-validators';
import { RestService } from 'services/rest/rest.service';
import { AlertService } from '../../services/alert/alert.service';

@Component({
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.css']
})
export class NewsletterComponent implements OnInit {
  form: UntypedFormGroup;
  formSubmitAttempt = false;

  firstName: string;
  email: string;

  submitted: boolean;

  focused: boolean;

  constructor(
    private rest: RestService,
    private alert: AlertService
  ) {}

  ngOnInit(): void {
    this.form = new UntypedFormBuilder().group({
      firstName: [null, Validators.compose([Validators.required])],
      email: new UntypedFormControl('', [
        Validators.required,
        CustomValidators.email
      ])
    });
  }

  onSubmit() {
    this.formSubmitAttempt = true;

    if (this.form.valid) {
      const payload = {
        firstName: this.form.value.firstName,
        email: this.form.value.email
      };

      this.rest.subscribeToNewsletter(payload).subscribe(
        () => {
          this.submitted = true;
          this.alert.followingRunToFly(
            this.capitalizeFirstLetter(this.firstName)
          );
        },
        () => {
          this.alert.errorOnSubscription();
        }
      );
    }
  }

  capitalizeFirstLetter(name: string) {
    name = name.toLowerCase();
    for (let i = 0; i < name.length; i++) {
      i = 0;
      if (
        (name.charCodeAt(i) > 97 || name.charCodeAt(i) == 97) &&
        (name.charCodeAt(i) < 122 || name.charCodeAt(i) == 122)
      ) {
        name = name.charAt(0).toUpperCase() + name.slice(1);
        return name;
      } else {
        name = name.slice(1);
      }
    }
  }
}
