import { Component, Input } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-wizard',
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.css']
})
export class WizardComponent {
  @Input() activeTabIndex: number;
  @Input() isOneWay: boolean;

  constructor(private location: Location) {}

  goBack(index) {
    if (this.activeTabIndex && this.activeTabIndex != 5) {
      let count = this.activeTabIndex - index;
      while (count > 0) {
        this.location.back();
        count--;
      }
    } else {
      return;
    }
  }
}
