<div
  class="filters-box filters-box-desktop"
  [ngClass]="{ 'first-box': name === 'Departure day' }">
  <div class="name-section" (click)="toggleFilterCollapse(name)">
    <div class="section-title">
      <div class="name-item" *ngIf="name === 'Departure day' && items.length">
        <img
          class="filter-icon"
          src="assets/img/filter-icons/departure-day.svg"
          alt="img" />
        {{ name }}
      </div>
      <div class="name-item" *ngIf="name === 'Destination type' && items.length">
        <img
          class="filter-icon"
          src="assets/img/filter-icons/compass-rose.svg"
          alt="img" />
        {{ name }}
      </div>
      <div class="name-item" *ngIf="name === 'Stops' && items.length">
        <img
          class="filter-icon-stops"
          src="assets/img/filter-icons/stops.svg"
          alt="img" />
        {{ name }}
      </div>
      <div class="name-item" *ngIf="name === 'Airline' && items.length">
        <img
          class="filter-icon"
          src="assets/img/filter-icons/airline.svg"
          alt="img" />
        {{ name }}
      </div>
      <div
        class="name-item"
        *ngIf="
          name === 'Price' &&
          desktop &&
          items.length &&
          (length_of_flights > 1 || typeOfAppliedFilter === 'Price')
        ">
        <img
          class="filter-icon-price"
          src="assets/img/filter-icons/price.svg"
          alt="img" />
        {{ name }}
      </div>
      <div
        class="name-item"
        *ngIf="name === 'Price' && !desktop && items.length">
        <img
          class="filter-icon-price"
          src="assets/img/filter-icons/price.svg"
          alt="img" />
        {{ name }}
      </div>
      <div
        class="name-item"
        *ngIf="
          name === 'Time left' &&
          desktop &&
          items.length &&
          (length_of_flights > 1 || typeOfAppliedFilter === 'Time left')
        ">
        <img
          class="filter-icon-time-left"
          src="assets/img/filter-icons/time-left.svg"
          alt="img" />
        {{ name }}
      </div>
      <div
        class="name-item"
        *ngIf="name === 'Time left' && !desktop && items.length">
        <img
          class="filter-icon-time-left"
          src="assets/img/filter-icons/time-left.svg"
          alt="img" />
        {{ name }}
      </div>
      <div class="name-item" *ngIf="name === 'Destination' && items.length">
        <img
          class="filter-icon"
          src="assets/img/filter-icons/wolrd-area.svg"
          alt="img" />
        World area
      </div>

      <span class="filters-count" *ngIf="filter_length && !filterCollapse[name]"
        >({{ name !== 'Price' && name !== 'Time left' ? filter_length + ' ' : '' }}applied)</span
      >
    </div>

    <i *ngIf="!filterCollapse[name]" class="flaticon-agree"></i>
    <i *ngIf="filterCollapse[name]" class="flaticon-agree rotate"></i>
  </div>
  <div
    *ngIf="(name === 'Destination' || name === 'Stops') && filterCollapse[name]"
    class="from-text">
    From
  </div>
  <ul
    class="list"
    [ngClass]="items.length <= 1 ? 'short-list' : 'large-list'"
    *ngIf="name === 'Departure day' && items.length && filterCollapse[name]">
    <li *ngFor="let i = index; let item; of: items">
      <input
        [(ngModel)]="item.isChecked"
        type="checkbox"
        id="{{ name }}-{{ i }}"
        (click)="valueChangeOfInputs(name)"
        (change)="filterBy(typeInString, item.filterBy)" />
      <label class="sort-name" for="{{ name }}-{{ i }}">
        <span class="input"> </span>{{ item.value }}
        <div *ngIf="item.count || item.count === 0" class="number">
          ({{ item.count }})
        </div>
      </label>
    </li>
  </ul>
  <ul
    class="list"
    *ngIf="name === 'Destination type' && filterCollapse[name]">
    <li *ngFor="let i = index; let item; of: items">
      <input
        [(ngModel)]="item.isChecked"
        type="checkbox"
        id="{{ name }}-{{ i }}"
        (click)="valueChangeOfInputs(name)"
        (change)="filterBy(typeInString, item.filterBy)" />
      <label class="sort-name" for="{{ name }}-{{ i }}">
        <img
          class="filter-icon"
          [src]="item.imageUrl ? imageUrl + item.imageUrl : getIconName(item.filterBy)"
          alt="img" />
        <span class="input"> </span>{{ item.value }}
        <div *ngIf="item.count || item.count === 0" class="number">
          ({{ item.count }})
        </div>
      </label>
    </li>
  </ul>
  <ul class="list" *ngIf="name === 'Destination' && filterCollapse[name]">
    <li *ngFor="let i = index; let item; of: items">
      <input
        [(ngModel)]="item.isChecked"
        (click)="valueChangeOfInputs(name)"
        (change)="filterBy(typeInString, item.filterBy)"
        type="checkbox"
        id="{{ name }}-{{ i }}" />
      <label class="sort-name" for="{{ name }}-{{ i }}">
        <span class="input"></span>{{ item.value }}
        <div *ngIf="item.count || item.count === 0" class="number">
          ({{ item.count }})
        </div></label
      >
      <span class="price">&#163;{{ item.secondText | priceRound }}</span>
    </li>
  </ul>
  <ul class="list" *ngIf="name === 'Stops' && filterCollapse[name]">
    <li *ngFor="let i = index; let item; of: items">
      <input
        [(ngModel)]="item.isChecked"
        (click)="valueChangeOfInputs(name)"
        (change)="filterBy(typeInString, item.filterBy)"
        type="checkbox"
        id="{{ name }}-{{ i }}" />
      <label class="sort-name" for="{{ name }}-{{ i }}">
        <span class="input"></span>{{ item.value }}
        <div *ngIf="item.count || item.count === 0" class="number">
          ({{ item.count }})
        </div></label
      >
      <span class="price">&#163;{{ item.secondText | priceRound }}</span>
    </li>
  </ul>
  <ul
    class="list"
    *ngIf="name === 'Airline' && items.length && filterCollapse[name]">
    <li *ngFor="let i = index; let item; of: items">
      <input
        [(ngModel)]="item.isChecked"
        type="checkbox"
        id="{{ name }}-{{ i }}"
        (click)="valueChangeOfInputs(name)"
        (change)="filterBy(typeInString, item.filterBy)" />
      <label class="sort-name" for="{{ name }}-{{ i }}">
        <span class="input"> </span>{{ item.value }}
        <div *ngIf="item.count || item.count === 0" class="number">
          ({{ item.count }})
        </div>
      </label>
    </li>
  </ul>

  <div
    *ngIf="
      name === 'Price' &&
      filterCollapse[name] &&
      desktop &&
      optionsForPrice.floor &&
      (length_of_flights > 1 || typeOfAppliedFilter === 'Price')
    "
    class="price-container">
    <div class="slider">
      <div>
        <ul
          class="list price-items">
          <li>
            <input
              [(ngModel)]="items[0].topDeal.isChecked"
              type="checkbox"
              id="test"
              (change)="valueChangeOfTopDeals($event)" />
            <label class="sort-name" for="test">
              <span class="input"> </span>Top Deal
              <div class="number">
                ({{ this.items[0].topDeal.count }})
              </div>
            </label>
          </li>
        </ul>
      </div>
      <ngx-slider
        [(value)]="filtersService.priceLowValue"
        [(highValue)]="filtersService.priceHighValue"
        [options]="optionsForPrice"
        (userChangeEnd)="valueChangeOfPrice($event)"></ngx-slider>
    </div>
  </div>

  <div
    *ngIf="
      name === 'Price' &&
      filterCollapse[name] &&
      !desktop &&
      optionsForPrice.floor
    "
    class="price-container">
    <div class="slider">
      <div>
        <ul
          class="list price-items">
          <li>
            <input
              [(ngModel)]="items[0].topDeal.isChecked"
              type="checkbox"
              id="test"
              (change)="valueChangeOfTopDeals($event)" />
            <label class="sort-name" for="test">
              <span class="input"> </span>Top Deal
              <div class="number">
                ({{ this.items[0].topDeal.count }})
              </div>
            </label>
          </li>
        </ul>
      </div>
      <ngx-slider
        [(value)]="filtersService.priceLowValue"
        [(highValue)]="filtersService.priceHighValue"
        [options]="optionsForPrice"
        (userChangeEnd)="valueChangeOfPrice($event)"></ngx-slider>
    </div>
  </div>

  <div
    *ngIf="
      name === 'Time left' &&
      filterCollapse[name] &&
      !desktop &&
      optionsForTime.floor >= 0
    "
    class="time-left-container">
    <div class="slider">
      <ngx-slider
        [(value)]="filtersService.timeLowValue"
        [(highValue)]="filtersService.timeHighValue"
        [options]="optionsForTime"
        (userChangeEnd)="valueChangeOfTime($event)"></ngx-slider>
    </div>
  </div>

  <div
    *ngIf="
      name === 'Time left' &&
      filterCollapse[name] &&
      desktop &&
      optionsForTime.floor >= 0 &&
      (length_of_flights > 1 || typeOfAppliedFilter === 'Time left')
    "
    class="time-left-container">
    <div class="slider">
      <ngx-slider
        [(value)]="filtersService.timeLowValue"
        [(highValue)]="filtersService.timeHighValue"
        [options]="optionsForTime"
        (userChangeEnd)="valueChangeOfTime($event)"></ngx-slider>
    </div>
  </div>
</div>
