import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  Input,
  HostListener,
  ElementRef,
  ViewChild,
  Renderer2
} from '@angular/core';
import { DeparturePoint } from 'app/shared';
import { toggle } from 'app/shared/animations';
import { AlertService } from 'services/alert/alert.service';

@Component({
  selector: 'app-departure-list',
  templateUrl: './departure-list.component.html',
  styleUrls: ['./departure-list.component.css'],
  animations: [toggle]
})
export class DepartureListComponent implements OnInit {
  @Input() departureTree: DeparturePoint;
  @Input() selectedDepartureNode: DeparturePoint;
  @Output() selectAction: EventEmitter<any> = new EventEmitter();
  @ViewChild('dropdown_content') dropdownContent: ElementRef;
  @ViewChild('dropdown_toggle') dropdownToggle: ElementRef;
  @ViewChild('flaticon_agree') flaticonAgree: ElementRef;
  @ViewChild('dropdown_toggle_btn') dropdownToggleBtn: ElementRef;

  opened: boolean = false;
  selectedName: string;
  selected: DeparturePoint;
  isMobile: boolean;

  private debounceTimer;
  private debounceDelay = 800;

  @HostListener('window:resize', ['$event'])
  onResize() {
    clearTimeout(this.debounceTimer);
    this.debounceTimer = setTimeout(() => {
      if (window.innerWidth <= 650) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    }, this.debounceDelay);
  }

  constructor(
    private alertService: AlertService,
    private renderer: Renderer2
  ) {}

  ngOnInit() {
    this.renderer.listen('window', 'click', (e: Event) => {
      if (
        e.target !== this.dropdownContent.nativeElement &&
        e.target !== this.dropdownToggleBtn.nativeElement &&
        e.target !== this.dropdownToggle.nativeElement &&
        e.target !== this.flaticonAgree.nativeElement
      ) {
        this.toggle();
      }
    });

    // first set first city which is London
    this._select(this.selectedDepartureNode);

    if (window.innerWidth < 650) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }

  toggle() {
    this.opened = !this.opened;
    if (this.isMobile) {
      this.alertService.openClosePopUp(this.opened);
    }
  }

  private _select(city: DeparturePoint, airport?: DeparturePoint) {
    if (airport) {
      this.selected = airport;
      this.selectedName = city.name + ' ' + airport.name;
    } else {
      this.selected = city;
      this.selectedName = city.name;
      if (city.children.length > 1)
        return (this.selectedName += ' (All Airports)');
      if (city.children.length) return this.selectedName;
    }
  }

  selectDeparture(city: DeparturePoint, airport?: DeparturePoint) {
    if (airport) {
      this._select(city, airport);
    } else {
      this._select(city);
    }
    this.selectAction.emit({ city: city, airport: airport });
    this.toggle();
  }

  sortedDepartureTree() {
    return this.departureTree.children.sort((a, b) =>
      a.name.toLowerCase().localeCompare(b.name.toLowerCase())
    );
  }
}
