<app-keep-waiting *ngIf="keepWaiting"></app-keep-waiting>

<div class="entry">
  <!-- entry -->
  <div
    *ngIf="isOtherOutbound"
    class="information transition-all outbound inbound"
    [ngClass]="{ active: state === 'active' }"
    (click)="toggle()">
    <div class="row">
      <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 col icon icon-col dual">
        <img
          *ngIf="notHighlightFlight"
          class="pil_1"
          src="assets/img/icons/departure.svg"
          alt="img" />
        <img
          *ngIf="!notHighlightFlight"
          class="pil_1"
          src="assets/img/icons/departure-in-black.svg"
          alt="img" />
        <img class="return" src="assets/img/icons/return.svg" alt="img" />
      </div>
      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 col date">
        <p class="two-dates">
          <!-- ddd DD MMM YYYY -->
          <span
            class="date_1"
            [ngClass]="notHighlightFlight === true ? 'blue' : ''"
            >{{
              outboundFirstTicket.departure.date | amDateFormat: 'ddd DD MMM'
            }}</span
          >
          <span>{{
            returnFirstTicket.departure.date | amDateFormat: 'ddd DD MMM'
          }}</span>
        </p>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3 col departure">
        <!-- new version without tomorrow -->
        <p>
          <span
            class="color-1 air-code"
            [ngClass]="{
              different:
                outboundFirstTicket.departure.code !==
                returnLastTicket.arrival.code,
              blue: notHighlightFlight === true
            }">
            {{ outboundFirstTicket.departure.code }}
          </span>
          <span
            class="top one-day-container"
            [ngClass]="notHighlightFlight === true ? 'blue' : ''">
            {{
              outboundFirstTicket.departure.date | amDateFormat: 'HH:mm'
            }}</span
          >
          <br />
          <span
            class="color-1 air-code"
            [ngClass]="{
              different:
                outboundLastTicket.arrival.code !==
                returnFirstTicket.departure.code
            }">
            {{ returnFirstTicket.departure.code }} </span
          >{{ returnFirstTicket.departure.date | amDateFormat: 'HH:mm' }}
        </p>
      </div>
      <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 col arrival">
        <p>
          <span
            class="color-1 air-code"
            [ngClass]="{
              different:
                outboundLastTicket.arrival.code !==
                returnFirstTicket.departure.code,
              blue: notHighlightFlight === true
            }">
            {{ outboundLastTicket.arrival.code }}
          </span>
          <span
            class="top one-day-container"
            [ngClass]="notHighlightFlight === true ? 'blue' : ''">
            {{ outboundLastTicket.arrival.date | amDateFormat: 'HH:mm' }}
            <span
              *ngIf="outboundWillArriveNextDay"
              [ngClass]="{ blue: notHighlightFlight === true }"
              class="one-day hide-from-mobile"
              >{{
                dayOfOutboundArrival > 1
                  ? '+' + dayOfOutboundArrival + ' days'
                  : '+' + dayOfOutboundArrival + ' day'
              }}</span
            >
            <span
              *ngIf="outboundWillArriveNextDay"
              [ngClass]="{ blue: notHighlightFlight === true }"
              class="one-day hide-from-desktop mobile-font"
              >+{{ dayOfOutboundArrival }}d</span
            >
          </span>
          <br />
          <span
            class="color-1 air-code"
            [ngClass]="{
              different:
                outboundFirstTicket.departure.code !==
                returnLastTicket.arrival.code
            }">
            {{ returnLastTicket.arrival.code }}
          </span>
          <span>{{
            returnLastTicket.arrival.date | amDateFormat: 'HH:mm'
          }}</span>
          <span class="one-day-container time">
            <span
              *ngIf="inboundWillArriveNextDay"
              class="one-day-bottom hide-from-mobile"
              >{{
                dayOfInboundArrival > 1
                  ? '+' + dayOfInboundArrival + ' days'
                  : '+' + dayOfInboundArrival + ' day'
              }}</span
            >
            <span
              *ngIf="inboundWillArriveNextDay"
              class="one-day-bottom hide-from-desktop mobile-font"
              >+{{ dayOfInboundArrival }}d</span
            >
          </span>
          <!-- <span [ngClass]="inboundWillArriveNextDay ? '' : 'hide-color-2' " class="color-2">(+1 d) </span> -->
        </p>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3 col stops">
        <p>
          <span
            class="direct"
            [ngClass]="{
              blue: notHighlightFlight === true,
              'with-stop': flight.outboundTickets.length !== 1
            }">
            {{
              flight.outboundTickets.length === 1
                ? 'Direct'
                : flight.outboundTickets.length - 1
            }}
          </span>
          <span
            [ngClass]="{
              'direct_bottom ': flight.returnTickets.length === 1,
              'with-stop ': flight.returnTickets.length !== 1
            }">
            {{
              flight.returnTickets.length === 1
                ? 'Direct'
                : flight.returnTickets.length - 1
            }}
          </span>
        </p>
      </div>
      <div class="col-lg-5 col-md-5 col-sm-5 col-xs-5 col airline">
        <p>
          <span
            class="top"
            [ngClass]="notHighlightFlight === true ? 'blue' : ''"
            >{{
              !outboundIsUndisclosed
                ? multipleAirlinesOutbound === true
                  ? 'Multiple airlines'
                  : outboundFirstTicket.airline.name
                : 'Undisclosed'
            }}</span
          >
          <br />{{
            !inboundIsUndisclosed
              ? multipleAirlinesInbound === true
                ? 'Multiple airlines'
                : returnFirstTicket.airline.name
              : 'Undisclosed'
          }}
        </p>
      </div>
      <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 col duration">
        <p>
          <span
            class="top"
            [ngClass]="notHighlightFlight === true ? 'blue' : ''">
            {{
              flight.outboundTickets.length === 1
                ? (flight.outboundTickets[0].duration | momentDiff: 'shortened')
                : (totalDuration | momentDiff: 'shortened')
            }}
          </span>
          <br />
          <span>
            {{
              flight.returnTickets.length === 1
                ? (flight.returnTickets[0].duration | momentDiff: 'shortened')
                : (totalDurationOfReturnFlights | momentDiff: 'shortened')
            }}
          </span>
        </p>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-3 col-xs-5 col price">
        <!-- color-3 only appears if the flight is from another outbound-->
        <p class="price-cell" [ngClass]="{ blue: notHighlightFlight === true }">
          {{ '£' + (flight.price | priceRound) }}
          <!-- <span
            class="flight-type-sm"
            [ngClass]="{ blue: notHighlightFlight === true }"
            >rt</span
          > -->
          <!-- {{!highlight ? ('£' + (flight.price | priceRound)) : ''}} -->
          <!-- <span *ngIf="highlight" class="color-3">£{{flight.price | priceRound}}</span> -->
          <span
            [ngClass]="{ blue: notHighlightFlight === true }"
            class="color-1 return"
            >return</span
          >
        </p>
        <div 
          *ngIf="flight.seats && flight.seats < 5" 
          class="seats-count">
            {{flight.seats}} {{flight.seats == 1 ? 'seat' : 'seats'}}
            <span class="hide-from-mobile"> left</span>
        </div>
      </div>
      <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 col arrow">
        <i
          class="flaticon-agree transition-all"
          [style.color]="color"
          [ngClass]="{ rotate: state === 'active' }"></i>
      </div>
    </div>
  </div>

  <div
    class="toggle-state"
    [ngClass]="{ 'active-flight-details': state === 'active' }"
    [@toggleAnimation]
    *ngIf="state === 'active'">
    <app-flight-details
      *ngIf="isOtherOutbound"
      [flight]="flight"
      type="outbound"
      [homeOrReturnPage]="true"
      [returnPage]="true"></app-flight-details>

    <div *ngIf="loading" class="loading" appLoading [loading]="loading"></div>

    <div *ngIf="isOtherOutbound" class="divider"></div>

    <app-flight-details
      [flight]="flight"
      type="return"
      [homeOrReturnPage]="true"></app-flight-details>
    <div class="app-departure-arrival-diff">
      <app-departure-arrival-diff
        [flight]="flight"></app-departure-arrival-diff>
    </div>
    <div class="global-flight-details-nav" *ngIf="state === 'active'">
      <div class="price-per-person">
        <div class="ticket-conditions" (click)="showTicketConditions = true">
          Ticket Conditions
        </div>
        <div>
          <div class="top-deal" *ngIf="flight?.isTopDeal">
            <img src="assets/img/icons/top-deal(4).png" alt="" />
          </div>
          <div class="price-area">
            <div>
              <div class="small-text">From</div>
              <div class="small-text">Return per 1 Adult</div>
            </div>
            <div class="price-per-adult">
              <app-price [value]="flight.price"></app-price>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="innerWidth > 766">
        <button class="yellow-button" (click)="navigateToPassengerPage()">
          <span class="btn-content">
            <span class="btn-text">Go to passenger details</span>
            <i class="flaticon-agree"></i>
          </span>
        </button>
        <!-- select -->
        <app-passengers-chooser
          (passengersUpdated)="
            onPassengersUpdated($event)
          "></app-passengers-chooser>
      </div>
      <div *ngIf="innerWidth <= 766">
        <app-passengers-chooser
          (passengersUpdated)="
            onPassengersUpdated($event)
          "></app-passengers-chooser>
        <button class="yellow-button" (click)="navigateToPassengerPage()">
          <span class="btn-content">
            <span class="btn-text">Go to passenger details</span>
            <i class="flaticon-agree"></i>
          </span>
        </button>
        <!-- select -->
      </div>
      <app-ticket-conditions-popup 
        [showTicketConditions]="showTicketConditions"
        [isOneWay]="flight.isOneWay"
        [fareRulesType]="flight.contentProvider"
        (toggleShowTicketConditions)="showTicketConditions = $event"
      />
    </div>
  </div>
</div>
