<ng-container>
  <img
    *ngIf="cabin_bag"
    class="cabin-bag"
    src="assets/img/icons/bag_2.png"
    alt="" />
  <img
    *ngIf="large_bag"
    class="checked-bag"
    src="assets/img/icons/bag_1.png"
    alt="" />
  <img
    *ngIf="small_bag"
    class="small-bag"
    src="assets/img/icons/personal-item.png"
    alt="" />
  <ng-container>{{ displayText }}</ng-container>
</ng-container>
