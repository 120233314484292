import { Component } from '@angular/core';

@Component({
  selector: 'app-country-message',
  templateUrl: './country-message.component.html',
  styleUrls: ['./country-message.component.css']
})
export class CountryMessageComponent {
  isContentOpen: boolean = false;
}
