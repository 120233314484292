import {
  Component,
  Input,
  Output,
  EventEmitter,
  HostListener,
  AfterViewInit,
  OnInit
} from '@angular/core';
import { filterDropdown } from 'app/shared';

import { Options } from '@angular-slider/ngx-slider';
import { FilterItem, FiltersService } from 'services/filters/filters.service';

import { Subscription } from 'rxjs';
import { ConfigService } from 'services/config/config.service';

@Component({
  selector: 'app-filter-dropdown',
  templateUrl: './filter-dropdown.component.html',
  styleUrls: ['./filter-dropdown.component.css'],
  animations: [filterDropdown]
})
export class FilterDropdownComponent implements AfterViewInit, OnInit {
  @Input() isActive: boolean = false;
  @Input() name: string;
  @Input() previousFilters: any;

  @Input() filters;
  @Input() filter_length;
  @Input() items = [];
  @Input() typeInString: string;
  @Input() optionItems: string;
  @Input() hasSelectAndClearField;
  @Input() mobile: boolean = false;
  @Input() desktop: boolean = false;
  @Input() length_of_flights: number = 0;
  @Input() typeOfAppliedFilter: string = '';
  @Input() optionsForPrice: Options = {};
  @Input() optionsForTime: Options = {};

  @Output() optionClick = new EventEmitter();
  @Output() applyClick = new EventEmitter();
  @Output() selectAllButton = new EventEmitter();

  @Output() updateCountAfterFilterApplied = new EventEmitter();

  itemLength: number = 0;
  isOpen = false;

  initialVisibleDestinationsLimit = 3;
  visibleDestinationsLimit = this.initialVisibleDestinationsLimit;

  priceLowValue: number = 300;
  priceHighValue: number = 600;

  timeLowValue: number;
  timeHighValue: number;

  applyMobileFilters$: Subscription;
  filterCollapse = {
    'Departure day': true,
    'Destination type': false,
    Stops: false,
    Airline: false,
    Price: false,
    'Time left': false,
    Destination: false
  };
  imageUrl: string;

  private wasInside = false;
  @HostListener('click')
  clickInside() {
    this.wasInside = true;
  }

  @HostListener('document:click')
  clickout() {
    if (!this.wasInside) {
      this.isOpen = false;
    }
    this.wasInside = false;
  }

  constructor(private config: ConfigService, public filtersService: FiltersService) {
    this.imageUrl = this.config.get('IMAGE_HOST_URL');
  }

  ngOnInit() {
    this.applyMobileFilters$ = this.filtersService.applyMobileFilters.subscribe(
      condition => {
        if (condition) {
          this.onApply();
        }
      }
    );
  }

  ngAfterViewInit() {
    this.filtersService.updateHeightOfFilterContainer.next(true);
  }

  filterBy(by: string, value: number | string) {
    const index = this.filters[by].indexOf(value);

    if (index > -1) {
      // if the item exists already then we need to remove it from the list
      this.filters[by].splice(index, 1);
    } else {
      // if the item doesn't exist then we need to add it in the list
      this.filters[by].push(value);
    }
    return this.isApplyButtonActive(by);
  }

  filterByForPriceAndTimeLeft(
    by: string,
    value: number | string,
    type: string
  ) {
    if (type === 'price') {
      if (
        this.filtersService.priceLowValue === this.optionsForPrice.floor &&
        this.filtersService.priceHighValue === this.optionsForPrice.ceil
      ) {
        this.filters.price = [];
      } else {
        this.filters.price = [];
        this.filters.price.push(value);
      }
    } else if (type === 'time') {
      if (
        this.filtersService.timeLowValue === this.optionsForTime.floor &&
        this.filtersService.timeHighValue === this.optionsForTime.ceil
      ) {
        this.filters.timeLeft = [];
      } else {
        this.filters.timeLeft = [];
        this.filters.timeLeft.push(value);
      }
    }

    return this.isApplyButtonActive(by);
  }

  isApplyButtonActive(filter: string) {
    const prev = this.previousFilters[filter];
    const curr = this.filters[filter];

    if (prev.length != curr.length) return true;

    return curr.some(x => !prev.includes(x));
  }

  onApply() {
    this.applyClick.emit();
  }

  selectAll(firstItem: string, secondItem: string) {
    this.selectAllButton.emit({ firstItem, secondItem });
  }

  toggleFilter() {
    this.isOpen = !this.isOpen;
  }

  valueChangeOfPrice(event) {
    this.updateCountAfterFilterApplied.emit('Price');

    this.items.forEach(item => {
      item.isChecked = false;
    });

    const filterBy = '' + event.value + '-' + event.highValue;
    this.filtersService.priceLowValue = event.value;
    this.filtersService.priceHighValue = event.highValue;

    this.items.unshift(new FilterItem(filterBy, true, filterBy, undefined, undefined, this.items[0].topDeal));
    this.filterByForPriceAndTimeLeft(
      this.typeInString,
      this.items[0].filterBy,
      'price'
    );
    if (this.desktop) {
      this.onApply();
    } else {
      setTimeout(() => {
        this.optionClick.emit();
      });
    }
  }

  valueChangeOfTopDeals(event) {
    this.updateCountAfterFilterApplied.emit('TopDeals');
    this.filtersService.topDealsChecked = event.target.checked;
    this.filters.topDealsChecked = event.target.checked;
    if (this.desktop) {
      this.onApply();
    } else {
      setTimeout(() => {
        this.optionClick.emit();
      });
    }
  }

  valueChangeOfTime(event) {
    this.updateCountAfterFilterApplied.emit('Time left');
    if (this.items[0].filterBy != '0-8') {
      this.items.shift();
    }
    this.items.forEach(item => {
      item.isChecked = false;
    });

    const filterBy = '' + event.value + '-' + event.highValue;
    this.filtersService.timeLowValue = event.value;
    this.filtersService.timeHighValue = event.highValue;

    this.items.unshift(new FilterItem(filterBy, true, filterBy));

    this.filterByForPriceAndTimeLeft(
      this.typeInString,
      this.items[0].filterBy,
      'time'
    );
    if (this.desktop) {
      this.onApply();
    } else {
      setTimeout(() => {
        this.optionClick.emit();
      });
    }
  }

  valueChangeOfInputs(type: string) {
    this.updateCountAfterFilterApplied.emit(type);
    if (this.desktop) {
      setTimeout(() => {
        this.onApply();
      });
    } else {
      setTimeout(() => {
        this.optionClick.emit();
      });
    }
  }

  toggleFilterCollapse(name: string) {
    this.filterCollapse[name] = !this.filterCollapse[name];
    this.filtersService.updateHeightOfFilterContainer.next(true);
  }

  getIconName(name: string) {
    return "assets/img/destination-type-icons/" + name.toLowerCase().replace(/ /g, '-') + ".svg";
  }
}
