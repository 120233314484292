import { Component, Input } from '@angular/core';
import { PriceDetails } from 'app/shared';

@Component({
  selector: 'booking-confirmation-price-details',
  templateUrl: './booking-confirmation-price-details.html',
  styleUrls: ['./booking-confirmation-price-details.scss']
})
export class BookingConfirmationPriceDetailsComponent {
  @Input() data: PriceDetails;
  @Input() paxType: string;
  @Input() show_pp: boolean = false;
  @Input() typeOfTable: number;
}
