import { SafeHtml } from '@angular/platform-browser';
import { Duration, Moment } from 'moment';

import * as moment from 'moment-timezone';
import { ClockService } from 'services/clock/clock.service';

export * from './utils';
export * from './animations';

export class FlightFilter {
  departureTime: Array<string> = [];
  destinationType: Array<string> = [];
  destinations: Array<string> = [];
  airlines: Array<string> = [];
  stops: Array<number | string> = [];
  price: Array<string> = []; //each number is the maximum namber from the range (e.g. from $50 - $150, take $150)
  timeLeft: Array<string> = []; //each number is the maximum namber from the range (e.g. from 8 - 16 hrs, take 16)
  topDealsChecked: boolean = false;
}

export class CalendarDay {
  constructor(
    public date: moment.Moment,
    public isStartDate?: boolean,
    public isReturnDate?: boolean,
    public price?: number,
    public isLowestPrice?: boolean,
    public isOtherOutbound?: boolean,

    public isAvailable?: boolean,
    public isLoaded?: boolean,
    public currency: string = 'GBP',
    public options: Array<Flight> = []
  ) { }
}

export class ReturnFlightsContainer {
  private _sortedReturnFlightsLength = 0;
  private _sortedOtherOutboundFlightsLength = 0;

  date: moment.Moment;
  returnFlights: Array<Flight> = [];
  otherOutboundFlights: Array<Flight> = [];
  constructor() { }

  isReturnFlightsSorted() {
    return this.returnFlights.length === this._sortedReturnFlightsLength;
  }

  isOtherOutboundFlightsSorted() {
    return (
      this.otherOutboundFlights.length ===
      this._sortedOtherOutboundFlightsLength
    );
  }

  sortOtherOutboundFlightsByPrice(forceSort?: boolean) {
    if (!forceSort && this.isOtherOutboundFlightsSorted()) return;
    this.otherOutboundFlights.sort((flight1: Flight, flight2: Flight) => {
      if (flight1.price < flight2.price) return -1;
      else if (flight1.price > flight2.price) return 1;
      else return 0;
    });

    this._sortedOtherOutboundFlightsLength = this.otherOutboundFlights.length;
  }

  sortReturnFlightsByPrice(forceSort?: boolean) {
    if (!forceSort && this.isReturnFlightsSorted()) return;

    this.returnFlights.sort((flight1: Flight, flight2: Flight) => {
      if (flight1.price < flight2.price) return -1;
      else if (flight1.price > flight2.price) return 1;
      else return 0;
    });

    this._sortedReturnFlightsLength = this.returnFlights.length;
  }

  getReturnFlightsMinPrice(): number {
    this.sortReturnFlightsByPrice();
    const returnFlight = this.returnFlights[0] || { price: Infinity };
    const price = returnFlight.price;

    return price;
  }

  getOtherOutboundFlightsMinPrice(): number {
    this.sortReturnFlightsByPrice();
    const otherOutboundFlight = this.otherOutboundFlights[0] || {
      price: undefined
    };
    const price = otherOutboundFlight.price;

    return price;
  }
}

export enum NodeType {
  Country = 1,
  City = 2,
  Airport = 3
}

export class DeparturePoint {
  children: Array<DeparturePoint> = [];
  name: string;
  code: string;
  flightsCount: number;
  nodeType: number;

  constructor(document?: any) {
    if (!document) return;

    this.name = document.name;
    this.code = document.code;
    this.nodeType = document.nodeType;
    this.flightsCount = document.flightsCount;
    if (
      this.nodeType === NodeType.Country ||
      this.nodeType === NodeType.City
    ) {
      if (document.children && document.children.length) {
        document.children.map(point => {
          this.children.push(new DeparturePoint(point));
        });
      }
    }
  }
}

export class Airport {
  name: string;
  city: string;
  shortCity: string;
  date: moment.Moment;
  code: string;
  cityCode: string;
  countryCode: string;
  country: string;
  shortCountry: string;
  worldArea: string;

  constructor(
    document?: any,
    parentCity?: string,
    parentCityCode?: string,
    parentCountry?: string,
    parentCountryCode?: string
  ) {
    if (!document) return;

    if (parentCityCode) {
      this.city = parentCity;
      this.code = parentCityCode;
      this.cityCode = parentCityCode;
      this.country = parentCountry;
      this.countryCode = parentCountryCode;
    } else {
      this.city = document.city;
      this.code = document.code;
      this.countryCode = document.countryCode;
      this.country = document.country;
      this.cityCode = document.cityCode;
    }
    this.name = document.name;
    this.worldArea = document.continent;
    if (document.date) {
      this.date = moment(document.date, 'YYYY-MM-DD HH:mm');
    }
  }
}

export class Checkin {
  passenger: Passenger;
  seat: string;

  constructor(document?: any) {
    if (!document) return;

    this.passenger = document.passenger;
    this.seat = document.seat;
  }
}

export class Passenger {
  protected _name: string;
  firstName: string;
  lastName: string;
  birthDay: number;
  birthMonth: number;
  birthYear: number;
  minAge: number = 0;
  maximumAge: number = 99;
  dateOfBirth: moment.Moment;
  passportId: string = 'DUMMY_PASSPORT_ID'; //TODO
  gender: string;
  type: string;
  age: number;
  disabled: boolean;

  set name(name) {
    this._name = name;
  }

  get name() {
    return this._name || this.firstName + ' ' + this.lastName;
  }

  constructor(document?: any) {
    if (!document) return;

    this.name = document.name;
    this.firstName = document.firstName;
    this.lastName = document.lastName;
    this.dateOfBirth = document.dateOfBirth;
    this.passportId = document.passportId;
    this.gender = document.gender;
    this.disabled = false;

    this.type = document.type;
    this.setupType();
  }

  setupType() {
    if (this.type == 'adult') {
      this.minAge = 12;
      this.maximumAge = 99;
    }
    if (this.type == 'child') {
      this.minAge = 2;
      this.maximumAge = 12;
    }
    if (this.type == 'infant') {
      this.minAge = 0;
      this.maximumAge = 2;
    }
  }
}

export class PricingDetails {
  taxes: number;
  serviceCharge: number;
  fare: number;
  baggage: number;

  constructor(document?: any) {
    if (!document) return;

    this.fare = document.fare;
    this.taxes = document.taxes;
    this.serviceCharge = document.serviceCharge;
    this.baggage = document.baggage;
  }
}

export class Booker extends Passenger {
  email: string;
  confirmEmail: string;
  countryCode: string;
  dialCode: string;
  phoneNumber: string;
  address: string;
  city: string;
  country: string;
  postCode: string;

  constructor(document?: any) {
    super(document);
    //override
    this.maximumAge = 99;
    this.minAge = 18;

    if (!document) return;

    this.email = document.email;
    this.countryCode = document.countryCode;
    this.phoneNumber = document.phoneNumber;
    this.address = document.address;
    this.dialCode = document.dialCode;
    this.city = document.city;
    this.country = document.country;
    this.postCode = document.postCode;
  }
}

export class Airline {
  name: string;
  airlineLogo: string;

  constructor(document?: any) {
    if (!document) return;

    this.name = document.name;
    this.airlineLogo = document.logoUrl;
  }
}

export class Ticket {
  id: string | number;
  flightNumber: string;
  type: 'Econome' | 'Business';
  airline: Airline;
  operatedByAirline: Airline;
  departure: Airport;
  arrival: Airport;
  seat: string;
  departureTerminal: string;
  arrivalTerminal: string;
  aircraft: string;
  duration: Duration;
  baggage: Baggage;
  fareFamily: string;
  inboundFareFamily: string;

  constructor(document?: any) {
    if (!document) return;

    if (document.id) this.id = document.id;
    if (document.flightNumber)
      this.flightNumber = document.flightNumber.replace(/\s+/g, '');
    this.type = document.class;
    this.seat = document.seat;
    this.departureTerminal = document.departureTerminal;
    this.arrivalTerminal = document.arrivalTerminal;
    this.aircraft = document.aircraft;
    this.duration = moment.duration(document.duration, 'hours');
    this.airline = new Airline(document.airline);
    this.operatedByAirline = new Airline(document.operatedByAirline);

    const departureDoc = Object.assign({}, document.departurePoint, {
      date: document.departureDate
    });
    this.departure = new Airport(departureDoc);

    const arrivalDoc = Object.assign({}, document.destinationPoint, {
      date: document.destinationDate
    });
    this.arrival = new Airport(arrivalDoc);

    if (document.baggage) {
      this.baggage = new Baggage(document.baggage);
    }
  }
}

export class DepartureAirport extends Airport {
  flightCount: number;
  innerList: Array<DepartureAirport> = [];
  parentCode: string = null;
  parentCityCode: string = null;
  constructor(document: any, parentCode?: string, parentCityCode?: string) {
    super(document.airport);

    if (parentCode) this.parentCode = parentCode;
    if (parentCityCode) this.parentCityCode = parentCityCode;
    if (!document) return;

    this.flightCount = document.flightCount;
    if (document.innerList && document.innerList.length) {
      document.innerList.map(airportDocument => {
        this.innerList.push(
          new DepartureAirport(airportDocument, this.code, this.cityCode)
        );
      });
    }
  }
}

export class FlightsBoard {
  selectedDepartureCode: string;
  flights: Array<Flight> = [];
  filterVisibilityThreshold: number;
  minDepartureLimitInMinutes: number;
  featuredFlights: Array<Flight> = [];

  constructor(document?) {
    if (!document) return;

    this.selectedDepartureCode = document.selectedDepartureFromCode;
    this.filterVisibilityThreshold = document.filterVisibilityThreshold;
    this.minDepartureLimitInMinutes = document.minDepartureLimitInMinutes;

    if (document.flights && document.flights.length)
      document.flights.map(flightDocument => {
        this.flights.push(new Flight(flightDocument));
      });
  }
}
export class FullFlight {
  departurePoint: Airport;
  destinationPoint: Airport;
  departureDate: moment.Moment;
  arrivalDate: moment.Moment;
  duration: Duration;
  outbound: OneWayFlight;
  inbound: OneWayFlight;
  timeLeft: Duration;
  isDepartureAndArrivalSame: boolean = true;
  isArrivalAndDepartureSame: boolean = true;
  isDepartureHasBag: boolean;
  isArrivalHasBag: boolean;

  private clockSubscription: any;
  private currentDate = moment();

  constructor(document?: any) {
    if (!document) return;

    this.departurePoint = new Airport(document.departurePoint);
    this.destinationPoint = new Airport(document.destinationPoint);
    this.departureDate = moment(document.departureDate, 'YYYY-MM-DD HH:mm');
    this.arrivalDate = moment(document.arrivalDate, 'YYYY-MM-DD HH:mm');
    this.duration = moment.duration(document.duration, 'hours');
    this.outbound = new OneWayFlight(document.outbound);
    this.inbound = new OneWayFlight(document.inbound);

    this.timeLeft = moment.duration(this.departureDate.diff(moment()));

    if (
      this.inbound.legs.length &&
      this.outbound.legs[0].departurePoint.code !=
      this.inbound.legs[this.inbound.legs.length - 1].destinationPoint.code
    )
      this.isDepartureAndArrivalSame = false;

    if (
      this.inbound.legs.length &&
      this.outbound.legs[this.outbound.legs.length - 1].destinationPoint.code !=
      this.inbound.legs[0].departurePoint.code
    )
      this.isArrivalAndDepartureSame = false;

    if (this.outbound.legs[0]?.baggage?.qty == 0) {
      this.isDepartureHasBag = false;
    }

    if (this.inbound.legs[0]?.baggage?.qty == 0) {
      this.isArrivalHasBag = false;
    }
  }

  startCountDown(clock: ClockService) {
    if (this.currentDate.isBefore(this.departureDate)) {
      this.clockSubscription = clock.tick.subscribe(() => {
        if (this.outbound.legs.length) {
          const departureDate = this.outbound.legs[0].departureDate;
          this.timeLeft = moment.duration(departureDate.diff(moment()));
          if (
            this.timeLeft.hours() === 0 &&
            this.timeLeft.minutes() === 0 &&
            this.timeLeft.seconds() === 0
          ) {
            this.stopCountDown();
          }
        }
      });
    } else {
      this.timeLeft = moment.duration(0, 'seconds');
    }
  }

  stopCountDown() {
    if (this.clockSubscription) {
      this.clockSubscription.unsubscribe();
      this.clockSubscription = null;
    }
  }
}

export class OneWayFlight {
  isOutbound: boolean;
  isOvernight: boolean;
  departurePoint: Airport;
  destinationPoint: Airport;
  departureDate: moment.Moment;
  arrivalDate: moment.Moment;
  duration: Duration;
  legs: Array<TicketOfOneWayFlight> = [];

  constructor(document?: any) {
    if (!document) return;

    this.isOutbound = document.isOutbound;
    this.isOvernight = document.isOvernight;
    this.departurePoint = new Airport(document.departurePoint);
    this.destinationPoint = new Airport(document.destinationPoint);
    this.departureDate = moment(document.departureDate, 'YYYY-MM-DD HH:mm');
    this.arrivalDate = moment(document.arrivalDate, 'YYYY-MM-DD HH:mm');
    this.duration = moment.duration(document.duration, 'hours');
    if (document.legs) {
      document.legs.forEach(ticket => {
        this.legs.push(new TicketOfOneWayFlight(ticket));
      });
    }
  }
}

export class TicketOfOneWayFlight {
  aircraft: string;
  airline: Airline;
  arrivalTerminal: string;
  baggage: Baggage;
  cabin: string;
  departureDate: moment.Moment;
  departurePoint: Airport;
  departureTerminal: string;
  destinationDate: moment.Moment;
  destinationPoint: Airport;
  duration: Duration;
  flightNumber: string;
  isOvernight: boolean;
  isStopOvernight: boolean;
  operatedByAirline: Airline;
  stopDuration: Duration;

  constructor(document?: any) {
    if (!document) return;

    this.aircraft = document.aircraft;
    this.airline = new Airline(document.airline);
    this.arrivalTerminal = document.arrivalTerminal;
    this.baggage = new Baggage(document.baggage);
    this.cabin = document.cabin;
    this.departureDate = moment(document.departureDate, 'YYYY-MM-DD HH:mm');
    this.departurePoint = new Airport(document.departurePoint);
    this.departureTerminal = document.departureTerminal;
    this.destinationDate = moment(document.destinationDate, 'YYYY-MM-DD HH:mm');
    this.destinationPoint = new Airport(document.destinationPoint);
    this.duration = moment.duration(document.duration, 'hours');
    this.flightNumber = document.flightNumber;
    this.isOvernight = document.isOvernight;
    this.isStopOvernight = document.isStopOvernight;
    this.operatedByAirline = new Airline(document.operatedByAirline);
    this.stopDuration = moment.duration(document.stopDuration, 'hours');
  }
}

export class Blog {
  author: string;
  body: string;
  bodyPreview: string;
  firstPublished: moment.Moment;
  lastReviewed: moment.Moment | null;
  id: string;
  mainImageUrl: string;
  readingTime: number;
  title: string;

  constructor(document: any) {
    if (!document) return;

    this.author = document.author;
    this.body = document.body;
    this.bodyPreview = document.bodyPreview;
    this.firstPublished = moment(document.firstPublished, 'YYYY-MM-DD HH:mm');
    this.lastReviewed =
      !!document.lastReviewed &&
      moment(document.lastReviewed, 'YYYY-MM-DD HH:mm');
    this.id = document.id;
    this.mainImageUrl = document.mainImageUrl || '';
    this.readingTime = document.readingTime;
    this.title = document.title;
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export class Flight {
  id: string;
  // isFeatured: boolean;
  outboundTickets: Array<Ticket> = [];
  returnTickets: Array<Ticket> = [];
  passenger: Passenger;
  timeLeft: Duration; //in seconds
  price: number;
  currency: string = 'GBP';
  destinationImgUrl: string;
  destinationThumbnailUrl: string;
  mealType: string;
  isDepartureAndArrivalSame: boolean = true;
  isArrivalAndDepartureSame: boolean = true;
  // showDealTag: boolean = false;
  isTopDeal: boolean = false;
  isOneWay: boolean = false;
  showReferencePrice: boolean = false;
  priceBeforeDiscount: number;
  discount: number;
  isDepartureHasBag: boolean = true;
  isArrivalHasBag: boolean = true;
  isOtherOutbound: boolean = false;
  maxVsActualPriceDiffSort: number;
  contentProvider: number;
  fareFamily: string;
  inboundFareFamily: string;
  seats: number;
  destinationTypes: Array<string> = [];

  lastBookedAt?: any;
  viewersCount = 0;

  private clockSubscription: any;

  constructor(document?: any) {
    if (!document) return;

    this.id = document.key;
    this.maxVsActualPriceDiffSort = document.maxVsActualPriceDiffSort;
    this.currency = document.currencyCode || 'GBP';
    this.destinationImgUrl = document.destinationUrl;
    this.destinationThumbnailUrl = document.destinationThumbnailUrl;
    this.price = document.price;
    this.isOtherOutbound = document.isOtherOutbound;
    this.isTopDeal = document.isTopDeal;
    this.isOneWay = document.isOneWay;
    this.contentProvider = document.contentProvider;
    this.seats = document.seats;
    this.fareFamily = document.fareFamily;
    this.inboundFareFamily = document.inboundFareFamily;

    if (document.dealElement) {
      this.showReferencePrice = document.dealElement.showReferencePrice;
      this.discount = document.dealElement.discount;
      this.priceBeforeDiscount = document.dealElement.priceBeforeDiscount;
    }

    if (document.destinationPoint) {
      this.destinationTypes = document.destinationPoint.labels;
    }

    this.passenger = new Passenger(document);

    if (document.inboundLegs && document.inboundLegs.length) {
      document.inboundLegs.map(legDocument => {
        this.returnTickets.push(new Ticket(legDocument));
      });
    }
    if (document.outboundLegs && document.outboundLegs.length) {
      document.outboundLegs.map(legDocument => {
        this.outboundTickets.push(new Ticket(legDocument));
      });
    }
    const departure = this.outboundTickets[0].departure;

    this.timeLeft = moment.duration(departure.date.diff(moment()));

    if (
      this.returnTickets.length &&
      this.outboundTickets[0].departure.code !=
      this.returnTickets[this.returnTickets.length - 1].arrival.code
    )
      this.isDepartureAndArrivalSame = false;

    if (
      this.returnTickets.length &&
      this.outboundTickets[this.outboundTickets.length - 1].arrival.code !=
      this.returnTickets[0].departure.code
    )
      this.isArrivalAndDepartureSame = false;

    if (this.outboundTickets[0]?.baggage?.qty == 0) {
      this.isDepartureHasBag = false;
    }

    if (this.returnTickets[0]?.baggage?.qty == 0) {
      this.isArrivalHasBag = false;
    }

    if (document.bookingsAndViews) {
      this.lastBookedAt = document.bookingsAndViews.lastBookedAt;
      this.viewersCount = document.bookingsAndViews.viewersCount;
    }
  }

  startCountDown(clock: ClockService) {
    this.clockSubscription = clock.tick.subscribe(() => {
      if (this.outboundTickets.length) {
        const departure = this.outboundTickets[0].departure;

        const departureDate = departure.date;
        this.timeLeft = moment.duration(departureDate.diff(moment()));
      }
    });
  }

  stopCountDown() {
    if (this.clockSubscription) {
      this.clockSubscription.unsubscribe();
      this.clockSubscription = null;
    }
  }

  get outboundDepartureDate(): Moment {
    return this.outboundTickets[0].departure.date;
  }

  get outboundArivalDate(): Moment {
    return this.outboundTickets[this.outboundTickets.length - 1].arrival.date;
  }

  get returnDepartureDate(): Moment {
    return this.returnTickets[0].departure.date;
  }

  get returnArivalDate(): Moment {
    return this.returnTickets[this.returnTickets.length - 1].arrival.date;
  }

  get outboundWillArriveNextDay(): boolean {
    return this.outboundDepartureDate.date() < this.outboundArivalDate.date();
  }

  get inboundWillArriveNextDay(): boolean {
    return this.returnDepartureDate.date() < this.returnArivalDate.date();
  }
}

export class PriceBreakdown {
  bookingId: string;
  avgPerPerson: number;
  currencyCode: string;
  subTotal: number;
  // total: string;
  adult: PriceDetails;
  child: PriceDetails;
  infant: PriceDetails;
  itineraryList: Array<ItineraryList> = [];
  totalPaxCount: number;
  priceChanged: boolean;
  isIncreased: boolean;
  isDecreased: boolean;
  oldPrice: number;
  newPrice: number;
  discountCode: string;
  discountAmount: number;
  grandTotal: number;
  totalToPay: number;
  fareRules: string = '';

  constructor(document?: any) {
    if (!document) return;

    this.bookingId = document.bookingId;
    this.avgPerPerson = document.avgPerPerson;
    this.currencyCode = document.currencyCode;
    // this.total = document.total;
    this.adult = new PriceDetails(document.adult);
    this.child = new PriceDetails(document.child);
    this.infant = new PriceDetails(document.infant);
    this.totalPaxCount = document.totalPaxCount;
    this.priceChanged = document.priceChanged;
    this.isIncreased = document.isIncreased;
    this.isDecreased = document.isDecreased;
    this.oldPrice = document.oldPrice;
    this.newPrice = document.newPrice;
    this.discountAmount = document.discountAmount * -1; // discount amout should be in negative
    this.discountCode = document.discountCode;
    this.grandTotal = document.grandTotal;
    this.totalToPay = document.totalToPay;

    if (document.rules) {
      document.rules.forEach(element => {
        this.fareRules += element + '\n' + '\n';
      });
    }

    this.subTotal =
      this.adult.subTotal +
      (this.child.subTotal || 0) +
      (this.infant.subTotal || 0);

    if (document.itineraryList && document.itineraryList.length) {
      document.itineraryList.map(item => {
        this.itineraryList.push(new ItineraryList(item));
      });
    }
  }
}

export class PriceDetails {
  fare: number;
  taxes: number;
  serviceFee: number;
  total: number;
  count: number;
  subTotal: number;
  discount: number;
  fareTypeCalculation: number;
  price: number;

  constructor(document?: any) {
    if (!document) return;

    this.fare = document.fare;
    this.taxes = document.taxes;
    this.serviceFee = document.serviceFee;
    this.count = document.count;
    this.total = document.total;
    this.subTotal = document.subTotal;
    this.discount = document.discount;
    this.fareTypeCalculation = document.fareTypeCalculation;
    this.price = document.price;
  }
}

export class BookingData {
  bookingId: string;
  bookingStatus: string;
  fareType: string;
  fareTypeText: string;
  referenceId: string;
  flightId: string;
  fullFlight: FullFlight;
  priceBreakdown: PriceBreakdown;
  booker: Booker;
  passangerList: Array<Passenger>;
  cardDetails: CardDetails;
  bookingDate: string;

  constructor(document?: any) {
    if (!document) return;

    this.bookingId = document.bookingId;
    this.bookingStatus = document.bookingStatus;
    this.fareType = document.fareType;
    this.fareTypeText = document.fareTypeText;
    this.referenceId = document.referenceId;
    this.flightId = document.flightKey;
    this.fullFlight = new FullFlight(document.fullFlight);
    this.priceBreakdown = new PriceBreakdown(document.pricingDetails);
    this.booker = new Booker(document.booker);
    this.cardDetails = new CardDetails(document.cardDetails);
    this.bookingDate = document.bookingDateText;

    this.passangerList = new Array<Passenger>();
    if (document.passangerList && document.passangerList.length) {
      document.passangerList.map(item => {
        this.passangerList.push(new Passenger(item));
      });
    }
  }
}

export class ItineraryList {
  departureAirportCode: string;
  arivalAirportCode: string;
  departureTerminal: string;
  arivalTerminal: string;

  constructor(document?: any) {
    if (!document) return;

    this.departureAirportCode = document.departureAirportCode;
    this.arivalAirportCode = document.arivalAirportCode;
    this.departureTerminal = document.departureTerminal;
    this.arivalTerminal = document.arivalTerminal;
  }
}

export class ApiSession {
  sessionId: string;
  browserId: string;

  constructor(init?: Partial<ApiSession>) {
    Object.assign(this, init);
  }
}

export class Cities {
  departureTree: DeparturePoint;
  constructor(document) {
    this.departureTree = new DeparturePoint(document.data);
  }
}

export class Labels {
  labels: { name: string }[];
  constructor(document) {
    this.labels = document.data;
  }
}

export interface BaggageItem {
  type: number;
  qty: number;
  unit: string | null;
}

export class Baggage {
  // description: string;
  items: Array<BaggageItem>;
  qty: number;
  unit: string;
  shortExpression: string;
  longDescription: SafeHtml | string;

  constructor(document?: any) {
    if (!document) return;

    this.qty = document.qty;
    this.unit = document.unit;
    this.shortExpression = document.shortExpression;
    this.longDescription = document.longDescription;
    this.items = [...document.items];
  }
}

export class GeoData {
  coordinates: Coordinates | null;
  countryCode: string;

  constructor(init?: Partial<GeoData>) {
    Object.assign(this, init);
  }
}

export class Coordinates {
  latitude: number;
  longitude: number;

  constructor(init?: Partial<Coordinates>) {
    Object.assign(this, init);
  }
}

export class CardDetails {
  last4Digits: string;
  cardType: string;
  expiryMonth: string;
  expiryYear: string;

  constructor(init?: Partial<CardDetails>) {
    Object.assign(this, init);
  }
}

export class PassengerList {
  private _adult: number;
  private _children: number;
  private _infants: number;
  private _max: number;

  constructor() {
    this._max = 9;
    this._adult = 1;
    this._children = 0;
    this._infants = 0;
  }

  get adult(): number {
    return this._adult;
  }
  set adult(value: number) {
    if (this.canSetAdult(value)) this._adult = value;
  }
  canSetAdult(value: number): boolean {
    return value >= 1 && value + this._children + this._infants <= this._max;
  }

  get children(): number {
    return this._children;
  }
  set children(value: number) {
    if (this.canSetChildren(value)) this._children = value;
  }
  canSetChildren(value: number): boolean {
    return value >= 0 && value + this._adult + this._infants <= this._max;
  }

  get infants(): number {
    return this._infants;
  }
  set infants(value: number) {
    if (this.canSetInfant(value)) this._infants = value;
  }
  canSetInfant(value: number): boolean {
    return value >= 0 && value + this._adult + this._children <= this._max;
  }

  get total(): number {
    return this._adult + this._infants + this._children;
  }
}

export class Country {
  code: string;
  name: string;
  dialCode: string;

  constructor(document) {
    if (!document) return;

    this.code = document.code;
    this.name = document.name;
    this.dialCode = document.dialCode;
  }
}
