import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-filter-item',
  templateUrl: './filter-item.component.html',
  styleUrls: ['./filter-item.component.scss']
})
export class FilterItemComponent {
  @Output() applyFilter: EventEmitter<void> = new EventEmitter<void>();
  @Input() text: string;

  constructor() {}

  deleteFilter() {
    this.applyFilter.emit();
  }
}
