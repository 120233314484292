import { Injectable, EventEmitter } from '@angular/core';

import * as moment from 'moment';
import { Subject } from 'rxjs';

@Injectable()
export class CalendarDayService {
  private _from: moment.Moment;
  private _to: moment.Moment;

  public onHover: EventEmitter<moment.Moment> = new EventEmitter();
  public onReturnDateSelected: EventEmitter<moment.Moment> = new EventEmitter();
  public dateOfFlight$ = new Subject();

  get from() {
    return moment(this._from);
  }

  set from(date: moment.Moment) {
    this._from = moment(date);
  }

  get to() {
    return moment(this._to);
  }

  set to(date: moment.Moment) {
    this._to = moment(date);
    this.onReturnDateSelected.emit(this.to);
  }

  reset() {
    this._from = null;
    this._to = null;
  }

  constructor() {}
}
