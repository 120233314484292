import { Component } from '@angular/core';
import { Router } from '@angular/router';
import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  animations: [
    trigger('toggleState', [
      state(
        'inactive',
        style({
          display: 'none',
          overflow: 'hidden',
          height: '0px'
        })
      ),
      state(
        'active',
        style({
          display: 'block',
          overflow: 'hidden',
          height: '*'
        })
      ),
      transition('inactive => active', animate('300ms ease-in')),
      transition('active => inactive', animate('300ms ease-out'))
    ])
  ]
})
export class HeaderComponent {
  state = 'inactive';

  constructor(router: Router) {
    router.events.subscribe(() => {
      this.state = 'inactive';
    });
  }

  toggle() {
    this.state = this.state === 'active' ? 'inactive' : 'active';
  }
}
