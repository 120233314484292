import {
  Component,
  ViewChild,
  ElementRef,
  Input,
  Output,
  ChangeDetectorRef,
  EventEmitter,
  OnInit,
  Renderer2,
  OnChanges
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.css']
})
export class SelectComponent implements OnInit, OnChanges {
  @Input() formControl: UntypedFormControl;
  @Input() formSubmitAttempt: boolean;
  @Input() options: Array<any>;
  @Input() initialSelectedOption: any;
  @Input() caption: string;
  @Input() booker: boolean = false;

  @Output() selectAction: EventEmitter<any> = new EventEmitter();
  @ViewChild('menu') menu: ElementRef;

  isOpen: boolean = false;
  positionMenuUp: boolean = false;
  selectedOption: any;

  onScrollSubscription: any;
  onDocClickSubscription: any;

  constructor(
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2,
    private eref: ElementRef
  ) {
    this.caption = 'Select';
  }

  adjustPosition() {
    const maxHeight = 300;
    if (this.menu) {
      const bodyRect = document.body.getBoundingClientRect(),
        elemRect = this.menu.nativeElement.getBoundingClientRect(),
        offset = elemRect.top - bodyRect.top;
      if (offset - window.pageYOffset + maxHeight > window.innerHeight)
        this.positionMenuUp = true;
      else this.positionMenuUp = false;
    }
  }

  ngOnInit() {
    this.formControl = this.formControl || new UntypedFormControl();
    if (this.initialSelectedOption || this.initialSelectedOption === 0) {
      this.options.map(option => {
        if (
          option.value == this.initialSelectedOption ||
          option == this.initialSelectedOption
        )
          this.selectedOption = option;
      });
    }
  }

  toggle() {
    this.isOpen = !this.isOpen;
    if (this.isOpen) {
      if (this.onScrollSubscription) this.onScrollSubscription();
      if (this.onDocClickSubscription) this.onDocClickSubscription();

      this.onScrollSubscription = this.renderer.listen(
        'window',
        'scroll',
        () => {
          this.adjustPosition();
        }
      );

      this.isOpen = !this.isOpen;
      this.onDocClickSubscription = this.renderer.listen(
        'document',
        'click',
        event => {
          if (this.isOpen && !this.eref.nativeElement.contains(event.target)) {
            this.toggle();
          }
        }
      );
      this.isOpen = !this.isOpen;
    } else {
      this.onScrollSubscription();
      this.onDocClickSubscription();
    }
  }

  select(option: any) {
    this.toggle();
    this.selectAction.emit(option);
    this.selectedOption = option;
    this.cdr.detectChanges();
  }

  ngOnChanges() {
    if (
      (this.initialSelectedOption || this.initialSelectedOption === 0) &&
      this.booker
    ) {
      this.options.map(option => {
        if (option.value === this.initialSelectedOption) {
          this.selectedOption = option;
        }
      });
    }
  }
}
