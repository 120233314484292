<div class="container">
  <div class="main-container">
    <h3 class="home-page-section-header-text">
      Popular last minute flights from London
    </h3>
    <div class="destinations-container">
      <div class="destination-items" *ngFor="let destination of destinations">
        <img
          class="spot-target"
          src="assets/img/icons/airport-pin-gray.svg"
          alt="" />
        <a
          target="_blank"
          [routerLink]="['/popular-last-minute-flights', destination.url]"
          >Last minute flights to {{ destination.cityLongName }}</a
        >
      </div>
    </div>
  </div>
</div>
