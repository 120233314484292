import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-card-component',
  templateUrl: './card-component.component.html',
  styleUrls: ['./card-component.component.css']
})
export class CardComponentComponent {
  @Input() featuredFlights: any;
  @Output() scrollToFlightAccardionFlight: EventEmitter<void> =
    new EventEmitter();

  constructor() {}

  scrollToFlightAccardion(flightId) {
    this.scrollToFlightAccardionFlight.emit(flightId);
  }
}
