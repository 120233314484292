import { Injectable } from '@angular/core';
import * as _swal from 'sweetalert';
import { SweetAlert } from 'sweetalert/typings/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
const swal: SweetAlert = _swal as any;

@Injectable()
export class AlertService {
  public showFilterPopUp: Subject<boolean> = new Subject();

  private popUpSubject = new BehaviorSubject<boolean>(false);
  popUp$: Observable<boolean> = this.popUpSubject.asObservable();

  openClosePopUp(condition) {
    this.popUpSubject.next(condition);
  }

  showFilter(condition) {
    this.showFilterPopUp.next(condition);
  }

  showErrorinPassengerPage() {
    swal({
      title: 'Oops!',
      text: "Something's not quite right! Please correct the highlighted errors before proceeding.",
      icon: 'warning',
      buttons: { ok: true },
      closeOnClickOutside: true
    });
  }

  showFareRules() {
    swal({
      title: 'Oops!',
      text: "Something's not quite right! Please correct the highlighted errors before proceeding. Something's not quite right! Please correct the highlighted errors before proceeding.Something's not quite right! Please correct the highlighted errors before proceeding.Something's not quite right! Please correct the highlighted errors before proceeding.Something's not quite right! Please correct the highlighted errors before proceeding.Something's not quite right! Please correct the highlighted errors before proceeding.Something's not quite right! Please correct the highlighted errors before proceeding.Something's not quite right! Please correct the highlighted errors before proceeding.Something's not quite right! Please correct the highlighted errors before proceeding.",
      icon: 'warning',
      buttons: { ok: true },
      closeOnClickOutside: true
    });
  }

  somethingBadHappened(reload?: boolean) {
    swal({
      title: 'Oops!',
      text: 'Unfortunately this flight is no longer available! Check the availability of alternative flights.',
      //icon: "assets/img/error-img/sad-icon.png",
      icon: 'warning',
      buttons: {
        ok: {
          text: 'OK',
          value: true,
          className: 'continue'
        }
      },
      closeOnClickOutside: true
    }).then(() => {
      if (reload) {
        window.location.reload();
      } else {
        window.location.href = '/';
      }
    });
  }

  higherThanSeatsNumber(reload?: boolean) {
    swal({
      title: 'Oops!',
      text: 'You are selecting a number of passengers higher than the number of seats left at the shown price. Please note that the final price for the chosen number of passengers might be higher than the price shown!',
      //icon: "assets/img/error-img/sad-icon.png",
      icon: 'warning',
      buttons: {
        ok: {
          text: 'OK',
          value: true,
          className: 'continue'
        }
      },
      closeOnClickOutside: true
    });
  }

  errorCode_1(reload?: boolean) {
    swal({
      title: 'Oops!',
      text: 'Unfortunately something went wrong and we could not proceed with the booking. Try another flight!',
      //icon: "assets/img/error-img/sad-icon.png",
      icon: 'warning',
      buttons: {
        ok: {
          text: 'OK',
          value: true,
          className: 'continue'
        }
      },
      closeOnClickOutside: true
    }).then(() => {
      if (reload) {
        window.location.reload();
      } else {
        window.location.href = '/';
      }
    });
  }

  sessionExpired() {
    return swal({
      title: 'Session timeout!',
      className: 'price-increase',
      text: 'Unfortunately this session has expired. To continue booking this flight start a new session refreshing the availability.',
      icon: 'warning',
      buttons: {
        lookForAnother: {
          text: 'Look for another flight',
          value: 'back',
          className: 'lookForAnother'
        },
        continue: {
          text: 'Refresh availability',
          value: 'continue',
          className: 'continue'
        }
      },
      closeOnClickOutside: false
    });
  }

  sessionExpiredGoHomePage() {
    return swal({
      title: 'Oops!',
      text: 'Unfortunately this session has expired.',
      //icon: "assets/img/error-img/sad-icon.png",
      icon: 'warning',
      buttons: {
        ok: {
          text: 'OK',
          value: true,
          className: 'continue'
        }
      },
      closeOnClickOutside: true
    });
  }

  paymentError() {
    swal({
      title: 'Oops!',
      text: 'Unfortunately the booking has failed. Try a different flight!',
      icon: 'warning',
      buttons: { ok: false },
      closeOnClickOutside: false
    });
  }

  noAvailableFlight() {
    swal({
      title: 'Oops!',
      text: 'Unfortunately no flight deal is available on your selected date.',
      icon: 'warning',
      buttons: { ok: true },
      closeOnClickOutside: true
    });
  }

  bookingFailed() {
    swal({
      title: 'Oops!',
      text: 'Unfortunately the booking has failed. Try a different flight!',
      icon: 'warning',
      buttons: { ok: true },
      closeOnClickOutside: false
    }).then(() => {
      window.location.href = '/';
    });
  }

  // showSuccess() {
  //     swal({
  //         title: "GOOD JOB!",
  //         text: "GREAT YOU HAVE DONE IT :)",
  //         icon: "success",
  //         buttons: { ok: true },
  //         closeOnClickOutside: true,
  //     });
  // }

  followingRunToFly(username: string) {
    swal({
      title: 'Great' + ' ' + username + ' !',
      text: 'Thanks for signing up! We have just sent you a confirmation email. Check your inbox.',
      icon: 'success',
      buttons: { ok: true },
      closeOnClickOutside: true,
      className: 'subscription'
    });
  }

  errorOnSubscription() {
    swal({
      title: 'Oops!',
      text: 'It seems this email address is already registered with us.',
      icon: 'warning',
      buttons: { ok: true },
      closeOnClickOutside: true,
      className: 'subscription'
    });
  }

  showPriceChangedWarning() {
    swal({
      title: 'Price Changed!',
      text: 'Please notice that the price is changed.',
      icon: 'warning',
      buttons: { ok: true },
      closeOnClickOutside: true
    });
  }

  alertForHelp() {
    swal({
      text: "Thanks for contacting us. We'll be in contact with you shortly to answer your enquiry.",
      icon: 'success',
      buttons: { ok: true },
      closeOnClickOutside: true,
      className: 'subscription'
    });
  }

  showPriceIncreaseWarning(newPrice: number, oldPrice: number) {
    const newPriceFormated = `<strong>&pound;${newPrice.toFixed(2).split('.')[0]}</strong>.${newPrice.toFixed(2).split('.')[1]}`;
    const oldPriceFormated = `<strong>&pound;${oldPrice.toFixed(2).split('.')[0]}</strong>.${oldPrice.toFixed(2).split('.')[1]}`;

    const template = `Unfortunately the price (per Adult) of your flight has increased from ${oldPriceFormated} to ${newPriceFormated}. This occurred because the airline could not confirm the original price due to availability or price changes that occurred after we posted the latest price on our site.`;

    return swal({
      title: 'Oops...price increase!',
      className: 'price-increase',
      content: {
        element: 'span',
        attributes: {
          innerHTML: `${template}`
        }
      },
      icon: 'warning',
      buttons: {
        lookForAnother: {
          text: 'Look for another flight',
          value: 'back',
          className: 'lookForAnother'
        },
        continue: {
          text: 'Continue Booking',
          value: 'continue',
          className: 'continue'
        }
      },
      closeOnClickOutside: true
    });
  }

  showPriceDecreaseWarning(newPrice, oldPrice) {
    const newPriceFormated = `<strong>&pound;${newPrice.toFixed(2).split('.')[0]}</strong>.${newPrice.toFixed(2).split('.')[1]}`;
    const oldPriceFormated = `<strong>&pound;${oldPrice.toFixed(2).split('.')[0]}</strong>.${oldPrice.toFixed(2).split('.')[1]}`;

    const template = `Good news! The price of your flight has dropped from ${oldPriceFormated} to ${newPriceFormated}.`;

    return swal({
      title: 'Price drop!',
      content: {
        element: 'span',
        attributes: {
          innerHTML: `${template}`
        }
      },
      // icon: "assets/img/error-img/smile-icon.png",
      icon: 'warning',
      className: 'icon-size',
      buttons: {
        continue: {
          text: 'Continue Booking',
          value: 'continue'
        }
      },
      closeOnClickOutside: true
    });
  }
}
