<div *ngIf="showTicketConditions" class="flight-details-box" [@toggleAnimation]>
  <div class="flight-details-box-wrapp">
    <h4 class="box-title">
      <span class="title">Ticket conditions</span>
      <button
        (click)="hideTicketConditions()"
        class="transition-all close-button">
        <span class="glyphicon glyphicon-remove"></span>
      </button>
    </h4>

    <div class="flight-details-box-content">
      <div>
        <ng-templete [ngSwitch]="!!fareRules">
          <ng-templete *ngSwitchCase="false">
            <!-- amadeus -->
            <p *ngIf="!isOneWay && fareRulesType === 1">
              The full ticket conditions (or fare rules) for this flight will be only
              available starting from the Passenger Details page (step 3 of the
              booking). However, please note that in general most tickets sold on this
              website can't be changed (non-changeable) or refunded (non-refundable).
            </p>
            <!-- ypsilon -->
            <p *ngIf="!isOneWay && fareRulesType === 2">
              Please note that this ticket can't be changed (non-changeable) or
              refunded (non-refundable). You might find additional information for
              this flight starting from the Passenger Details page (step 3 of the
              booking).
            </p>
            <p *ngIf="isOneWay && fareRulesType === 1">
              The full ticket conditions (or fare rules) for this flight will be only
              available starting from the next page (Passenger Details page, i.e. step
              2 of the booking). However, please note that in general most tickets
              sold on this website can't be changed (non-changeable) or refunded
              (non-refundable).
            </p>
            <p *ngIf="isOneWay && fareRulesType === 2">
              Please note that this ticket can't be changed (non-changeable) or
              refunded (non-refundable). You might find additional information for
              this flight starting from the next page (Passenger Details page, i.e.
              step 2 of the booking).
            </p>
          </ng-templete>
          <p *ngSwitchCase="true" [innerHTML]="fareRules | addNewLine"></p>
        </ng-templete>
      </div>
    </div>
  </div>
</div>