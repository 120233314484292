import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { NgModule, ErrorHandler } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// third parties
import { AppComponent } from './app.component';
import { AppRoutes } from './app.routing';

import { CookieMessageComponent } from './components/cookie-message/cookie-message.component';

import { GlobalErrorHandler } from './services/global-error-handler/global-error-handler.service';

// modules
import { ServicesModule } from 'app/services/services.module';
import { ComponentsModule } from 'app/components/components.module';
import { PipesModule } from 'app/pipes/pipes.module';

@NgModule({
  declarations: [AppComponent, CookieMessageComponent],
  imports: [
    RouterModule.forRoot(AppRoutes),
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    ServicesModule,
    ComponentsModule,
    PipesModule
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
