<div class="calendar" #calendar>
  <div class="break"></div>
  <div class="calendar-nav">
    <span class="title"> {{ currentMonth }} {{ currentYear }}</span>
    <div class="wrapp next" *ngIf="calendarDays.length > defaultNumberOfDays">
      <button
        (click)="scrollUp()"
        (mouseenter)="scrollPosition !== 0 && onHover('up')"
        (mouseleave)="onMouseLeave('up')"
        [ngClass]="{ disable: scrollPosition === 0 }"
        class="white-btn prev-btn">
        <span class="previous-icon">
          <img class="arrow" [src]="upImgUrl" />
        </span>
      </button>
      <button
        (click)="scrollDown()"
        (mouseenter)="
          scrollPosition + heightOfCell <= maxScroll && onHover('down')
        "
        (mouseleave)="onMouseLeave('down')"
        [ngClass]="{ disable: scrollPosition + heightOfCell > maxScroll }"
        class="white-btn next-btn">
        <span class="next-icon">
          <img class="arrow" [src]="downImgUrl" />
        </span>
      </button>
    </div>
  </div>

  <div class="table-row week-names">
    <!-- monday -->
    <div class="table-cell">
      <span>Mon</span>
    </div>
    <!-- tuesday -->
    <div class="table-cell">
      <span>Tue</span>
    </div>
    <!-- wednesday -->
    <div class="table-cell">
      <span>Wed</span>
    </div>
    <!-- thursday -->
    <div class="table-cell">
      <span>Thu</span>
    </div>
    <!-- friday -->
    <div class="table-cell">
      <span>Fri</span>
    </div>
    <!-- saturday -->
    <div class="table-cell weekend">
      <span>Sat</span>
    </div>
    <!-- sunday -->
    <div class="table-cell weekend">
      <span>Sun</span>
    </div>
  </div>

  <div class="month-wrapp" #monthWrap (window:resize)="onResize($event)">
    <app-calendar-day
      (clickOutput)="selectDay($event)"
      *ngFor="let calendarDay of calendarDays"
      [highlightPreviousDay]="highlightPreviousDay"
      [data]="calendarDay">
    </app-calendar-day>
  </div>

  <div class="calendar-nav bottom-nav">
    <div *ngIf="isOtherOutbound" class="flex">
      <span class="other-outbound-dot"></span>
      <span class="text other-outbound">Other outbound</span>
    </div>
    <div *ngIf="isFlightUnavailable" class="flex">
      <img class="no" src="assets/img/calendar/no.png" />
      <span class="text">Flight not available</span>
    </div>
  </div>
  <div class="break"></div>
</div>
