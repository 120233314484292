import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-keep-waiting',
  templateUrl: './keep-waiting.component.html',
  styleUrls: ['./keep-waiting.component.scss']
})
export class KeepWaitingComponent {
  @Input() headerText: string = "Almost there...";
  @Input() contentText: string = `
    We are confirming with the airline that the flight you selected is still available. 
    This may take up to 30 seconds.
  `;

  constructor() {}
}
