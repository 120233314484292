import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ElementRef,
  HostListener,
  AfterViewInit
} from '@angular/core';
import { Flight } from 'app/shared';
import { Subject } from 'rxjs';
import { debounceTime, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnChanges, AfterViewInit {
  searchText: string = '';
  @Input() flights: Array<Flight>;
  @Output() flightsChanged: EventEmitter<any> = new EventEmitter<any>();
  private searchSubject: Subject<string> = new Subject<string>();

  @ViewChild('myInput') myInput: ElementRef;

  placeholderText: string;

  constructor() {
    this.updatePlaceholder();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.updatePlaceholder();
  }

  updatePlaceholder() {
    if (window.innerWidth > 500) {
      this.placeholderText = 'Find a destination (city, country or island)';
    } else if (window.innerWidth <= 500 && window.innerWidth >= 375) {
      this.placeholderText = 'Find a city, country or island';
    } else {
      this.placeholderText = 'Find a city, country or island';
    }
  }

  ngAfterViewInit() {
    // Set focus on the input element after the view has been initialized
    this.myInput.nativeElement.focus();
  }

  onInputChange(value: string): void {
    this.searchText = value;
    this.searchSubject.next(value);

    this.searchSubject
      .pipe(debounceTime(300), startWith(value))
      .subscribe(value => {
        this.searchCountries(value);
      });
  }

  clearSearch(): void {
    this.searchText = '';
    this.myInput.nativeElement.focus();
    this.flightsChanged.emit(undefined);
  }

  searchCountries(query: string): void {
    if (query) {
      query = query.toLocaleLowerCase();
      let flights = [];
      
      flights = this.flights.filter(flight => {
        const country =
          flight.outboundTickets[
            flight.outboundTickets.length - 1
          ].arrival.country.toLocaleLowerCase();
        const city =
          flight.outboundTickets[
            flight.outboundTickets.length - 1
          ].arrival.city.toLocaleLowerCase();
        const countryInParentheses =
          this.getCountryInParentheses(country).toLocaleLowerCase();
  
        // Split the country and city names by both spaces and hyphens
        const countryParts = country.split(/[\s-]/); // "Preveza-Lefkada" => ["preveza", "lefkada"]
        const cityParts = city.split(/[\s-]/);       // "Addis Ababa" => ["addis", "ababa"]
  
        // Check if the query matches

        return (
          this.compareFirstNCharacters(query, city, query.length) ||
          this.compareFirstNCharacters(query, country, query.length) ||
          this.compareFirstNCharacters(query, countryInParentheses, query.length) ||
          countryParts.some(part => this.compareFirstNCharacters(query, part, query.length)) ||
          cityParts.some(part => this.compareFirstNCharacters(query, part, query.length))
        );
      });
  
      const emitData = {
        flights: flights,
        text: this.searchText
      };
      this.flightsChanged.emit(emitData);
    } else {
      this.flightsChanged.emit(undefined);
    }
  }

  getCountryInParentheses(country: string): string {
    country = country.toLocaleLowerCase();
    const matches = country.match(/\((.*?)\)/);
    return matches ? matches[1] : '';
  }

  compareFirstNCharacters(str1: string, str2: string, length: number): boolean {
    const substr1 = str1.substring(0, length);
    const substr2 = str2.substring(0, length);

    return substr1 !== '' && substr2 !== '' && substr1 === substr2;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['flights'].currentValue && this.searchText) {
      this.searchCountries(this.searchText);
    }
  }
}
