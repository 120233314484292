<div class="row">
  <div class="col-md-12 col-sm-12 col-xs-24 col">
    <p class="label">
      Email Address
      <span class="note">(we'll send confirmation here)</span>
    </p>
    <div class="input-wrapp">
      <div *ngIf="form.controls['email'].valid" class="toggle ok">
        <span class="glyphicon glyphicon-ok"></span>
      </div>
      <div
        *ngIf="formSubmitAttempt && form.controls['email'].invalid"
        class="toggle error">
        <span class="glyphicon glyphicon-remove"></span>
      </div>
      <input
        [(ngModel)]="booker.email"
        [formControl]="form.controls['email']"
        type="email"
        class="default-input email" />
    </div>
  </div>
  <div class="col-md-12 col-sm-12 col-xs-24 col">
    <p class="label">
      Confirm Email Address <span class="note">(must type again)</span>
    </p>
    <div class="input-wrapp">
      <div *ngIf="form.controls['confirmEmail'].valid" class="toggle ok">
        <span class="glyphicon glyphicon-ok"></span>
      </div>
      <div
        *ngIf="
          formSubmitAttempt &&
          (form.controls['confirmEmail'].hasError('required') ||
            form.controls['confirmEmail'].errors?.equalTo)
        "
        class="toggle error">
        <span class="glyphicon glyphicon-remove"></span>
      </div>
      <input
        [(ngModel)]="booker.confirmEmail"
        [formControl]="form.controls['confirmEmail']"
        type="email"
        class="default-input emailConfirm"
        required
        (paste)="$event.preventDefault()" />
    </div>
  </div>
</div>
<div appLoading [loading]="!codesLoaded">
  <div *ngIf="codesLoaded" class="row">
    <div class="col-md-12 col-sm-24 col-xs-24 col">
      <p class="label">Country code</p>
      <app-select
        ngDefaultControl
        [initialSelectedOption]="booker.dialCode"
        (selectAction)="booker.dialCode = $event.value"
        [formSubmitAttempt]="formSubmitAttempt"
        [options]="dialCodes"
        [formControl]="form.controls['dialCode']"></app-select>
    </div>
    <div class="col-md-12 col-sm-12 col-xs-24 col">
      <p class="label">Phone Number</p>
      <div class="input-wrapp">
        <div *ngIf="form.controls['phoneNumber'].valid" class="toggle ok">
          <span class="glyphicon glyphicon-ok"></span>
        </div>
        <div
          *ngIf="
            formSubmitAttempt &&
            form.controls['phoneNumber'].hasError('required')
          "
          class="toggle error">
          <span class="glyphicon glyphicon-remove"></span>
        </div>
        <input
          [(ngModel)]="booker.phoneNumber"
          [formControl]="form.controls['phoneNumber']"
          type="tel"
          numberOnly
          class="default-input phone"
          required />
      </div>
    </div>
  </div>
  <div *ngIf="codesLoaded" class="row">
    <div class="col-md-12 col-sm-24 col-xs-24 col">
      <p class="label">
        Billing Address
        <span class="note">(where your payment card is registered)</span>
      </p>
      <div class="input-wrapp">
        <div *ngIf="form.controls['address'].valid" class="toggle ok">
          <span class="glyphicon glyphicon-ok"></span>
        </div>
        <div
          *ngIf="
            formSubmitAttempt && form.controls['address'].hasError('required')
          "
          class="toggle error">
          <span class="glyphicon glyphicon-remove"></span>
        </div>
        <input
          [(ngModel)]="booker.address"
          [formControl]="form.controls['address']"
          type="text"
          class="default-input normal"
          required />
      </div>
    </div>
    <div class="col-md-12 col-sm-24 col-xs-24 col">
      <p class="label">City/Town</p>
      <div class="input-wrapp">
        <div *ngIf="form.controls['city'].valid" class="toggle ok">
          <span class="glyphicon glyphicon-ok"></span>
        </div>
        <div
          *ngIf="
            formSubmitAttempt && form.controls['city'].hasError('required')
          "
          class="toggle error">
          <span class="glyphicon glyphicon-remove"></span>
        </div>
        <input
          [(ngModel)]="booker.city"
          [formControl]="form.controls['city']"
          type="text"
          class="default-input normal"
          required />
      </div>
    </div>
  </div>
  <div *ngIf="codesLoaded" class="row">
    <div class="col-md-12 col-sm-24 col-xs-24 col">
      <p class="label">Postcode</p>
      <div class="input-wrapp">
        <div *ngIf="form.controls['postCode'].valid" class="toggle ok">
          <span class="glyphicon glyphicon-ok"></span>
        </div>
        <div
          *ngIf="formSubmitAttempt && form.controls['postCode'].invalid"
          class="toggle error">
          <span class="glyphicon glyphicon-remove"></span>
        </div>
        <input
          [(ngModel)]="booker.postCode"
          [formControl]="form.controls['postCode']"
          type="text"
          class="default-input normal"
          required />
      </div>
    </div>
    <div class="col-md-12 col-sm-24 col-xs-24 col">
      <p class="label">Country</p>
      <div class="default-select">
        <div class="input-wrapp">
          <app-select
            ngDefaultControl
            [initialSelectedOption]="booker.countryCode"
            (selectAction)="booker.countryCode = $event.value"
            [formSubmitAttempt]="formSubmitAttempt"
            [options]="countries"
            [formControl]="form.controls['countryCode']"></app-select>
        </div>
      </div>
    </div>
  </div>
</div>
