<header class="site-wrapper">
  <div class="site-container site-container-home">
    <div class="logo-container">
      <a routerLink="/" class="logo left">
        <img src="assets/img/runtofly-logo.svg" alt="Run To Fly" />
      </a>
    </div>
    <nav class="right">
      <ul>
        <li>
          <a routerLink="/about" class="transition-all">
            About</a
          >
        </li>
        <li>
          <a routerLink="/help" class="transition-all">
            Help</a
          >
        </li>
      </ul>
    </nav>

    <!-- MOBILE MENU -->
    <div class="mobile-menu">
      <a (click)="toggle()" class="menu-butt flaticon-menu55 link"></a>
      <div class="sub-menu" [@toggleState]="state">
        <ul>
          <li>
            <a routerLink="/about" class="transition-all">
              <span class="glyphicon glyphicon-info-sign transition-all"></span
              >About</a
            >
          </li>
          <li>
            <a routerLink="/help" class="transition-all">
              <span
                class="glyphicon glyphicon-question-sign transition-all"></span
              >Help</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</header>
