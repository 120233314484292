import { Component, OnInit, Input } from '@angular/core';
import * as moment from 'moment';

import { ConfigService } from 'app/services/config/config.service';
import { Ticket, Flight } from 'app/shared/index';

@Component({
  selector: 'app-flight-details',
  templateUrl: './flight-details.component.html',
  styleUrls: ['./flight-details.component.css']
})
export class FlightDetailsComponent implements OnInit {
  @Input() flight: Flight;
  @Input() type: string = 'outbound';
  @Input() highlightDepartureArivalDiff: boolean = true;
  @Input() confirmation_page: boolean = false;
  @Input() homeOrReturnPage: boolean = false;
  @Input() showCountryMessage: boolean = false;
  @Input() returnPage!: boolean;

  highlightDeparture: boolean;
  highlightArrival: boolean;
  highlightBag: boolean = false;

  tickets: Array<Ticket>;
  imgUrl: string;
  totalDuration: moment.Duration = moment.duration(0, 'hours');
  fareRulesType: number;
  isOneWay: boolean;

  constructor(private config: ConfigService) {
    this.imgUrl = this.config.get('IMAGE_HOST_URL');
  }
  ngOnInit() {
    this.tickets = this.flight.outboundTickets;

    const flightType = localStorage.getItem('flightType');
    this.isOneWay = flightType === 'one-way';

    if (this.highlightDepartureArivalDiff) {
      this.highlightDeparture = !this.flight.isDepartureAndArrivalSame;
      this.highlightArrival = !this.flight.isArrivalAndDepartureSame;
    }

    if (this.type === 'return') {
      this.tickets = this.flight.returnTickets;

      if (this.highlightDepartureArivalDiff) {
        this.highlightDeparture = !this.flight.isArrivalAndDepartureSame;
        this.highlightArrival = !this.flight.isDepartureAndArrivalSame;
      }

      this.highlightBaggage();
    }
    this.getStop();
    this.fareRulesType = this.flight?.contentProvider;
  }

  highlightBaggage() {
    if (
      (!this.flight.isDepartureHasBag && this.flight.isArrivalHasBag) ||
      (this.flight.isDepartureHasBag && !this.flight.isArrivalHasBag)
    ) {
      this.highlightBag = true;
    }
  }

  getStop() {
    this.tickets.map((ticket, index) => {
      this.totalDuration.add(ticket.duration);
      if (index > 0) {
        const previousTicket = this.tickets[index - 1];
        this.totalDuration.add(
          ticket.departure.date.diff(previousTicket.arrival.date)
        );
      }
    });
  }

  highlightDate(index: number) {
    if (index === 0) return false;
    const prevDate = this.tickets[index - 1].arrival.date;
    const currentDate = this.tickets[index].departure.date;
    return !currentDate.isSame(prevDate, 'day');
  }
}
