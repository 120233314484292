import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { FlightManagerService } from 'services/flight-manager/flight-manager.service';

@Component({
  selector: 'app-ticket-conditions-popup',
  templateUrl: './ticket-conditions-popup.component.html',
  styleUrls: ['./ticket-conditions-popup.component.css']
})
export class TicketConditionsPopup implements OnInit {
  @Input() showTicketConditions: boolean = false;
  @Input() isOneWay: boolean = false;
  @Input() fareRulesType: number;

  @Output() toggleShowTicketConditions: EventEmitter<boolean> = new EventEmitter(false);

  subscribtion: Subscription;
  fareRules: string;

  constructor(private flightManagerService: FlightManagerService) {}

  ngOnInit() {
    this.subscribtion = this.flightManagerService.fareRulesOfFlight.subscribe(
      data => {
        this.fareRules = data;
      }
    );
  }

  hideTicketConditions() {
    this.toggleShowTicketConditions.emit(false);
  }
}
