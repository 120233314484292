import { Routes } from '@angular/router';

export const AppRoutes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./pages/home-page/home-page.module').then(m => m.HomePageModule)
  },
  {
    path: '',
    loadChildren: () =>
      import('./pages/static-pages/static-pages.module').then(
        m => m.StaticPagesModule
      )
  },
  {
    path: 'wizard',
    loadChildren: () =>
      import('./pages/wizard/wizard.module').then(m => m.WizardModule)
  },
  {
    path: '**',
    redirectTo: 'session/404'
  }
];
