import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'changeToDecimal'
})
export class ChangeToDecimal implements PipeTransform {
  transform(value: moment.Moment, value2: moment.Moment) {
    const val1 = moment(value);
    const val2 = moment(value2);

    return val1.diff(val2, 'hours', true);
  }
}
