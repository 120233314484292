import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-show-time',
  templateUrl: './show-time.component.html',
  styleUrls: ['./show-time.component.css']
})
export class ShowTimeComponent implements OnInit, OnChanges {
  @Input() time: string;
  @Input() minutes: boolean = false;
  @Input() flight_card: boolean = false;
  @Input() img_card: boolean = false;
  @Input() isMobile: boolean = false;

  hours$ = new BehaviorSubject<string>('');
  text_hours$ = new BehaviorSubject<string>('');
  minutes$ = new BehaviorSubject<string>('');
  text_minutes$ = new BehaviorSubject<string>('');
  seconds$ = new BehaviorSubject<string>('');
  text_seconds$ = new BehaviorSubject<string>('');

  constructor() {}

  ngOnInit(): void {
    if (!this.minutes) {
      this.hours$.next(this.time.substr(0, 2));
      this.text_hours$.next(this.time.substr(2, 1));
      this.minutes$.next(this.time.substr(4, 2));
      this.text_minutes$.next(this.time.substr(6, 1));
      this.seconds$.next(this.time.substr(8, 2));
      this.text_seconds$.next(this.time.substr(10, 1));
    } else {
      this.minutes$.next(this.time.substr(0, 2));
      this.text_minutes$.next(this.time.substr(2, 1));
      this.seconds$.next(this.time.substr(4, 2));
      this.text_seconds$.next(this.time.substr(6, 1));
    }
  }

  ngOnChanges() {
    if (this.time && !this.minutes) {
      this.hours$.next(this.time.substr(0, 2));
      this.text_hours$.next(this.time.substr(2, 1));
      this.minutes$.next(this.time.substr(4, 2));
      this.text_minutes$.next(this.time.substr(6, 1));
      this.seconds$.next(this.time.substr(8, 2));
      this.text_seconds$.next(this.time.substr(10, 1));
    } else if (this.time && this.minutes) {
      this.minutes$.next(this.time.substr(0, 2));
      this.text_minutes$.next(this.time.substr(2, 1));
      this.seconds$.next(this.time.substr(4, 2));
      this.text_seconds$.next(this.time.substr(6, 1));
    }
  }
}
