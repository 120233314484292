import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FlightManagerService } from 'services/flight-manager/flight-manager.service';
import { PassengerList } from 'app/shared';

@Component({
  selector: 'app-passengers-chooser',
  templateUrl: './passengers-chooser.component.html',
  styleUrls: ['./passengers-chooser.component.css']
})
export class PassengersChooserComponent implements OnInit {
  @Output() passengersUpdated = new EventEmitter<any>();
  @Input() isOneWay: boolean;

  passengerList: PassengerList;

  constructor(private flightManager: FlightManagerService) {}

  ngOnInit() {
    this.passengerList = this.flightManager.passengerList;
  }

  increase(paxType) {
    this.changeCount(paxType, 1);
  }

  canIncrease(paxType) {
    return this.canChangeCount(paxType, 1);
  }

  decrease(paxType) {
    this.changeCount(paxType, -1);
  }

  canDecrease(paxType) {
    return this.canChangeCount(paxType, -1);
  }

  changeCount(paxType, increment) {
    switch (paxType) {
      case 'adults':
        this.passengerList.adult += increment;
        break;
      case 'children':
        this.passengerList.children += increment;
        break;
      case 'infants':
        this.passengerList.infants += increment;
        break;
    }

    this.passengersUpdated.emit(this.passengerList);
    this.flightManager.passengerList = this.passengerList;
  }

  canChangeCount(paxType, increment) {
    switch (paxType) {
      case 'adults':
        return this.passengerList.canSetAdult(
          this.passengerList.adult + increment
        );
      case 'children':
        return this.passengerList.canSetChildren(
          this.passengerList.children + increment
        );
      case 'infants':
        return this.passengerList.canSetInfant(
          this.passengerList.infants + increment
        );
    }
  }
}
