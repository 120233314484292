import { Component, OnInit } from '@angular/core';
import { ApiSessionService } from 'services/api-session/api-session.service';
import { ConfigService } from 'services/config/config.service';
import { Router, NavigationEnd } from '@angular/router';
import { MetaService } from 'services/meta/meta.service';
import { AlertService } from 'app/services/alert/alert.service';
import { Title } from '@angular/platform-browser';

declare let gtag: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'app';
  loading: boolean = true;

  constructor(
    private configServie: ConfigService,
    private apiSessionService: ApiSessionService,
    public router: Router,
    public alertService: AlertService,
    private meta: MetaService,
    private titleService: Title
  ) {
    this.titleService.setTitle('');

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        gtag('config', 'UA-142987094-1', {
          page_path: event.urlAfterRedirects
        });
      }
    });
  }

  ngOnInit() {
    localStorage.removeItem('flightType')
    this.configServie.load().subscribe(() => {
      this.meta.addMeta(
        'google-site-verification',
        this.configServie.get('GOOGLE_SITE_VERIFICATION')
      );

      this.apiSessionService.init();
      if (this.apiSessionService._geoData.countryCode) {
        this.apiSessionService.initApiSession();
      } else {
        this.apiSessionService.tryGetGeoData().subscribe(() => {
          if (this.apiSessionService._geoData) {
            this.apiSessionService.initApiSession().subscribe(
              () => {
                this.loading = false;
                this.apiSessionService.getLocations()
                this.apiSessionService.getLabels()
              },
              err => {
                console.log(err);
              }
            );
          }
        });
      }
    });
  }
}
