import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable()
export class ConfigService {
  private _config: object;
  private _env: string;

  constructor(private _http: HttpClient) {}

  load() {
    this._env = environment.status;
    return this._http.get('./assets/config/' + this._env + '.json').pipe(
      map(data => {
        this._config = data;
      })
    );
  }

  // Is app in the development mode?
  isDevmode() {
    return this._env === 'development';
  }

  // Gets current env
  getEnv() {
    return this._env;
  }

  // Gets API route based on the provided key
  getApi(key: string): string {
    const ENDPOINT = this._config['API_ENDPOINTS'][key];
    if (this._config['CORS']) return this._config['HOST_API'] + ENDPOINT;
    return this._config['PREFIX'] + ENDPOINT;
  }

  // Gets a value of specified property in the configuration file
  get(key: any) {
    return this._config[key];
  }
}
