import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { RestService } from 'services/rest/rest.service';
import { GeoData, Coordinates } from 'app/shared';
import { Subject } from 'rxjs';

@Injectable()
export class ApiSessionService {
  private readonly BROWSER_ID_KEY = 'bid';
  private readonly SESSIONS_ID_KEY = 'sid';

  private sessionId: string = null;
  private browserId: string = null;
  public locations$ = new Subject();
  public labels$ = new Subject();

  public _geoData: GeoData;
  public get geoData(): GeoData {
    return this._geoData;
  }

  private _defaultCityCode: string;
  public get defaultCityCode(): string {
    return this._defaultCityCode;
  }

  constructor(private restService: RestService) {
    this._geoData = new GeoData();
  }

  init() {
    this.tryGetGeoDataFromURL();
  }

  initApiSession() {
    this.browserId = localStorage.getItem(this.BROWSER_ID_KEY);
    this.sessionId = sessionStorage.getItem(this.SESSIONS_ID_KEY);

    let timeZoneOffset = new Date().getTimezoneOffset();
    timeZoneOffset = timeZoneOffset * Math.sign(timeZoneOffset);

    return this.restService
      .initApiSession(
        this.browserId,
        timeZoneOffset,
        this._geoData.coordinates,
        this._geoData.countryCode
      )
      .pipe(
        map(
          result => {
            this.storeBrowserId(result.browserId);
            this.storeSessionId(result.sessionId);
            this.restService.setSessionId(this.sessionId);
          },
          error => {
            console.log(error);
          }
        )
      );
  }

  public getLocations() {
    this.restService.getLocations("GB").subscribe(
      result => {
        this.locations$.next(result);
      }
    );
  }


  public getLabels() {
    this.restService.getLabels().subscribe(
      result => {
        this.labels$.next(result);
      }
    );
  }

  tryGetGeoData() {
    return this.restService.getGeolocationData().pipe(
      map(result => {
        this._geoData = result;
      })
    );
  }

  tryGetGeoDataFromURL() {
    const lat = this.getParameterByName('lat');
    const long = this.getParameterByName('long');
    const countyCode = this.getParameterByName('countryCode');

    if (lat && long && countyCode) {
      this._geoData.coordinates = new Coordinates({
        latitude: Number.parseFloat(lat),
        longitude: Number.parseFloat(long)
      });
      this._geoData.countryCode = countyCode;
    }
  }

  getBrowserId(): string {
    return this.browserId;
  }

  storeBrowserId(value: string) {
    if (!value) return;
    localStorage.setItem(this.BROWSER_ID_KEY, value);
    this.browserId = value;
  }

  getSessionId(): string {
    return this.sessionId;
  }

  storeSessionId(value: string) {
    if (!value) return;
    sessionStorage.setItem(this.SESSIONS_ID_KEY, value);
    this.sessionId = value;
  }

  getParameterByName(name) {
    const url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }
}
