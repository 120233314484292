import {
  Component,
  EventEmitter,
  Output,
  Input,
  OnInit,
  OnDestroy
} from '@angular/core';
import { TableHeaderService } from 'app/components/table-header/table-header.service';
import { FlightAccordionService } from 'components/flight-accordion/flight-accordion.service';

import { Subscription } from 'rxjs';

@Component({
  selector: 'app-table-header',
  templateUrl: './table-header.component.html',
  styleUrls: ['./table-header.component.css']
})
export class TableHeaderComponent implements OnInit, OnDestroy {
  @Output() sort: EventEmitter<number> = new EventEmitter();
  @Input() isActive: boolean;
  @Input() isDefault: boolean;
  @Input() cssClasses: string;
  @Input() showArrows: boolean;

  order: number;

  id: string;
  subscription: Subscription;

  constructor(
    private tableHeaderService: TableHeaderService,
    private accordionService: FlightAccordionService
  ) {
    this.id = Math.random() + '' + Math.random();
    this.subscription = this.tableHeaderService.onClick.subscribe(headerId => {
      if (headerId === "setActive") {
        this.order = this.isDefault ? 1 : null;
        this.isActive = this.isDefault;
      } else if (this.id != headerId) {
        this.isActive = false;
        this.order = null;
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ngOnInit() {
    if (this.isActive) this.flip();
  }

  flip() {
    this.accordionService.reset();
    this.isActive = true;
    if (!this.order) this.order = -1;
    this.order = -1 * this.order;
    this.sort.emit(this.order);

    this.tableHeaderService.onClick.emit(this.id);
  }
}
