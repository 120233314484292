<div class="row">
  <div class="col-md-8 col-sm-12 col-xs-24 col">
    <p class="label">First Name</p>
    <div class="input-wrapp">
      <div
        *ngIf="
          (form.controls['firstName'].valid &&
            form.controls['firstName'].touched) ||
          !form.controls['firstName'].invalid
        "
        class="toggle ok">
        <span class="glyphicon glyphicon-ok"></span>
      </div>
      <div
        *ngIf="formSubmitAttempt && form.controls['firstName'].invalid"
        class="toggle error">
        <span class="glyphicon glyphicon-remove"></span>
      </div>
      <input
        [(ngModel)]="passenger.firstName"
        placeholder="i.e. John"
        [formControl]="form.controls['firstName']"
        type="text"
        class="default-input normal first-name" />
    </div>
  </div>
  <div class="col-md-8 col-sm-12 col-xs-24 col">
    <p class="label">Last Name (Surname)</p>
    <div class="input-wrapp">
      <div
        *ngIf="
          (form.controls['lastName'].valid &&
            form.controls['lastName'].touched) ||
          !form.controls['lastName'].invalid
        "
        class="toggle ok">
        <span class="glyphicon glyphicon-ok"></span>
      </div>
      <div
        *ngIf="formSubmitAttempt && form.controls['lastName'].invalid"
        class="toggle error">
        <span class="glyphicon glyphicon-remove"></span>
      </div>
      <input
        [(ngModel)]="passenger.lastName"
        placeholder="i.e. Smith"
        [formControl]="form.controls['lastName']"
        type="text"
        class="default-input normal last-name" />
    </div>
  </div>
  <div class="col-md-8 col-sm-24 col-xs-24 col">
    <p class="label">Gender</p>
    <app-select
      ngDefaultControl
      [initialSelectedOption]="passenger.gender"
      (selectAction)="passenger.gender = $event.value"
      [formSubmitAttempt]="formSubmitAttempt"
      [options]="[
        { label: 'Male', value: 'M' },
        { label: 'Female', value: 'F' }
      ]"
      [formControl]="form.controls['gender']"></app-select>
  </div>
</div>
<div class="row">
  <div class="col-md-8 col-sm-5 col-xs-24 col">
    <p class="label">Date of Birth</p>
    <app-select
      ngDefaultControl
      [caption]="'Day'"
      [initialSelectedOption]="passenger.birthDay"
      (selectAction)="passenger.birthDay = $event; setPassangerDay($event)"
      [formSubmitAttempt]="formSubmitAttempt"
      [options]="days"
      [formControl]="form.controls['birthDay']"></app-select>
  </div>
  <div class="col-md-8 col-sm-13 col-xs-24 col">
    <p class="label do-not-show">Month</p>
    <div class="default-select">
      <div class="input-wrapp">
        <app-select
          ngDefaultControl
          [caption]="'Month'"
          [initialSelectedOption]="passenger.birthMonth"
          (selectAction)="
            passenger.birthMonth = $event.value; setPassangerMonth($event)
          "
          [formSubmitAttempt]="formSubmitAttempt"
          [options]="months"
          [formControl]="form.controls['birthMonth']"></app-select>
      </div>
    </div>
  </div>
  <div class="col-md-8 col-sm-6 col-xs-24 col">
    <p class="label do-not-show">Year</p>
    <app-select
      ngDefaultControl
      [caption]="'Year'"
      [initialSelectedOption]="passenger.birthYear"
      (selectAction)="passenger.birthYear = $event; setPassangerYear($event)"
      [formSubmitAttempt]="formSubmitAttempt"
      [options]="years"
      [formControl]="form.controls['birthYear']"></app-select>
  </div>
</div>
