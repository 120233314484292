<footer class="site-wrapper">
  <div class="site-container site-container-home container">
    <img
      class="footer-logo"
      src="assets/img/backgrounds/rtf-icon.png"
      alt="Runtofly" />
    <div class="container-fluid link-container">
      <div class="row">
        <div class="col-md-8 col-sm-8 col">
          <p>About</p>
          <ul>
            <li>
              <a routerLink="/about" class="transition-all">About Runtofly</a>
            </li>
            <li>
              <a
                target="_blank"
                href="/last-minute-flights"
                class="transition-all"
                >Popular Destinations</a
              >
            </li>
            <li>
              <a target="_blank" href="/blog" class="transition-all"
                >Our Blog</a
              >
            </li>
          </ul>
        </div>
        <div class="col-md-8 col-sm-8 col">
          <p>Support</p>
          <ul>
            <li>
              <a routerLink="/help" class="transition-all">Help</a>
            </li>
            <li>
              <a routerLink="/contact" class="transition-all">Contact Us</a>
            </li>
          </ul>
        </div>
        <div class="col-md-8 col-sm-8 col">
          <p>Legal</p>
          <ul>
            <li>
              <a routerLink="terms" class="transition-all">
                Terms and Conditions</a
              >
            </li>
            <li>
              <a routerLink="/privacy" class="transition-all">Privacy Policy</a>
            </li>
            <li>
              <a routerLink="/cookie" class="transition-all">Cookie Policy</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="social-links">
        <a
          href="https://www.instagram.com/runtofly/"
          target="_blank"
          class="left inst">
          <img src="assets/img/backgrounds/insta1.png" alt="Instagram" />
        </a>
        <a
          href="https://www.facebook.com/runtofly/"
          target="_blank"
          class="left fb">
          <img src="assets/img/backgrounds/fb-logo1.png" alt="Facebook" />
        </a>
        <div class="clear"></div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-18 col">
        <!-- <p class="copyright">Registered Address: Ground Floor Rear, 235 Eversholt Street, NW1 1BE, London (UK)</p> -->
        <p class="copyright">
          ©
          <span class="mob-remove-2">{{
            currentDate | amDateFormat: 'YYYY'
          }}</span>
          RUN TO FLY LTD. All rights reserved.
        </p>
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-6 col">
        <img
          class="iata-logo"
          src="assets/img/backgrounds/iata-logo.png"
          alt="International Air Transport Association" />
      </div>
    </div>
  </div>
</footer>
