import {
  Component,
  Input,
  Output,
  OnInit,
  EventEmitter,
  OnChanges,
  OnDestroy,
  ViewChild
} from '@angular/core';
import { Flight, FlightFilter, toggle } from 'app/shared';
import { HttpClient } from '@angular/common/http';
import { compareStops } from '../../shared/utils';

import { FlightManagerService } from 'app/services/flight-manager/flight-manager.service';
import { FiltersService } from 'app/services/filters/filters.service';
import { Options } from '@angular-slider/ngx-slider';
import { Subscription } from 'rxjs';
import { FilterDropdownComponent } from './filter-dropdown/filter-dropdown.component';
import * as moment from 'moment';

export class FilterItem {
  text: string;
  secondText: string;
  isChecked: boolean;
  count: number;
  filterBy: any;
  topDeal?: {
    count: number;
    isChecked: boolean;
  }
  imageUrl?: string;

  constructor(
    text: string,
    isChecked: boolean,
    filterBy: any,
    count?: number,
    secondText?: string,
    topDeal?: {
      count: number;
      isChecked: boolean;
    },
    imageUrl?: string
  ) {
    this.text = text;
    this.isChecked = isChecked;
    this.filterBy = filterBy;
    this.count = count;
    this.secondText = secondText;
    this.topDeal = topDeal;
    this.imageUrl = imageUrl;
  }

  get value() {
    return this.text;
  }

  get number() {
    return this.count;
  }
}

@Component({
  selector: 'app-flights-filter',
  templateUrl: './flights-filter.component.html',
  styleUrls: ['./flights-filter.component.css'],
  animations: [toggle]
})
export class FlightsFilterComponent implements OnInit, OnChanges, OnDestroy {
  private _flights: Array<Flight>;

  @Input() set flights(value) {
    this._flights = value;
    this.setupFilterValues();
  }

  get flights() {
    return this._flights;
  }

  @Input() notFilteredFlights: Array<Flight>;
  @Input() desktop: boolean = false;
  @Input() labels: { name: string, imageUrl: string }[] = [];

  @Output() optionChecked: EventEmitter<FlightFilter> = new EventEmitter();
  @Output() applyOutput: EventEmitter<FlightFilter> = new EventEmitter();
  @Output() resetOutput: EventEmitter<FlightFilter> = new EventEmitter();
  @Output() closeOutput: EventEmitter<FlightFilter> = new EventEmitter();

  @Input() mobile: boolean = false;

  @ViewChild('stopsRef') stopsElement: FilterDropdownComponent;
  @ViewChild('airlineRef') airlineElement: FilterDropdownComponent;
  @ViewChild('priceRef') priceElement: FilterDropdownComponent;
  @ViewChild('timeLeftRef') timeLeftElement: FilterDropdownComponent;
  @ViewChild('destinationRef') destinationElement: FilterDropdownComponent;
  @ViewChild('departureTimeRef') departureTimeElement: FilterDropdownComponent;
  @ViewChild('destinationTypeRef') destinationTypeElement: FilterDropdownComponent;

  public previousFilters: FlightFilter = new FlightFilter();

  filtersConfig: any;

  departureTimeItems: FilterItem[] = [];
  destinationTypeItems: FilterItem[] = [];
  destinationItems: FilterItem[] = [];
  stopItems: FilterItem[] = [];
  airlineItems: FilterItem[] = [];
  hoursItems: FilterItem[] = [];

  optionsForPrice: Options = {};
  optionsForTime: Options = {};

  searchDestination: string = '';
  searchAirline: string = '';
  isActive: boolean;
  allCollapsed: boolean = true;

  typeOfAppliedFilter: string;

  setUpFilters$: Subscription;

  topDealsCount: number = 0;

  constructor(
    private http: HttpClient,
    public flightManagerService: FlightManagerService,
    public filtersService: FiltersService
  ) {}

  _updateFilterPreviousState() {
    this.previousFilters.departureTime =
      this.filtersService.filters.departureTime.slice();
    this.previousFilters.destinationType =
      this.filtersService.filters.destinationType.slice();
    this.previousFilters.destinations =
      this.filtersService.filters.destinations.slice();
    this.previousFilters.airlines =
      this.filtersService.filters.airlines.slice();
    this.previousFilters.stops = this.filtersService.filters.stops.slice();
    this.previousFilters.price = this.filtersService.filters.price.slice();
    this.previousFilters.topDealsChecked = this.filtersService.topDealsChecked;
    this.previousFilters.timeLeft =
      this.filtersService.filters.timeLeft.slice();
  }

  isApplyButtonActive(filter: string) {
    this.isActive = false;
    this.previousFilters[filter].map(item => {
      this.isActive = this.filtersService.filters[filter].indexOf(item) == -1;
    });
    return (
      this.isActive ||
      this.previousFilters[filter].length !=
        this.filtersService.filters[filter].length
    );
  }

  _calcCounts(arr) {
    const obj = {};
    for (let i = 0; i < arr.length; i++) {
      if (obj[arr[i]]) {
        obj[arr[i]]++;
      } else {
        obj[arr[i]] = 1;
      }
    }
    return obj;
  }

  _updateCount(
    items: FilterItem[],
    filterByValue: any,
    count: number,
    text?: string,
    secondText?: string,
    imageUrl?: string
  ) {
    let targetItem = items.find(item => {
      return item.filterBy === filterByValue;
    });

    if (!targetItem) {
      targetItem = new FilterItem(
        text,
        false,
        filterByValue,
        count,
        secondText,
        undefined,
        imageUrl
      );
      return items.push(targetItem);
    }
    targetItem.count = count;
  }

  ngOnInit() {
    this.http.get('./assets/config/filters.json').subscribe(
      (res: any) => {
        this.filtersConfig = res;
        this.setupFilterValues();
      },
      err => {
        console.log(err);
      }
    );

    this.setUpFilters$ = this.filtersService.setUpFilters.subscribe(() => {
      this.reset();
    });
  }

  setupFilterValues() {
    if (!this.filtersConfig) return;
    if (!this.typeOfAppliedFilter) {
      this.setUpDestination();
      this.setUpStops();
      this.setUpdepartureTime();
      this.setUpDestinationType();
      this.setUpAirline();
      this.setUpTopDeals();
      this.setUpPriceForDesktop();
      this.setUpHoursForDesktop();
    }
  }

  filterBy(by: string, value: number | string) {
    const index = this.filtersService.filters[by].indexOf(value);
    if (index > -1) {
      // if the item exists already then we need to remove it from the list
      this.filtersService.filters[by].splice(index, 1);
    } else {
      // if the item doesn't exist then we need to add it in the list
      this.filtersService.filters[by].push(value);
    }
    return this.isApplyButtonActive(by);
  }

  setUpDestination() {
    // this part is for mobile
    // we don't want to update section which was clicked the last
    if (
      !this.desktop &&
      this.filtersService.typeOfAppliedFilterMobile === 'Destination' &&
      this.filtersService.filters.destinations.length > 0
    ) {
      this.destinationItems = [...this.filtersService.destinationItems];
      return;
    } else if (
      !this.desktop &&
      this.filtersService.typeOfAppliedFilterMobile === 'Destination' &&
      this.filtersService.filters.destinations.length === 0
    ) {
      this.filtersService.typeOfAppliedFilterMobile = '';
      this.filtersService.destinationItems = [];
    }

    this.resetCounts(this.filtersService.destinationItems);

    const destinations = {};
    this.flights.map(flight => {
      const lastIndex = flight.outboundTickets.length - 1;
      const worldArea = flight.outboundTickets[lastIndex].arrival.worldArea;
      if (!worldArea) return {};

      const worldAreaKey = worldArea.toString();
      const worldAreaObj = destinations[worldAreaKey];
      if (worldAreaObj) {
        worldAreaObj.count++;
        worldAreaObj.minPrice = Math.min(flight.price, worldAreaObj.minPrice);
        worldAreaObj.name = worldArea;
      } else {
        destinations[worldAreaKey] = {
          count: 1,
          minPrice: flight.price,
          name: worldArea
        };
      }
    });

    Object.keys(destinations)
      .sort(function (a, b) {
        return destinations[b].count - destinations[a].count;
      })
      .map(destination => {
        // setup min prices from scretch but don't delete whole array
        this.filtersService.destinationItems.forEach(item => {
          if (item.filterBy === destination) {
            item.secondText = destinations[destination].minPrice;
          }
        });
        this._updateCount(
          this.filtersService.destinationItems,
          destination,
          destinations[destination].count,
          destinations[destination].name,
          destinations[destination].minPrice
        );
      });

    this.updateExistingItems(this.filtersService.destinationItems);
    this.destinationItems = [];
    this.destinationItems = [...this.filtersService.destinationItems];
  }

  setUpStops() {
    if (
      !this.desktop &&
      this.filtersService.typeOfAppliedFilterMobile === 'Stops' &&
      this.filtersService.filters.stops.length > 0
    ) {
      this.stopItems = [...this.filtersService.stopItems];
      return;
    } else if (
      !this.desktop &&
      this.filtersService.typeOfAppliedFilterMobile === 'Stops' &&
      this.filtersService.filters.stops.length === 0
    ) {
      this.filtersService.typeOfAppliedFilterMobile = '';
      this.filtersService.stopItems = [];
    }

    this.resetCounts(this.filtersService.stopItems);

    const countOfStops = {};
    this.flights.map(flight => {
      let index: string | number = flight.outboundTickets.length - 1;
      if (index == 0) index = 'Direct';

      if (countOfStops[index]) {
        countOfStops[index].count++;
        countOfStops[index].minPrice = Math.min(
          countOfStops[index].minPrice,
          flight.price
        );
      } else countOfStops[index] = { count: 1, minPrice: flight.price };
    });

    Object.keys(countOfStops)
      .sort(compareStops)
      .map(stop => {
        let text = stop;
        if (text != 'Direct') text += ' Stop';

        // setup min prices from scretch but don't delete whole array
        this.filtersService.stopItems.forEach(item => {
          if (item.filterBy === stop) {
            item.secondText = countOfStops[stop].minPrice;
          }
        });

        this._updateCount(
          this.filtersService.stopItems,
          stop,
          countOfStops[stop].count,
          text,
          countOfStops[stop].minPrice
        );
      });
    this.updateExistingItems(this.filtersService.stopItems);

    this.stopItems = [];
    this.stopItems = [...this.filtersService.stopItems];
  }

  setUpAirline() {
    if (
      !this.desktop &&
      this.filtersService.typeOfAppliedFilterMobile === 'Airline' &&
      this.filtersService.filters.airlines.length > 0
    ) {
      this.airlineItems = [...this.filtersService.airlineItems];
      return;
    } else if (
      !this.desktop &&
      this.filtersService.typeOfAppliedFilterMobile === 'Airline' &&
      this.filtersService.filters.airlines.length === 0
    ) {
      this.filtersService.typeOfAppliedFilterMobile = '';
      this.filtersService.airlineItems = [];
    }

    this.resetCounts(this.filtersService.airlineItems);
    const airlines = this.flights.map(flight => {
      if (
        flight.outboundTickets[0].airline.name !=
        flight.outboundTickets[flight.outboundTickets.length - 1].airline.name
      ) {
        return 'Multiple airlines';
      }
      return flight.outboundTickets[0].airline.name || 'Undisclosed';
    });

    const countOfAirlines = this._calcCounts(airlines);
    
    Object.keys(countOfAirlines)
      .map(item => {
        const filterBy = item === 'Undisclosed' ? null : item;
        this._updateCount(
          this.filtersService.airlineItems,
          filterBy,
          countOfAirlines[item],
          item
        );
      });

    this.updateExistingItems(this.filtersService.airlineItems);
    this.filtersService.airlineItems.sort((a, b) => {
      const nameA = a.filterBy.toUpperCase();
      const nameB = b.filterBy.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    })

    this.airlineItems = [];
    this.airlineItems = [...this.filtersService.airlineItems];
  }

  setUpdepartureTime() {
    // Handle the mobile filter case first
    if (!this.desktop && this.filtersService.typeOfAppliedFilterMobile === 'Departure day') {
      if (this.filtersService.filters.departureTime.length > 0) {
        this.departureTimeItems = [...this.filtersService.departureTimeItems];
        return;
      } else {
        this.filtersService.typeOfAppliedFilterMobile = '';
        this.filtersService.departureTimeItems = [];
      }
    }
  
    this.resetCounts(this.filtersService.departureTimeItems);
  
    const localDate = moment();
    const localDay = localDate.day();
  
    const departureTimes = this.flights.map(flight => {
      const departureDay = flight.outboundTickets[0].departure.date.day();
      
      switch (departureDay - localDay) {
        case 0:
          return 'Today';
        case 1:
        case -6:
          return 'Tomorrow';
        default:
          return 'The day after tomorrow';
      }
    });
  
    // Sort to always have 'Today', 'Tomorrow', and then 'The day after tomorrow'
    const countOfdepartureTimes = this._calcCounts(departureTimes);
  
    Object.keys(countOfdepartureTimes).forEach(item => {
      this._updateCount(this.filtersService.departureTimeItems, item, countOfdepartureTimes[item], item);
    });
  
    this.updateExistingItems(this.filtersService.departureTimeItems);
    
    // Update departureTimeItems
    this.departureTimeItems = [...this.filtersService.departureTimeItems.sort((a, b) => {
      const priority = { 'Today': 0, 'Tomorrow': 1, 'The day after tomorrow': 2 };
      return priority[a.text] - priority[b.text];  // Ensures correct order
    })];
  }

  setUpDestinationType() {
    if (
      !this.desktop &&
      this.filtersService.typeOfAppliedFilterMobile === 'Destination Type' &&
      this.filtersService.filters.destinationType.length > 0
    ) {
      this.destinationTypeItems = [...this.filtersService.destinationTypeItems];
      return;
    } else if (
      !this.desktop &&
      this.filtersService.typeOfAppliedFilterMobile === 'Destination Type' &&
      this.filtersService.filters.destinationType.length === 0
    ) {
      this.filtersService.typeOfAppliedFilterMobile = '';
      this.filtersService.destinationTypeItems = [];
    }

    this.resetCounts(this.filtersService.destinationTypeItems);

    const destinationTypeCounts = this.labels.reduce((acc, type) => {
      acc[type.name] = {
        count: 0,
        imageUrl: type.imageUrl
      };
      return acc;
    }, {});
    
    this.flights.forEach(flight => {
      flight.destinationTypes?.forEach(type => {
        if (destinationTypeCounts.hasOwnProperty(type)) {
          destinationTypeCounts[type].count++;
        }
      });
    });
    Object.keys(destinationTypeCounts)
      .map(item => {
        this._updateCount(
          this.filtersService.destinationTypeItems,
          item,
          destinationTypeCounts[item].count,
          item,
          '',
          destinationTypeCounts[item].imageUrl
        );
      });
    this.updateExistingItems(this.filtersService.destinationTypeItems);
    this.filtersService.destinationTypeItems.sort((a, b) => {
      const nameA = a.filterBy.toUpperCase();
      const nameB = b.filterBy.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    })
    this.destinationTypeItems = [];
    this.destinationTypeItems = [...this.filtersService.destinationTypeItems];
  }
  
  setUpPrice() {
    this.filtersConfig.price.map((price, index) => {
      let count = 0;
      this.notFilteredFlights.map(flight => {
        if (flight.price >= price.min && Math.ceil(flight.price) <= price.max) {
          count++;
        }
      });
      let text = `${price.min}-${price.max}`;
      const filterByValue = text;
      if (index == this.filtersConfig.price.length - 1) text = `${price.min}+`;

      this._updateCount(
        this.filtersService.priceItems,
        filterByValue,
        count,
        text
      );
    });
  }

  setUpPriceForDesktop() {
    const topDealsChecked = this.filtersService.priceItems?.[0]?.topDeal.isChecked || false;
    if (this.filtersService.filters.price.length > 0) {
      const result = this.getMinMaxPriceFromUnfilteredFlights();
      const text = result.min + '-' + result.max;

      this.setUpPriceOptions(result.min, result.max);
      this.filtersService.priceItems.push(
        new FilterItem(text, false, text, undefined, undefined, {
          count: this.topDealsCount,
          isChecked: topDealsChecked
        })
      );
      return;
    } else if (
      this.filtersService.filters.price.length === 0 &&
      this.filtersService.typeOfAppliedFilterMobile === 'Price'
    ) {
      this.filtersService.typeOfAppliedFilterMobile = '';
      this.filtersService.priceItems = [
        new FilterItem(
          '',
          false,
          '',
          undefined,
          undefined,
          {
            count: this.topDealsCount,
            isChecked: topDealsChecked
          }
        )
      ];
    }

    let max = 0;
    let min = Math.ceil(this.flights[0].price);
    this.flights.forEach(flight => {
      if (flight.price > max) {
        max = Math.ceil(flight.price);
      }
      if (flight.price < min) {
        min = Math.ceil(flight.price);
      }
    });
    const text = min + '-' + max;
    this.filtersService.priceLowValue = min;
    this.filtersService.priceHighValue = max;

    //if prices are same no sense to show price slider
    if (min != max) {
      this.setUpPriceOptions(min, max);
    } else {
      this.optionsForPrice = null;
    }

    this.filtersService.priceItems.push(new FilterItem(text, false, text, undefined, undefined, {
      count: this.topDealsCount,
      isChecked: this.filtersService.priceItems?.[0]?.topDeal.isChecked || false
    }));

    
  }

  setUpPriceOptions(min: number, max: number) {
    this.optionsForPrice = {
      floor: min,
      ceil: max,
      step: 1,
      minRange: 0,
      translate: (value: number): string => {
        return '£' + value;
      }
    };
  }
  setUpTopDeals() {
    this.topDealsCount = this.flights.filter(flight => flight.isTopDeal).length;
    const existingItem = this.filtersService.priceItems[0];

    if (!existingItem) {
      this.filtersService.priceItems.push(
        new FilterItem(
          '',
          false,
          '',
          undefined,
          undefined,
          {
            count: this.topDealsCount,
            isChecked: false
          }
        )
      );
    } else {
      existingItem.topDeal = {
        count: this.topDealsCount,
        isChecked: existingItem.topDeal.isChecked
      };
    }
  }

  getMinMaxPriceFromUnfilteredFlights() {
    let max = 0;
    let min = Math.ceil(this.notFilteredFlights[0].price);
    this.notFilteredFlights.forEach(flight => {
      if (flight.price > max) {
        max = Math.ceil(flight.price);
      }
      if (flight.price < min) {
        min = Math.ceil(flight.price);
      }
    });

    return { min: min, max: max };
  }

  getMinMaxTimeFromUnfilteredFlights() {
    let min =
      this.notFilteredFlights[0].timeLeft.days() * 24 +
      this.notFilteredFlights[0].timeLeft.hours();
    let max =
      this.notFilteredFlights[0].timeLeft.days() * 24 +
      this.notFilteredFlights[0].timeLeft.hours();

    this.notFilteredFlights.forEach(flight => {
      const flightHours = flight.timeLeft.days() * 24 + flight.timeLeft.hours();
      if (flightHours < min) {
        min = flightHours;
      }
      if (flightHours > max) {
        max = flightHours;
      }
    });

    min += 1;
    max += 1;

    return { min: min, max: max };
  }

  setUpHours() {
    this.filtersConfig.hoursLeft.map(hour => {
      let count = 0;
      this.notFilteredFlights.map(flight => {
        if (
          flight.timeLeft.asHours() >= hour.min &&
          flight.timeLeft.asHours() < hour.max
        ) {
          count++;
        }
      });

      const text = `${hour.min}-${hour.max}`;
      this._updateCount(
        this.filtersService.hoursItems,
        text,
        count,
        `${text} hrs`
      );
    });
  }

  setUpHoursForDesktop() {
    if (this.filtersService.filters.timeLeft.length > 0) {
      const result = this.getMinMaxTimeFromUnfilteredFlights();
      const text = result.min + '-' + result.max;

      this.setUpHoursOptions(result.min, result.max);
      this.filtersService.hoursItems.push(new FilterItem(text, false, text));
      return;
    } else if (
      this.filtersService.filters.timeLeft.length === 0 &&
      this.filtersService.typeOfAppliedFilterMobile === 'Time left'
    ) {
      this.filtersService.typeOfAppliedFilterMobile = '';
      this.filtersService.hoursItems = [];
    }

    let min =
      this.flights[0].timeLeft.days() * 24 + this.flights[0].timeLeft.hours();
    let max =
      this.flights[0].timeLeft.days() * 24 + this.flights[0].timeLeft.hours();

    this.flights.forEach(flight => {
      const flightHours = flight.timeLeft.days() * 24 + flight.timeLeft.hours();
      if (flightHours < min) {
        min = flightHours;
      }
      if (flightHours > max) {
        max = flightHours;
      }
    });

    const text = min + '-' + max;

    this.filtersService.timeLowValue = min;
    this.filtersService.timeHighValue = max;

    //if times are same no sense to show time slider
    if (min != max) {
      this.setUpHoursOptions(min, max);
    } else {
      this.optionsForTime = null;
    }

    this.filtersService.hoursItems.push(new FilterItem(text, false, text));
  }

  setUpHoursOptions(min, max) {
    this.optionsForTime = {
      floor: min,
      ceil: max,
      step: 1,
      minRange: 0,
      translate: (value: number): string => {
        return value + ' hrs';
      }
    };
  }

  resetCounts(items: Array<FilterItem>) {
    items.forEach(item => {
      if (item.isChecked === false) {
        item.count = 0;
      }
    });
  }

  updateExistingItems(items: Array<FilterItem>) {
    for (let i = 0; i < items.length; i++) {
      if (items[i].count === 0) {
        items.splice(i, 1);
        i = i - 1;
      }
    }
  }

  selectAll(...args) {
    const firstItem = args[0].firstItem;
    const secondItem = args[0].secondItem;
    this[firstItem].map(item => {
      item.isChecked = true;
    });
    this.filtersService.filters[secondItem] = [];
  }

  close() {
    this.closeOutput.emit();
  }

  reset() {
    setTimeout(() => {
      this.setUpDestination();
      this.setUpStops();
      this.setUpdepartureTime();
      this.setUpDestinationType();
      this.setUpAirline();
      this.setUpTopDeals();
      this.setUpPriceForDesktop();
      this.setUpHoursForDesktop();
    });

    this.countNumberOfActiveFilters();
    this.resetOutput.emit(this.filtersService.filters);
  }

  clearAll() {
    this.filtersService.resetFilters();
    this.reset();
  }

  applyFilter() {
    this._updateFilterPreviousState();

    if (this.desktop) {
      this.countNumberOfActiveFilters();
    }

    if (!this.filtersService.filtersHasLength()) {
      this.reset();
    }
    this.applyOutput.emit(this.filtersService.filters);
  }

  optionIsClicked() {
    this.countNumberOfActiveFilters();
    this._updateFilterPreviousState();
    if (!this.filtersService.filtersHasLength()) {
      this.filtersService.typeOfAppliedFilterMobile = '';
      this.reset();
    }
    this.optionChecked.emit(this.filtersService.filters);
  }

  updateCountAfterFilterApplied(type: string) {
    this.typeOfAppliedFilter = type;
    this.filtersService.typeOfAppliedFilterMobile = type;
  }

  checkIfFiltersAreEmpty(items): boolean {
    let isChecked = true;
    items.forEach(item => {
      if (item.isChecked) {
        isChecked = false;
      }
    });

    return isChecked;
  }

  setUpFilterValuesByType(typeOfAppliedFilter) {
    if (typeOfAppliedFilter === 'Stops') {
      if (this.checkIfFiltersAreEmpty(this.filtersService.stopItems)) {
        this.setUpStops();
      }
      this.setUpdepartureTime();
      this.setUpDestinationType();
      this.setUpAirline();
      this.setUpDestination();
      this.setUpTopDeals();
      this.setUpPriceForDesktop();
      this.setUpHoursForDesktop();
    } else if (typeOfAppliedFilter === 'Departure day') {
      if (this.checkIfFiltersAreEmpty(this.filtersService.departureTimeItems)) {
        this.setUpdepartureTime();
      }
      this.setUpDestinationType();
      this.setUpAirline();
      this.setUpStops();
      this.setUpDestination();
      this.setUpTopDeals();
      this.setUpPriceForDesktop();
      this.setUpHoursForDesktop();
    } else if (typeOfAppliedFilter === 'Destination type') {
      this.setUpdepartureTime();
      this.setUpAirline();
      this.setUpDestination();
      this.setUpStops();
      this.setUpTopDeals();
      this.setUpPriceForDesktop();
      this.setUpHoursForDesktop();
    } else if (typeOfAppliedFilter === 'Price') {
      this.setUpdepartureTime();
      this.setUpDestinationType();
      this.setUpAirline();
      this.setUpDestination();
      this.setUpStops();
      this.setUpTopDeals();
      this.setUpHoursForDesktop();
    } else if (typeOfAppliedFilter === 'TopDeals') {
      this.setUpdepartureTime();
      this.setUpDestinationType();
      this.setUpAirline();
      this.setUpDestination();
      this.setUpStops();
      this.setUpPriceForDesktop();
      this.setUpHoursForDesktop();
    } else if (typeOfAppliedFilter === 'Destination') {
      if (this.checkIfFiltersAreEmpty(this.filtersService.destinationItems)) {
        this.setUpDestination();
      }
      this.setUpdepartureTime();
      this.setUpDestinationType();
      this.setUpAirline();
      this.setUpStops();
      this.setUpTopDeals();
      this.setUpPriceForDesktop();
      this.setUpHoursForDesktop();
    } else if (typeOfAppliedFilter === 'Time Left') {
      this.setUpdepartureTime();
      this.setUpDestinationType();
      this.setUpAirline();
      this.setUpStops();
      this.setUpDestination();
      this.setUpTopDeals();
      this.setUpPriceForDesktop();
    } else if (typeOfAppliedFilter === 'Airline') {
      if (this.checkIfFiltersAreEmpty(this.filtersService.airlineItems)) {
        this.setUpAirline();
      }
      this.setUpdepartureTime();
      this.setUpDestinationType();
      this.setUpStops();
      this.setUpDestination();
      this.setUpTopDeals();
      this.setUpPriceForDesktop();
      this.setUpHoursForDesktop();
    }
  }

  countNumberOfActiveFilters() {
    this.filtersService.countOfFilters =
      this.filtersService.filters.destinations.length +
      this.filtersService.filters.departureTime.length +
      this.filtersService.filters.destinationType.length +
      this.filtersService.filters.airlines.length +
      this.filtersService.filters.stops.length +
      this.filtersService.filters.price.length +
      +this.filtersService.filters.topDealsChecked +
      this.filtersService.filters.timeLeft.length;
  }

  ngOnChanges() {
    if (this._flights && this.typeOfAppliedFilter) {
      this.setUpFilterValuesByType(this.typeOfAppliedFilter);
    }
  }

  ngOnDestroy() {
    if (this.setUpFilters$) {
      this.setUpFilters$.unsubscribe();
    }
  }

  expendAll() {
    this.allCollapsed = false;
    this.stopsElement.filterCollapse['Stops'] = true;
    this.airlineElement.filterCollapse['Airline'] = true;
    this.priceElement.filterCollapse['Price'] = true;
    this.timeLeftElement.filterCollapse['Time left'] = true;
    this.destinationElement.filterCollapse['Destination'] = true;
    this.departureTimeElement.filterCollapse['Departure day'] = true;
    this.destinationTypeElement.filterCollapse['Destination type'] = true;
    this.filtersService.updateHeightOfFilterContainer.next(true);
  }

  collapseAll() {
    this.allCollapsed = true;
    this.stopsElement.filterCollapse['Stops'] = false;
    this.airlineElement.filterCollapse['Airline'] = false;
    this.priceElement.filterCollapse['Price'] = false;
    this.timeLeftElement.filterCollapse['Time left'] = false;
    this.destinationElement.filterCollapse['Destination'] = false;
    this.departureTimeElement.filterCollapse['Departure day'] = false;
    this.destinationTypeElement.filterCollapse['Destination type'] = false;
    this.filtersService.updateHeightOfFilterContainer.next(true);
  }
}
