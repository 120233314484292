import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-price',
  templateUrl: './price.component.html',
  styleUrls: ['./price.component.css']
})
export class PriceComponent {
  @Input()
  set value(value: number) {
    this._value = value;
    if (this.value === 0 || this.value < 0 || this.value > 0) {
      if (this.value < 0) {
        this.value *= -1;
        this.isNegative = true;
      }
      const toFixedTwo = this.value.toFixed(2);
      this.integral = toFixedTwo.split('.')[0];
      this.fractional = toFixedTwo.split('.')[1];
    }
  }

  get value(): number {
    return this._value;
  }

  @Input() color_of_fraction = '';

  _value: number;
  integral: string;
  fractional: string;
  isNegative: boolean;

  constructor() {}
}
