<div class="content">
  <span class="title">
    <span class="title-cities"
      >{{ flight.outboundTickets[0].departure.city }}
      <img
        *ngIf="!isOneWay"
        src="assets/img/icons/left-and-right-arrows.svg"
        alt="img"
        class="right-arrow" />
      <img
        *ngIf="isOneWay"
        src="assets/img/icons/right-arrow.svg"
        alt="img"
        class="right-arrow" />
      {{ flight.outboundTickets[flight.outboundTickets.length - 1].arrival.city
      }}<span class="flight-type">, {{ isOneWay ? 'one way' : 'return' }}</span>
    </span>
    <br />
    <span
      class="color-1 right loading"
      *ngIf="
        (passengerPage || paymentPage) && priceBreakdownDetails === undefined
      "
      >Loading ...</span
    >
    <span
      class="passenger-details"
      *ngIf="
        (passengerPage || paymentPage) && priceBreakdownDetails !== undefined
      "
      >{{ priceBreakdownDetails.totalPaxCount }}x Ticket</span
    >
    <span
      class="passenger-details"
      *ngIf="
        (passengerPage || paymentPage) && priceBreakdownDetails !== undefined
      ">
      <span
        class="passenger-details"
        *ngIf="priceBreakdownDetails.totalPaxCount > 1"
        >s</span
      >
      <span class="pax-type">
        (
        <span
          class="passenger-details pax-type"
          *ngIf="priceBreakdownDetails.adult.count === 1"
          >{{ priceBreakdownDetails.adult.count }} Adult</span
        >
        <span
          class="passenger-details pax-type"
          *ngIf="priceBreakdownDetails.adult.count > 1"
          >{{ priceBreakdownDetails.adult.count }} Adults</span
        >
        <span
          class="passenger-details pax-type"
          *ngIf="
            priceBreakdownDetails.child.count &&
            priceBreakdownDetails.child.count === 1
          "
          >, {{ priceBreakdownDetails.child.count }} Child</span
        >
        <span
          class="passenger-details pax-type"
          *ngIf="
            priceBreakdownDetails.child.count &&
            priceBreakdownDetails.child.count > 1
          "
          >, {{ priceBreakdownDetails.child.count }} Children</span
        >
        <span
          class="passenger-details pax-type"
          *ngIf="
            priceBreakdownDetails.infant.count &&
            priceBreakdownDetails.infant.count === 1
          "
          >, {{ priceBreakdownDetails.infant.count }} Infant</span
        >
        <span
          class="passenger-details pax-type"
          *ngIf="
            priceBreakdownDetails.infant.count &&
            priceBreakdownDetails.infant.count > 1
          "
          >, {{ priceBreakdownDetails.infant.count }} Infants</span
        >
        )</span
      >
    </span>
  </span>
  <ul class="basket">
    <!-- entry -->
    <li id="current" class="entry">
      <div class="row">
        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3 col">
          <img
            alt="img"
            class="icon basket-o"
            src="assets/img/icons/basket-o.svg" />
        </div>
        <div class="col-lg-21 col-md-21 col-sm-21 col-xs-21 col">
          <ul class="details">
            <li>
              <span class="bold">OUTBOUND:</span>
              <span class="light">
                {{
                  flight.outboundTickets[0].departure.date
                    | amDateFormat: 'ddd DD MMM YYYY'
                }}
              </span>
            </li>
            <li>
              <span class="color-1">
                {{ flight.outboundTickets[0].departure.code }}
                {{
                  flight.outboundTickets[0].departure.date
                    | amDateFormat: 'HH:mm'
                }}</span
              >
              <!-- <i class="icon-rightarrow color-2"></i> -->
              <img
                src="assets/img/icons/right-arrow.svg"
                alt="img"
                class="right-arrow" />
              <span class="color-1">
                {{
                  flight.outboundTickets[flight.outboundTickets.length - 1]
                    .arrival.code
                }}
                {{
                  flight.outboundTickets[flight.outboundTickets.length - 1]
                    .arrival.date | amDateFormat: 'HH:mm'
                }}</span
              >
              <span class="nextDay" *ngIf="outboundWillArriveNextDay">
                +{{ dayOfOutboundArrival }} day<span
                  *ngIf="dayOfOutboundArrival > 1"
                  >s</span
                ></span
              >
            </li>

            <li>
              <span *ngIf="flight.outboundTickets.length === 1">
                {{
                  flight.outboundTickets[0].duration | momentDiff: 'shortened'
                }}, Direct</span
              >
              <span *ngIf="flight.outboundTickets.length > 1">
                {{ totalDuration | momentDiff: 'shortened' }},
                {{ flight.outboundTickets.length - 1 + ' stop' }}</span
              >
              <span class="color-2">
                {{
                  flight.outboundTickets.length > 1
                    ? '(in ' + flight.outboundTickets[0].arrival.code + ')'
                    : ''
                }}
              </span>
            </li>
            <li>
              <span class="color-2 light airline-name"
                >{{
                  flight.outboundTickets[0].airline.name
                    ? flight.outboundTickets[0].airline.name
                    : 'Airline undisclosed'
                }},
                {{ flight.outboundTickets[0].type }}
              </span>
            </li>
          </ul>
        </div>
      </div>
    </li>

    <li *ngIf="!flight.returnTickets.length && !isOneWay" class="entry">
      <div class="row">
        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3 col">
          <img
            alt="img"
            class="icon basket-r"
            src="assets/img/icons/basket-r.svg" />
        </div>
        <div class="col-lg-21 col-md-21 col-sm-21 col-xs-21 col">
          <ul class="details">
            <li>
              <span class="bold">INBOUND:</span>
              <span class="light"> Not selected</span>
            </li>
          </ul>
        </div>
      </div>
    </li>

    <li
      *ngIf="flight.returnTickets && flight.returnTickets.length"
      id="current"
      class="entry">
      <div class="row">
        <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3 col">
          <img
            alt="img"
            class="icon basket-r"
            src="assets/img/icons/basket-r.svg" />
        </div>
        <div class="col-lg-21 col-md-21 col-sm-21 col-xs-21 col">
          <ul class="details">
            <li>
              <span class="bold">INBOUND:</span>
              <span class="light">
                {{
                  flight.returnTickets[0].departure.date
                    | amDateFormat: 'ddd DD MMM YYYY'
                }}
              </span>
            </li>
            <li>
              <span class="color-1">
                {{ flight.returnTickets[0].departure.code }}
                {{
                  flight.returnTickets[0].departure.date | amDateFormat: 'HH:mm'
                }}
              </span>
              <!-- <i class="icon-rightarrow color-2"></i> -->
              <img
                src="assets/img/icons/right-arrow.svg"
                alt="img"
                class="right-arrow" />
              <span class="color-1">
                {{
                  flight.returnTickets[flight.returnTickets.length - 1].arrival
                    .code
                }}
                {{
                  flight.returnTickets[flight.returnTickets.length - 1].arrival
                    .date | amDateFormat: 'HH:mm'
                }}
                <span class="nextDay" *ngIf="inboundWillArriveNextDay">
                  +{{ dayOfInboundArrival }} day<span
                    *ngIf="dayOfInboundArrival > 1"
                    >s</span
                  ></span
                >
              </span>
            </li>
            <li>
              <span *ngIf="flight.returnTickets.length === 1">
                {{
                  flight.returnTickets[0].duration | momentDiff: 'shortened'
                }}, Direct</span
              >
              <span *ngIf="flight.returnTickets.length > 1">
                {{ totalDurationOfReturnFlights | momentDiff: 'shortened' }},
                {{ flight.returnTickets.length - 1 + ' stop' }}</span
              >
              <span class="color-2">
                {{
                  flight.returnTickets.length > 1
                    ? '(in ' + flight.returnTickets[0].arrival.code + ')'
                    : ''
                }}
              </span>
            </li>
            <li>
              <span class="color-2 light airline-name"
                >{{
                  flight.returnTickets[0].airline.name
                    ? flight.returnTickets[0].airline.name
                    : 'Airline undisclosed'
                }},
                {{ flight.returnTickets[0].type }}
              </span>
            </li>
          </ul>
        </div>
      </div>
    </li>

    <!-- nav buttons -->
    <div class="nav-buttons" *ngIf="!flight.returnTickets.length">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col start">
          <span class="details-btn" (click)="showFlightDetails()">{{
            isOneWay ? 'Flight Details' : 'Outbound Details'
          }}</span>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col end">
          <span
            *ngIf="conditionsVisible && isOneWay"
            class="details-btn"
            (click)="showFareRules($event)"
            >Ticket Conditions</span
          >
          <span *ngIf="!isOneWay" class="details-btn" routerLink="/"
            >Change Outbound</span
          >
        </div>
      </div>
    </div>
  </ul>

  <!-- nav buttons -->
  <div
    class="nav-buttons"
    *ngIf="flight.returnTickets && flight.returnTickets.length">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col start">
        <span class="details-btn" (click)="showFlightDetails()"
          >Flight Details</span
        >
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col end">
        <span
          *ngIf="conditionsVisible"
          class="details-btn"
          (click)="showFareRules($event)"
          >Ticket Conditions</span
        >
        <span *ngIf="!conditionsVisible" class="details-btn" routerLink="/"
          >Change flight</span
        >
      </div>
    </div>
  </div>

  <!-- passengers -->
  <div *ngIf="passengers !== undefined && paymentPage" class="passengers">
    <ul>
      <!-- entry -->
      <li *ngFor="let passenger of passengers">
        <span class="white"
          >{{ passenger.firstName }} {{ passenger.lastName }}</span
        >&nbsp;&nbsp;
        <span class="grey">{{ passenger.gender === 'M' ? 'M' : 'F' }}</span
        >&nbsp;&nbsp;
        <span class="white"
          >{{ passenger.birthDay }}.{{
            passenger.birthMonth < 10
              ? '0' + passenger.birthMonth
              : passenger.birthMonth
          }}.{{ passenger.birthYear }}</span
        >
        <span class="edit" (click)="navigateToPassangerPage()"
          ><img alt="img" src="assets/img/icons/edit-yellow.svg" />
        </span>
      </li>
    </ul>
  </div>

  <!-- costs -->
  <div
    *ngIf="
      flight &&
      ((flight.returnTickets && flight.returnTickets.length) || isOneWay)
    "
    class="costs">
    <!-- entry -->
    <div class="row">
      <div
        class="col-lg-16 col-md-16 col-sm-16 col-xs-16 col flight-cost"
        [ngClass]="{
          'return-page-price': paymentPage !== true && passengerPage !== true
        }">
        <p>
          Flight Cost
          <span *ngIf="!passengerPage && !paymentPage" class="per-person"
            >per person</span
          >
          <span
            *ngIf="passengerPage || paymentPage"
            id="show-breakdown"
            (click)="showBreakDownDetails()"
            class="color-1 transition-all price-breakdown"
            >Price Details</span
          >
        </p>
      </div>
      <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 col price">
        <span
          *ngIf="
            (paymentPage || passengerPage) &&
            priceBreakdownDetails === undefined
          "
          class="color-1 right loading">
          Loading ...
        </span>
        <p
          *ngIf="!paymentPage && !passengerPage"
          class="current price-return-page"
          [ngClass]="passengerPage || paymentPage ? 'font-of-price' : ''">
          <app-price [value]="flight.price"></app-price>
        </p>
        <p
          *ngIf="
            (paymentPage || passengerPage) &&
            priceBreakdownDetails !== undefined
          "
          class="current"
          [ngClass]="passengerPage || paymentPage ? 'font-of-price' : ''">
          <app-price [value]="priceBreakdownDetails.grandTotal"></app-price>
        </p>
      </div>
      <div
        class="average-price-container"
        *ngIf="
          (passengerPage || paymentPage) &&
          priceBreakdownDetails !== undefined &&
          priceBreakdownDetails.totalPaxCount > 1
        ">
        <span>
          Average p.p.
          <span class="average-price-number"
            ><app-price [value]="priceBreakdownDetails.avgPerPerson"></app-price
          ></span>
        </span>
      </div>
    </div>
    <div
      class="row"
      *ngIf="
        (paymentPage || passengerPage) && priceBreakdownDetails.discountCode
      "
      [ngClass]="{
        'has-avg':
          priceBreakdownDetails !== undefined &&
          priceBreakdownDetails.totalPaxCount > 1
      }">
      <div class="col-lg-16 col-md-16 col-sm-16 col-xs-16 col">
        <p class="discount-code">
          Discount Code
          <span class="color-1">{{ priceBreakdownDetails.discountCode }}</span>
        </p>
      </div>
      <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 col price">
        <span
          *ngIf="priceBreakdownDetails === undefined"
          class="color-1 right loading">
          Loading ...
        </span>
        <p
          *ngIf="priceBreakdownDetails !== undefined"
          class="current"
          [ngClass]="passengerPage || paymentPage ? 'font-of-price' : ''">
          <app-price [value]="priceBreakdownDetails.discountAmount"></app-price>
        </p>
      </div>
    </div>
    <div class="total" *ngIf="passengerPage || paymentPage">
      <div class="row">
        <div class="col-xs-12 col payable">
          <p>Total To Pay</p>
        </div>
        <div class="col-xs-12 col price">
          <span
            *ngIf="priceBreakdownDetails === undefined"
            class="color-1 right loading">
            Loading ...
          </span>
          <p *ngIf="priceBreakdownDetails !== undefined">
            <app-price [value]="priceBreakdownDetails.totalToPay"></app-price>
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12 col color-1 left">
          <span class="taxes-and-fees"> Including taxes and fees </span>
        </div>
      </div>
    </div>
  </div>
</div>
