<div
  class="flight-details-container"
  [ngClass]="{ 'no-margin': confirmation_page }">
  <div class="container-fluid">
    <div class="row">
      <div class="global-flight-details-header">
        <img
          *ngIf="type === 'outbound'"
          class="departure-plane"
          src="assets/img/icons/departure2.svg"
          alt="img" />
        <img
          *ngIf="type !== 'outbound'"
          class="return-plane"
          src="assets/img/icons/return2.svg"
          alt="img" />
        <h4>
          <span class="mob-remove-7">{{
            type === 'outbound' ? 'Outbound:' : 'Inbound:'
          }}</span>
          {{ tickets[0].departurePoint.code }}
          <!-- <i class="icon-rightarrow"></i> -->
          <span class="arrow-icon">
            <img
              src="assets/img/icons/right-arrow-dark.svg"
              alt="img"
              class="right-arrow" />
          </span>
          {{ tickets[tickets.length - 1].destinationPoint.code + ',' }}
          <span class="">{{
            tickets[0].departureDate | amDateFormat: 'ddd DD MMM'
          }}</span>
        </h4>
        <div class="time">
          <img src="assets/img/icons/timer-icon.svg" alt="img" />
          <span class="only-for-desktop"
            >{{ tickets.length > 1 ? 'Travel' : 'Flight' }} duration:</span
          >
          <span *ngIf="tickets.length === 1" class="stop">
            {{ tickets[0].duration | momentDiff: 'shortened' }}, Direct</span
          >
          <span *ngIf="tickets.length > 1" class="stop">
            {{ totalDuration | momentDiff: 'shortened' }},
            {{
              tickets.length - 1 + (tickets.length - 1 > 1 ? ' stops' : ' stop')
            }}</span
          >
        </div>
      </div>
      <div class="global-flight-details">
        <!-- flight -->
        <div class="flight top" *ngFor="let ticket of tickets; let i = index">
          <app-cflight-leg-details
            [ticket]="ticket"
            [isFirst]="i === 0"
            [isLast]="i === tickets.length - 1"
            [highlightDeparture]="highlightDeparture"
            [highlightArrival]="highlightArrival"
            [nextTicket]="i !== tickets.length - 1 ? tickets[i + 1] : null"
            [highlightBag]="highlightBag">
          </app-cflight-leg-details>
        </div>
      </div>

      <ng-content></ng-content>
    </div>
  </div>
</div>
