import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'roundCents'
})
export class RoundCentsPipe implements PipeTransform {
  transform(value: string | number): any {
    if (typeof value == 'string') {
      const arg = +value;
      const arg2 = arg.toFixed(2);
      value = arg2.toString();

      return value;
    } else if (typeof value == 'number') {
      const arg = value.toFixed(2);

      return arg;
    }
  }
}
