import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlight'
})
export class HighlightPipe implements PipeTransform {
  transform(value: string, searchTerm: string): string {
    if (!searchTerm) {
      return value;
    }

    // Escape special characters in the search term
    const escapedSearchTerm = searchTerm.replace(
      /[-\/\\^$*+?.()|[\]{}]/g,
      '\\$&'
    );

    // Create a regex that matches the search term at:
    // - the start of the string,
    // - the start of a word boundary,
    // - the first occurrence inside parentheses,
    // - after a hyphen (only for the first part).
    const regex = new RegExp(
      `(^${escapedSearchTerm}|\\b(${escapedSearchTerm})|\\(([^)]*?)(${escapedSearchTerm}))`,
      'i' // Case-insensitive, but limits to only the first match
    );

    // Replace the matched text, only highlighting the first part of the match
    return value.replace(regex, (match, p1, p2, p3, p4) => {
      // Highlight the match inside parentheses, but only the first occurrence
      if (p4) {
        const highlighted = `<span class="highlight">${p4.slice(0, searchTerm.length)}</span>`;
        return `(${p3}${highlighted}${p4.slice(searchTerm.length)}`;
      }
      // Highlight the match at the beginning of the string
      else if (p1) {
        const highlighted = `<span class="highlight">${p1.slice(0, searchTerm.length)}</span>`;
        return `${highlighted}${p1.slice(searchTerm.length)}`;
      }
      // Highlight the match at a word boundary (beginning of a word)
      else if (p2) {
        const highlighted = `<span class="highlight">${p2.slice(0, searchTerm.length)}</span>`;
        return `${highlighted}${p2.slice(searchTerm.length)}`;
      }
      return match;
    });
  }
}