import {
  Input,
  Renderer2,
  ComponentRef,
  OnDestroy,
  ViewContainerRef,
  Directive,
  ComponentFactoryResolver
} from '@angular/core';

import { LoadingComponent } from 'app/components/loading/loading.component';

@Directive({
  selector: '[appLoading]'
})
export class LoadingDirective implements OnDestroy {
  private componentRef: ComponentRef<any>;

  private _loading: boolean;
  @Input() set loading(value) {
    this._loading = value;
    if (this._loading) this.showLoading();
    else this.hideLoading();
  }

  get loading() {
    return this._loading;
  }

  constructor(
    private renderer: Renderer2,
    private container: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {}

  private showLoading() {
    const factory =
      this.componentFactoryResolver.resolveComponentFactory(LoadingComponent);

    this.componentRef = factory.create(this.container.injector);
    this.renderer.appendChild(
      this.container.element.nativeElement,
      this.componentRef.location.nativeElement
    );
  }
  private hideLoading() {
    if (this.componentRef) {
      this.renderer.removeChild(
        this.container.element.nativeElement,
        this.componentRef.location.nativeElement
      );
      this.componentRef.destroy();
      this.componentRef = null;
    }
  }

  ngOnDestroy() {
    this.hideLoading();
  }
}
