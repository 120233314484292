import { Pipe, PipeTransform } from '@angular/core';
import { Duration } from 'moment';

@Pipe({
  name: 'momentDiff'
})
export class MomentDiffPipe implements PipeTransform {
  transform(value: Duration, args?: any): any {
    if (args === 'full-numbers') {
      return (
        ('0' + value.hours()).slice(-2) +
        ':' +
        ('0' + value.minutes()).slice(-2) +
        ':' +
        ('0' + value.seconds()).slice(-2)
      );
    }

    if (args === 'shortened') {
      return (
        '' +
        Math.floor(value.asHours()) +
        'h ' +
        (
          '0' +
          (value.minutes() + Math.round(1 / (60 / value.seconds())))
        ).slice(-2) +
        'm'
      );
    }

    if (args === 'minutes') {
      if (Math.floor(value.minutes()) < 10) {
        return (
          '0' +
          Math.floor(value.minutes()) +
          'mm' +
          ('0' + value.seconds()).slice(-2) +
          'ss'
        );
      } else {
        return (
          '' +
          Math.floor(value.minutes()) +
          'mm' +
          ('0' + value.seconds()).slice(-2) +
          'ss'
        );
      }
    }

    return (
      ('0' + (value.hours() + value.days() * 24)).slice(-2) +
      'h ' +
      ('0' + value.minutes()).slice(-2) +
      'm ' +
      ('0' + value.seconds()).slice(-2) +
      's'
    );
  }
}
