<div
  #icon
  (mouseenter)="tooltipShow = true"
  (mouseleave)="tooltipShow = false"
  class="glyphicon"
  aria-hidden="true"
  [ngClass]="{
    'position-1': departureBoard === true,
    'position-2': departureBoard === false
  }">
  <div>
    <img width="15px" src="assets/img/icons/info-icon.png" />
  </div>
</div>
<div
  *ngIf="tooltipShow"
  #tooltipBox
  [ngClass]="{
    'tooltip fade bottom in': !isMobile,
    'baggage-style': baggage_section && !isMobile,
    'right-style': displayPosition === 'right' && !isMobile,
    'center-style': center && !isMobile,
    'baggage-tooltip-mobile-container': baggage_section && isMobile
  }"
  role="tooltip"
  [ngStyle]="{ left: left, top: top }">
  <div *ngIf="!isMobile" class="tooltip-arrow"></div>
  <div [ngClass]="{ 'baggage-tooltip-mobile-content': isMobile }">
    <div class="tooltip-inner" [ngClass]="{ 'tooltip-inner-mobile': isMobile }">
      <div class="img-content" *ngIf="baggage_section">
        <h6>Baggage Allowance</h6>
        <div class="baggages">
          <span class="img-container">
            <img class="small-bag" src="assets/img/icons/personal-item.png" />
          </span>
          <img
            class="checked-unchecked-icon"
            src="assets/img/icons/checked.svg" />
          <span class="bag-description"
            >Small Cabin Bag:
            <span class="included green-clr" *ngIf="baggage_items[0].qty"
              >included</span
            ></span
          >
        </div>
        <div class="baggages">
          <span class="img-container">
            <img class="cabin-bag" src="assets/img/icons/bag_2(1).png" />
          </span>
          <img
            *ngIf="!baggage_items[1].qty"
            class="checked-unchecked-icon"
            src="assets/img/icons/unchecked.svg" />
          <img
            *ngIf="baggage_items[1].qty"
            class="checked-unchecked-icon"
            src="assets/img/icons/checked.svg" />
          <span *ngIf="baggage_items[1].qty" class="bag-description">
            Large Cabin Bag:
            <span class="included green-clr">included</span></span
          >
          <span *ngIf="!baggage_items[1].qty" class="bag-description">
            Large Cabin Bag:
            <span class="included red-clr">not included</span></span
          >
        </div>
        <div class="baggages">
          <span class="img-container">
            <img class="checked-bag" src="assets/img/icons/bag_1.png" />
          </span>
          <img
            *ngIf="!baggage_items[2].qty"
            class="checked-unchecked-icon"
            src="assets/img/icons/unchecked.svg" />
          <img
            *ngIf="baggage_items[2].qty"
            class="checked-unchecked-icon"
            src="assets/img/icons/checked.svg" />
          <span *ngIf="baggage_items[2].qty" class="bag-description">
            Checked Bag: <span class="included green-clr">included</span>
            <span *ngIf="baggage_items[2].unit === 'KG'" class="green-clr">
              (1={{ baggage_items[2].qty }}kg)
            </span>
            <span
              *ngIf="baggage_items[2].unit === 'P' && baggage_items[2].qty >= 1"
              class="green-clr">
              ({{ baggage_items[2].qty }})
            </span>
          </span>
          <span *ngIf="!baggage_items[2].qty" class="bag-description">
            Checked Bag:
            <span class="included red-clr">not included</span></span
          >
        </div>
      </div>

      <div class="break" *ngIf="baggage_section"></div>
      <div [ngClass]="{ content: baggage_section === true }" class="ng-content">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
