import {
  Component,
  OnInit,
  Output,
  Input,
  EventEmitter,
  OnDestroy
} from '@angular/core';

import * as moment from 'moment-timezone';

import { CalendarDayService } from 'app/components/calendar-day/calendar-day.service';
import { CalendarDay } from 'app/shared';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-calendar-day',
  templateUrl: './calendar-day.component.html',
  styleUrls: ['./calendar-day.component.scss']
})
export class CalendarDayComponent implements OnInit, OnDestroy {
  @Output() clickOutput: EventEmitter<CalendarDay> = new EventEmitter();

  @Input() data: CalendarDay;

  selectedDate: CalendarDay;

  highlighted: boolean;
  from: moment.Moment;
  localTime = moment();

  subscriptions: Array<Subscription> = [];

  private dateOfFlight: Subscription;
  public showFromText = true;

  constructor(private calendarDayService: CalendarDayService) {}

  mouseEnter() {
    this.calendarDayService.onHover.emit(this.data.date);
  }

  mouseLeave() {
    this.calendarDayService.onHover.emit();
  }

  isWithinRange(from: moment.Moment, to: moment.Moment) {
    return (
      this.data.date.isBetween(from, to) ||
      this.data.date.isSame(from) ||
      this.data.date.isSame(to)
    );
  }

  selectDate(date: any) {
    if (this.selectDate === date) {
      this.highlighted = false;
      this.selectDate = date;
    }

    this.calendarDayService.to = this.data.date;
    this.data.isReturnDate = true;
    this.clickOutput.emit(this.data);
  }

  reset() {
    this.from = this.calendarDayService.from;
    const to = this.calendarDayService.to;
    if (!to) return (this.highlighted = false);

    this.highlighted = this.isWithinRange(this.from, to);
    this.data.isReturnDate = to.diff(this.data.date, 'days') == 0;
  }

  ngOnDestroy() {
    this.subscriptions.map(subscription => {
      subscription.unsubscribe();
    });

    if (this.dateOfFlight) {
      this.dateOfFlight.unsubscribe();
    }
  }

  isTomorrow() {
    const today = moment(new Date(this.localTime.format('YYYY MM DD'))).startOf('day');
    const data = moment(new Date(this.data.date.format('YYYY MM DD'))).startOf('day');
  
    const diffInDays = data.diff(today, 'days');
    return diffInDays === 1;
  }

  ngOnInit() {
    let subscription = this.calendarDayService.onHover.subscribe(
      (hoveredDate: moment.Moment) => {
        if (!hoveredDate) {
          // it means we need to unhover everything
          return this.reset();
        }
        const difference = Math.abs(this.data.date.diff(hoveredDate, 'hour'));
        if (difference >= 0) {
          // if it is not the same day of the month
          this.highlighted = this.isWithinRange(
            this.calendarDayService.from,
            hoveredDate
          );
        }
      }
    );

    if (this.data.options.length < 2) {
      this.showFromText = false;
    } else {
      const price = this.data.options[0].price;
      for (let i = 1; i < this.data.options.length; i++) {
        if (this.data.options[i].price != price) {
          return;
        }
      }
      this.showFromText = false;
    }

    this.dateOfFlight = this.calendarDayService.dateOfFlight$.subscribe(
      date => {
        if (this.data.date != undefined && this.data.date.isSame(date, 'day')) {
          this.showFromText = false;
        }
      }
    );

    this.subscriptions.push(subscription);

    subscription = this.calendarDayService.onReturnDateSelected.subscribe(
      () => {
        this.reset();
      }
    );

    this.subscriptions.push(subscription);

    if (this.data.isStartDate) this.calendarDayService.from = this.data.date;
    this.reset();
  }
}
