import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import * as moment from 'moment-timezone';

if (environment.production) {
  enableProdMode();
  if (window) {
    window.console.log = () => {};
  }
}

moment.tz.setDefault('Europe/London');
platformBrowserDynamic().bootstrapModule(AppModule);
