<div class="main-container">
  <div class="departure-board-container newsletter-section">
    <div class="home-page-section-header">
      <h3 class="home-page-section-header-text">
        Do you want to know when we add deals to new destinations?
      </h3>
    </div>

    <div class="newsletter-container">
      <div class="newsletter-form" *ngIf="!submitted">
        <div *ngIf="!submitted">
          <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="row">
              <div class="col-md-12 col-sm-12 col-xs-24 col">
                <p class="label">First Name</p>
                <div class="input-wrapp">
                  <div
                    *ngIf="
                      form.controls['firstName'].valid &&
                      form.controls['firstName'].touched
                    "
                    class="toggle ok">
                    <span class="glyphicon glyphicon-ok"></span>
                  </div>
                  <div
                    *ngIf="
                      form.controls['firstName'].invalid &&
                      form.controls['firstName'].touched &&
                      !form.controls['firstName'].pristine &&
                      form.controls['email'].value
                    "
                    class="toggle error">
                    <span class="glyphicon glyphicon-remove"></span>
                  </div>
                  <input
                    [(ngModel)]="firstName"
                    [formControl]="form.controls['firstName']"
                    type="text"
                    class="default-input normal first-name" />
                </div>
              </div>
              <div class="col-md-12 col-sm-12 col-xs-24 col">
                <p class="label">Email Address</p>
                <div class="input-wrapp">
                  <div
                    *ngIf="
                      form.controls['email'].valid &&
                      form.controls['email'].touched
                    "
                    class="toggle ok">
                    <span class="glyphicon glyphicon-ok"></span>
                  </div>
                  <div
                    *ngIf="
                      form.controls['email'].invalid &&
                      form.controls['email'].touched &&
                      !form.controls['email'].pristine &&
                      !focused &&
                      form.controls['email'].value
                    "
                    class="toggle error">
                    <span class="glyphicon glyphicon-remove"></span>
                  </div>
                  <input
                    [(ngModel)]="email"
                    [formControl]="form.controls['email']"
                    type="email"
                    class="default-input email"
                    (focus)="focused = true"
                    (focusout)="focused = false" />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-24 col-sm-24 col-xs-24 col">
                <button
                  class="yellow-button"
                  [ngClass]="{ disabled: !form.valid }"
                  [disabled]="!form.valid"
                  type="submit">
                  Yes, keep me posted!
                </button>
              </div>
            </div>
          </form>
        </div>

        <div class="row">
          <div class="col-md-24 col-sm-24 col-xs-24 col">
            <p class="note">
              No worry, no spam. We'll send you an email only when a really good
              deal becomes available.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
