<div #main_container class="main">
  <app-keep-waiting *ngIf="keepWaiting"></app-keep-waiting>
  <div class="departure-board-container" appLoading [loading]="loading">
    <div
      #topBarContainer
      class="top-bar"
      [ngClass]="{ 'fixed-top-bar': fixedTopBar }">
      <div
        *ngIf="!loading"
        class="yellow-header"
        [ngClass]="{
          'has-destination': !(
            flightsBoard &&
            !destinationCityName &&
            !destinationCountryName
          )
        }">
        <div class="container-fluid">
          <div class="header-container">
            <div
              class="col departure"
              [ngClass]="{
                'to-top': destinationCityName || destinationCountryName
              }"
              *ngIf="!isSearchOpen">
              <i
                class="icon-departure left"
                [ngClass]="{
                  'icon-departure-destination': !(
                    flightsBoard &&
                    !destinationCityName &&
                    !destinationCountryName
                  )
                }"></i>
              <div
                class="mob-show destination-country"
                *ngIf="destinationCityName || destinationCountryName">
                From London
              </div>
              <p class="left flex-p">
                <span class="mob-remove"
                  >Departures
                  <span *ngIf="!destinationCityName && !destinationCountryName"
                    >from</span
                  >
                  <span *ngIf="destinationCityName || destinationCountryName"
                    >from London</span
                  ></span
                >
                <span
                  class="for-mobile"
                  *ngIf="!destinationCityName && !destinationCountryName"
                  >From</span
                >
              </p>
              <div
                *ngIf="!destinationCityName && !destinationCountryName"
                class="selectpicker-wrapper">
                <app-departure-list
                  *ngIf="
                    flightsBoard &&
                    departureTree &&
                    !destinationCityName &&
                    !destinationCountryName
                  "
                  (selectAction)="onDepartureSelected($event)"
                  [departureTree]="departureTree"
                  [selectedDepartureNode]="selectedCity"></app-departure-list>
              </div>
            </div>
            <div
              class="flights-count-container"
              [ngClass]="{
                'flights-count-container-to-left':
                  notFilteredFlightsOfCityOrAirport.length <
                  flightsBoard.filterVisibilityThreshold
              }">
              <p class="flights-count right flex-p-2">
                <span class="mob-remove-6">
                  <span
                    [ngClass]="{
                      bold:
                        notFilteredFlightsOfCityOrAirport.length >=
                          flightsBoard.filterVisibilityThreshold &&
                        notFilteredFlightsOfCityOrAirport.length !==
                          flights.length
                    }"
                    >{{ flights.length }}</span
                  >
                  <span
                    *ngIf="
                      notFilteredFlightsOfCityOrAirport.length >=
                        flightsBoard.filterVisibilityThreshold &&
                      notFilteredFlightsOfCityOrAirport.length !==
                        flights.length
                    ">
                    of {{ notFilteredFlightsOfCityOrAirport.length }}</span
                  >
                </span>
                <span
                  *ngIf="
                    notFilteredFlightsOfCityOrAirport.length >=
                      flightsBoard.filterVisibilityThreshold &&
                    notFilteredFlightsOfCityOrAirport.length !== flights.length
                  "
                  class="mob-show-5"
                  >{{
                    flights.length +
                      '/' +
                      notFilteredFlightsOfCityOrAirport.length
                  }}</span
                >
                <span class="flight-name">
                  {{
                    notFilteredFlightsOfCityOrAirport.length > 1 ||
                    notFilteredFlightsOfCityOrAirport.length === 0
                      ? ' flights'
                      : ' flight'
                  }}</span
                >
                <span
                  *ngIf="
                    notFilteredFlightsOfCityOrAirport.length === flights.length
                  ">
                  (All)</span
                >
              </p>
            </div>
            <div *ngIf="isSearchOpen" class="search-container">
              <app-search
                [flights]="flightsForSearch"
                (flightsChanged)="onFlightsChanged($event)"></app-search>
            </div>

            <div class="col date">
              <div class="filters-trigger">
                <span>
                  <button
                    *ngIf="
                      !isSearchOpen &&
                      notFilteredFlightsOfCityOrAirport.length >=
                        flightsBoard.filterVisibilityThreshold &&
                      !activeFiltersList
                    "
                    class="filter-btn"
                    (click)="openFiltersPopup(false)"
                    type="button"
                    class="btn btn-info btn-filters right transition-all">
                    <span class="mob-show-3 filter-icon">
                      <img src="../../../assets/img/icons/filter.svg" />
                    </span>
                  </button>
                  <span
                    *ngIf="
                      !isSearchOpen &&
                      notFilteredFlightsOfCityOrAirport.length >=
                        flightsBoard.filterVisibilityThreshold &&
                      activeFiltersList
                    "
                    (click)="clearFilters()">
                    <div class="clear-filters">All flights</div>
                  </span>
                </span>

                <span class="search-icon">
                  <button
                    (click)="openSearch()"
                    *ngIf="
                      !activeFiltersList &&
                      !isSearchOpen &&
                      ((flightsLoaded &&
                        flights.length >=
                          flightsBoard?.filterVisibilityThreshold) ||
                        isFiltersActive)
                    "
                    type="button"
                    class="btn btn-info btn-filters right transition-all">
                    <span class="filter-icon search-icon">
                      <img src="../../../assets/img/icons/search.svg" />
                    </span>
                  </button>

                  <button
                    *ngIf="isSearchOpen"
                    (click)="openSearch()"
                    type="button"
                    class="btn btn-info btn-filters right transition-all">
                    <span class="filter-icon search-icon">
                      <img src="../../../assets/img/icons/cross.svg" />
                    </span>
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        *ngIf="!isDesktop && !loading && activeFiltersList"
        class="active-filters-container">
        <div class="filters-header">
          <div>
            <span class="filters-header-text">Filters</span>
            <span class="filters-header-count">{{
              ' (' + filtersService.countOfFilters + ' applied)'
            }}</span>
          </div>
          <div>
            <span
              (click)="openFiltersPopup(true)"
              class="filters-edit filters-btn"
              >Edit</span
            >
            <span (click)="clearFilters()" class="filters-btn">{{
              filtersService.countOfFilters > 1 ? 'Clear all' : 'Clear'
            }}</span>
          </div>
        </div>
        <div class="active-filters">
          <ng-container *ngFor="let filterKey of getFilterKeys()">
            <ng-container *ngFor="let item of activeFiltersList[filterKey]">
              <app-filter-item
                [text]="item"
                (applyFilter)="deleteFilter(filterKey, item)"></app-filter-item>
            </ng-container>
          </ng-container>
        </div>
      </div>

      <div *ngIf="!loading" class="date-info n-header-wrapper">
        <div class="date-and-time">
          <p class="left">{{ localTime | amDateFormat: 'HH:mm' }}</p>
        </div>
        <div class="date-and-time">
          <p class="right for-desktop">
            {{ localTime | amDateFormat: 'dddd DD MMMM' }}
          </p>
          <p class="right for-mobile">
            {{ localTime | amDateFormat: 'ddd DD MMM' }}
          </p>
        </div>
      </div>

      <div
        class="pop-up"
        [ngClass]="{ 'filters-hidden': !showPopup }"
        [@toggleAnimation]
        *ngIf="!isDesktop">
        <div class="content-filter">
          <app-flights-filter
            [desktop]="false"
            (resetOutput)="clearFilters($event)"
            (closeOutput)="close()"
            (applyOutput)="applyFilter($event); sort(1, 'time')"
            (optionChecked)="updateFlightsCount($event)"
            [flights]="flightsForFilters"
            [labels]="labels"
            [notFilteredFlights]="notFilteredFlightsOfCityOrAirport">
          </app-flights-filter>
        </div>
        <div
          class="available-flights"
          *ngIf="filtersService.filtersHasLength()">
          <span
            ><span
              [ngClass]="{
                bold:
                  flightsForFilters.length <
                  notFilteredFlightsOfCityOrAirport.length
              }"
              >{{ flightsForFilters.length }}</span
            >
            of {{ notFilteredFlightsOfCityOrAirport.length }} flights</span
          >
        </div>
        <div class="submit-btn" (click)="applyMobileFilters()">
          <button
            type="button"
            [ngClass]="{ disabled: !filtersService.filtersHasLength() }"
            [disabled]="!filtersService.filtersHasLength()">
            Show filtered results
          </button>
        </div>
      </div>
      <div *ngIf="!loading" class="flight-type-tabs">
        <div
          [ngClass]="{
            'flight-type-tab': flightsByCategory.roundTrip.length,
            'active-tab': !isOneWayFlight && flightsByCategory.roundTrip.length,
            'inactive-tab': !flightsByCategory.roundTrip.length
          }"
          (click)="
            flightsByCategory.roundTrip.length
              ? toggleIsOneWayFlight(false)
              : null
          ">
          <img
            *ngIf="isOneWayFlight || !flightsByCategory.roundTrip.length"
            alt="img"
            class="flight-type-icon return-icon standart-icons"
            src="assets/img/icons/2planes.svg" />
          <img
            *ngIf="isOneWayFlight"
            alt="img"
            class="flight-type-icon return-icon hover-icons"
            src="assets/img/icons/2planes-yellow.svg" />
          <img
            *ngIf="!isOneWayFlight && flightsByCategory.roundTrip.length"
            alt="img"
            class="flight-type-icon return-icon"
            src="assets/img/icons/2planes-yellow.svg" />
          Return
          <!-- <span *ngIf="isDesktop === false">(rt)</span> -->
        </div>
        <div
          [ngClass]="{
            'flight-type-tab': flightsByCategory.oneWay.length,
            'active-tab': isOneWayFlight && flightsByCategory.oneWay.length,
            'inactive-tab': !flightsByCategory.oneWay.length
          }"
          (click)="
            flightsByCategory.oneWay.length ? toggleIsOneWayFlight(true) : null
          ">
          <img
            *ngIf="!isOneWayFlight || !flightsByCategory.oneWay.length"
            alt="img"
            class="flight-type-icon standart-icons one-way-icon"
            src="assets/img/icons/basket-o-gray.svg" />
          <img
            *ngIf="!isOneWayFlight"
            alt="img"
            class="flight-type-icon one-way-icon hover-icons"
            src="assets/img/icons/basket-o-yellow.svg" />
          <img
            *ngIf="isOneWayFlight && flightsByCategory.oneWay.length"
            alt="img"
            class="flight-type-icon one-way-icon"
            src="assets/img/icons/basket-o-yellow.svg" />
          One way
          <!-- <span *ngIf="isDesktop === false">(ow)</span> -->
        </div>
      </div>
      <div *ngIf="!loading" class="board-titles">
        <div class="n-header-grid-wrapper">
          <div class="n-header-grid">
            <app-table-header
              class="time"
              [cssClasses]="'time'"
              [isActive]="flights.length > 1 && activeTableHeaders.time"
              [isDefault]="true"
              (sort)="sort($event, 'time')"
              (click)="closeAccordion()"
              [showArrows]="flights.length > 1">
              <p
                [ngClass]="{
                  'active-sort-by':
                    flights.length > 1 && activeTableHeaders.time
                }"
                class="transition-all">
                Time
              </p>
            </app-table-header>

            <app-table-header
              class="destination"
              (sort)="sort($event, 'destination')"
              (click)="closeAccordion()"
              [showArrows]="flights.length > 1">
              <p
                [ngClass]="{ 'active-sort-by': activeTableHeaders.destination }"
                class="destination-space transition-all">
                Destination
              </p>
            </app-table-header>

            <app-table-header
              class="n-stops"
              (sort)="sort($event, 'stops')"
              (click)="closeAccordion()"
              [showArrows]="flights.length > 1">
              <p
                [ngClass]="{ 'active-sort-by': activeTableHeaders.stop }"
                class="transition-all">
                Stops
              </p>
            </app-table-header>

            <app-table-header
              class="n-airline"
              (sort)="sort($event, 'airline')"
              (click)="closeAccordion()"
              [showArrows]="flights.length > 1">
              <p
                [ngClass]="{ 'active-sort-by': activeTableHeaders.airline }"
                class="transition-all">
                Airline
              </p>
            </app-table-header>

            <div class="n-price">
              <app-table-header
                (sort)="sort($event, 'price')"
                (click)="closeAccordion()"
                [showArrows]="flights.length > 1">
                <p
                  [ngClass]="{ 'active-sort-by': activeTableHeaders.price }"
                  class="transition-all">
                  Price
                </p>
              </app-table-header>

              <app-tooltip
                *ngIf="isDesktop"
                [displayPosition]="'right'"
                [width]="350"
                [departureBoard]="true">
                Prices displayed are
                <span *ngIf="!isOneWayFlight">‘from prices’</span> in British
                Pound Sterling (GBP) per 1 adult including all taxes and fees,
                except charges for additional optional services.
                <br />
                <br />
                Note that until booking is confirmed, fares displayed are
                subject to change as are based on historical data (on average
                fares shown were last found 90 minutes before).
              </app-tooltip>
            </div>
            <div class="n-dep-time">
              <app-table-header
                class="n-dep-time n-dep-time-header"
                (sort)="sort($event, 'time-left')"
                (click)="closeAccordion()"
                [showArrows]="flights.length > 1">
                <p
                  [ngClass]="{ 'active-sort-by': activeTableHeaders.timeLeft }"
                  class="transition-all">
                  Time left
                </p>
              </app-table-header>
              <app-tooltip
                *ngIf="isDesktop === true"
                [displayPosition]="'right'"
                [width]="350"
                [departureBoard]="true">
                This is the time left to the scheduled departure. Flights shown
                will be removed from the departure board 3 hours before the
                scheduled departure.
              </app-tooltip>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div appLoading [loading]="!loading && !flightsLoaded" class="flights-list">
      <div #flight_accordion *ngIf="flightsLoaded">
        <!-- VISIBLE FLIGHTS -->
        <app-flight-accordion
          #flightAccordionComponent
          *ngFor="
            let i = index;
            let flight;
            of: flights | limitTo: visibleFlightsLimit
          "
          id="{{ flight.id }}"
          [flight]="flight"
          [localDate]="localTime"
          [searchText]="searchText"
          (keepWaiting)="updateKeepWaiting($event)"
          (isToggling)="toggleElement()"
          (updateIsLoading)="isLoading($event)">
        </app-flight-accordion>
      </div>
      <div
        *ngIf="
          (destinationCityName || destinationCountryName) &&
          flightsLoaded &&
          flights.length === 0
        "
        class="showing no-flights">
        <div class="n-header-grid-wrapper">
          <p class="no-flight-text">
            Unfortunately there are no last minute flights to
            {{ destinationCityName || destinationCountryName }}.
          </p>
        </div>
      </div>

      <div
        *ngIf="
          isSearchOpen && searchText && flightsLoaded && flights.length === 0
        "
        class="showing no-flights">
        <div class="n-header-grid-wrapper">
          <p class="no-flight-text">
            Couldn't find {{ "'" + searchText + "'" }} among the available
            destinations
          </p>
        </div>
      </div>

      <div *ngIf="flightsLoaded" class="items-amount-area">
        <div class="show-less-btn">
          <div
            *ngIf="
              flights &&
              flights.length > 5 &&
              visibleFlightsLimit > 5 &&
              visibleFlightsLimit !== flights.length
            "
            class="change-amount-button"
            (click)="showLess()">
            Show less
            <i class="flaticon-agree show-less"></i>
          </div>
        </div>
        <div class="items-amount col-8">
          Showing
          {{
            visibleFlightsLimit > flights.length
              ? flights.length
              : visibleFlightsLimit
          }}
          of
          {{ flights.length }}
          flights
        </div>
        <div class="show-all-btn">
          <div
            *ngIf="
              flights &&
              flights.length > 5 &&
              visibleFlightsLimit + 15 < flights.length &&
              visibleFlightsLimit !== flights.length
            "
            class="change-amount-button"
            (click)="showAll()">
            Show all
            <i class="flaticon-agree show-more"></i>
          </div>
        </div>
      </div>

      <!-- MORE FLIGHTS -->
      <div class="btn-section">
        <button
          *ngIf="
            flights && visibleFlightsLimit < flights.length && flightsLoaded
          "
          class="yellow-button destinations-button"
          (click)="showMore()">
          <span class="btn-content">
            <span class="btn-text"
              >Show
              {{
                visibleFlightsLimit + 15 >= flights.length
                  ? 'all ' + flights.length + ' flights'
                  : ' 10 more flights'
              }}</span
            >
            <i class="flaticon-agree show-more"></i>
          </span>
        </button>

        <button
          *ngIf="
            flights &&
            flights.length > initialVisibleFlightsLimit &&
            flights.length - visibleFlightsLimit === 0 &&
            flightsLoaded
          "
          class="yellow-button destinations-button"
          (click)="showLess()">
          <span class="btn-content">
            <span class="btn-text">Show less flights</span>
            <i class="flaticon-agree show-less"></i>
          </span>
        </button>

        <button
          *ngIf="
            (destinationCityName || destinationCountryName) && flightsLoaded
          "
          routerLink="/"
          class="yellow-button">
          <span class="btn-content">
            <span class="btn-text">View all destinations</span>
            <i class="flaticon-agree"></i>
          </span>
        </button>
      </div>
    </div>
  </div>
  <div
    [@toggleFilter]
    #filterContainer
    *ngIf="
      isDesktop === true &&
      ((flightsLoaded &&
        flights.length >= flightsBoard?.filterVisibilityThreshold) ||
        isFiltersActive) &&
      !isSearchOpen
    "
    class="filters-container"
    [ngClass]="{ fixed: fixedComponent }">
    <app-flights-filter
      (resetOutput)="resetFiltersForDesktop()"
      [desktop]="true"
      (applyOutput)="applyFilter($event)"
      [flights]="flights"
      [labels]="labels"
      [notFilteredFlights]="notFilteredFlightsOfCityOrAirport">
    </app-flights-filter>
  </div>
</div>
