import { map, timeout } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from 'app/services/config/config.service';
import {
  Booker,
  BookingData,
  CalendarDay,
  Flight,
  FlightsBoard,
  Passenger,
  PriceBreakdown,
  ReturnFlightsContainer,
  GeoData,
  Coordinates,
  ApiSession,
  Country,
  Blog,
  Cities,
  Labels
} from 'app/shared';
import * as moment from 'moment';
import { Observable } from 'rxjs';

export class RestError extends Error {
  code: any;
  constructor(message, code) {
    super(message);
    this.code = code;
  }
}

@Injectable()
export class RestService {
  private headers: HttpHeaders = new HttpHeaders();

  constructor(
    private config: ConfigService,
    private http: HttpClient
  ) {}

  initApiSession(
    browserId: string,
    userTimeZoneOffset,
    geoLocation?: Coordinates,
    countryCode?: string
  ): Observable<ApiSession> {
    const URL = this.config.getApi('INIT_SESSION');
    const body = { browserId, geoLocation, userTimeZoneOffset, countryCode };
    return this.http.post(URL, body).pipe(
      map(result => {
        return new ApiSession(result);
      })
    );
  }

  getLocations(
    countryCode?: string
  ): Observable<Cities> {
    const URL = this.config.getApi('GET_LOCATIONS');
    const request = this.http.get(URL, {
      headers: this.headers,
      params: { countryCode }
    })
    return request.pipe(
      map((res: any) => {
        return new Cities(res);
      })
    );
  }

  getLabels(): Observable<Labels> {
    const URL = this.config.getApi('GET_LABELS');
    const request = this.http.get(URL, {
      headers: this.headers,
    })
    return request.pipe(
      map((res: any) => {
        return new Labels(res);
      })
    );
  }

  getFlightsBoard(
    cityCode: string = "LON",
    departureAirportCode?: string,
    isOneWay?: boolean,

  ): Observable<FlightsBoard> {
    const URL = this.config.getApi('FLIGHTS_BOARD');
    const params: {
      cityCode?: string;
      departureAirportCode?: string;
      isOneWay?: boolean;
    } = { cityCode };

    if (departureAirportCode) params.departureAirportCode = departureAirportCode;
    if (isOneWay) params.isOneWay = isOneWay;


    const request = this.http.get(URL, {
      headers: this.headers,
      params: params
    });
    return request.pipe(
      map((res: any) => {
        return new FlightsBoard(res.data);
      })
    );
  }

  getFlight(flightId: string | number): Observable<Flight> {
    const URL = this.config.getApi('FLIGHT');
    return this.http
      .get(URL, {
        params: { outboundFlightKey: flightId + '' },
        headers: this.headers
      })
      .pipe(
        map((res: any) => {
          if (res.errorCode) throw new Error(res.errorMessage);
          return new Flight(res.data);
        })
      );
  }

  getDepartureFlight(flightId: string | number): Observable<Flight> {
    const URL = this.config.getApi('DEPARTURE_FLIGHT');
    return this.http
      .get(URL, {
        params: { outboundFlightKey: flightId + '' },
        headers: this.headers
      })
      .pipe(
        map((res: any) => {
          if (res.errorCode) throw new Error(res.errorMessage);
          return new Flight(res.data);
        })
      );
  }

  getReturnFlightContainer(
    flightId: string,
    date: moment.Moment
  ): Observable<ReturnFlightsContainer> {
    const URL = this.config.getApi('RETURN_FLIGHTS');
    return this.http
      .get(URL, {
        params: {
          selectedFlightKey: flightId,
          date: date.format('L')
        },
        headers: this.headers
      })
      .pipe(
        map((res: any) => {
          if (res.errorCode) throw new Error(res.errorMessage);
          const returnFlightsContainer = new ReturnFlightsContainer();
          returnFlightsContainer.date = moment(res.data.date);
          returnFlightsContainer.returnFlights = res.data.returnFlights.map(
            doc => new Flight(doc)
          );
          returnFlightsContainer.otherOutboundFlights =
            res.data.otherOutboundFlights.map(doc => new Flight(doc));
          return returnFlightsContainer;
        })
      );
  }

  getPriceBreakdown(
    flightId: string,
    adultCount: any,
    childCount: any,
    infantCount: any
  ) {
    const URL = this.config.getApi('PRICE_BREAKDOWN');

    return this.http
      .get(URL, {
        params: {
          flightKey: flightId,
          adultCount: adultCount,
          childCount: childCount,
          infantCount: infantCount
        },
        headers: this.headers
      })
      .pipe(
        map((res: any) => {
          if (res.errorCode)
            throw new RestError(res.errorMessage, res.errorCode);

          return new PriceBreakdown(res.data);
        })
      );
  }

  getBookingData(bookingId: string) {
    const URL = this.config.getApi('BOOKING_COMFIRMATION');
    return this.http
      .get(URL, {
        params: {
          bookingId: bookingId
        },
        headers: this.headers
      })
      .pipe(
        map((res: any) => {
          if (res.errorCode) throw new Error(res.errorMessage);
          return new BookingData(res.data);
        })
      );
  }

  // getCalendarDays(flightId: string, startDate: moment.Moment): Observable<Array<CalendarDay>> {
  //     const URL = this.config.getApi('FLIGHTS_CALENDAR')
  //     return this.http.get(URL, {
  //         params: {
  //             selectedFlightKey: flightId,
  //         },
  //         headers: this.headers
  //     }).pipe(map((res: any) => {
  //         if (res.errorCode)
  //             throw new Error(res.errorMessage)

  //         let calendarDays: Array<CalendarDay> = [];
  //         let calendarDay = new CalendarDay(moment(startDate))

  //         calendarDay.isStartDate = true;
  //         calendarDays.push(calendarDay);
  //         res.data.map((doc) => {
  //             calendarDay = new CalendarDay(moment(doc.date));
  //             calendarDay.currency = doc.currencyCode;
  //             calendarDay.isLoaded = true;
  //             calendarDay.isOtherOutbound = doc.isOtherOutbound;
  //             calendarDay.isAvailable = doc.isAvailable;
  //             calendarDay.price = doc.price;
  //             calendarDays.push(calendarDay);
  //         })
  //         return calendarDays;
  //     }))
  // }

  getCalendarDays(
    flightId: string,
    startDate: moment.Moment
  ): Observable<Array<CalendarDay>> {
    const URL = this.config.getApi('INBOUND_DATES');
    return this.http
      .get(URL, {
        params: {
          outboundFlightKey: flightId
        },
        headers: this.headers
      })
      .pipe(
        map((res: any) => {
          if (res.errorCode) throw new Error(res.errorMessage);

          const calendarDays: Array<CalendarDay> = [];
          let calendarDay = new CalendarDay(moment(startDate));

          calendarDay.isStartDate = true;
          calendarDays.push(calendarDay);

          res.data.map(doc => {
            if (moment(doc.date).isAfter(moment()) && doc.minPrice) {
              calendarDay = new CalendarDay(moment(doc.date));
              calendarDay.currency = doc.currencyCode;
              calendarDay.isLoaded = true;
              calendarDay.isOtherOutbound = doc.isOtherOutbound;
              calendarDay.isAvailable = doc.isAvailable;
              calendarDay.price = doc.minPrice;
              calendarDay.isLowestPrice = doc.isLowest;
              if (doc.options.length) {
                doc.options.forEach(flight => {
                  calendarDay.options.push(new Flight(flight));
                });
              }
              calendarDays.push(calendarDay);
            }
          });
          return calendarDays;
        })
      );
  }

  bookFlight(
    bookingId: string,
    passengers: Passenger[],
    booker: Booker
  ): Observable<string> {
    const URL = this.config.getApi('PAYMENT').replace('{bookingId}', bookingId);

    return this.http
      .post(
        URL,
        {
          booker: booker,
          passengerList: passengers
        },
        { headers: this.headers }
      )
      .pipe(
        map((res: any) => {
          if (res.data && res.data.paymentPageURL)
            return res.data.paymentPageURL;

          throw new RestError(res.errorMessage, res.errorCode);
        })
      );
  }

  getGeolocationData() {
    //const URL = "https://ipinfo.io/geo";
    const URL = 'https://api.bigdatacloud.net/data/reverse-geocode-client';
    return this.http.get(URL).pipe(
      timeout(3000),
      map((res: any) => {
        if (res) {
          //let loc:string = res.loc;
          //let lat = Number.parseFloat(res.latitude.split(",")[0]);
          const lat = res.latitude;
          //let long = Number.parseFloat(res.longitude.split(",")[1]);
          const long = res.longitude;

          const coordinates: Coordinates = { latitude: lat, longitude: long };
          const countryCode: string = res.countryCode;
          return new GeoData({ coordinates, countryCode });
        }
      })
    );
  }

  setSessionId(sessionId: string) {
    this.headers = this.headers.append('sId', sessionId);
  }

  sendHelpMessage(data: any) {
    const URL = this.config.getApi('HELP_MESSAGE');

    return this.http.post(URL, data, { headers: this.headers }).pipe(
      map((res: any) => {
        if (res.data) return res.data;

        throw new RestError(res.errorMessage, res.errorCode);
      })
    );
  }

  subscribeToNewsletter(data: any) {
    const URL = this.config.getApi('NEWSLETTER_SUBSCRIPTION');

    return this.http.post(URL, data, { headers: this.headers }).pipe(
      map((res: any) => {
        if (res.data) {
          return res.data;
        }

        throw new RestError(res.errorMessage, res.errorCode);
      })
    );
  }

  applyDiscountCode(bookingId, discountCode) {
    const url = this.config.getApi('APPLY_DISCOUNT_CODE');

    return this.http
      .post(
        url,
        {},
        { headers: this.headers, params: { bookingId, discountCode } }
      )
      .pipe(
        map((res: any) => {
          if (res.data) {
            return new PriceBreakdown(res.data);
          }

          throw new RestError(res.errorMessage, res.errorCode);
        })
      );
  }

  removeDiscountCode(bookingId) {
    const url = this.config.getApi('REMOVE_DISCOUNT_CODE');

    return this.http
      .post(url, {}, { headers: this.headers, params: { bookingId } })
      .pipe(
        map((res: any) => {
          if (res.data) {
            return new PriceBreakdown(res.data);
          }

          throw new RestError(res.errorMessage, res.errorCode);
        })
      );
  }

  getCountryList(): Observable<Array<Country>> {
    const url = this.config.getApi('GET_COUNTRY_LIST');
    const countryLsit = [];

    return this.http.get(url, { headers: this.headers }).pipe(
      map((item: any) => {
        if (item.data) {
          item.data.forEach(country => {
            countryLsit.push(new Country(country));
          });
        }
        return countryLsit;
      })
    );
  }

  getBlogPosts() {
    const url = this.config.getApi('GET_BLOG_POSTS');
    const blogs = [];
    return this.http.get(url, { headers: this.headers }).pipe(
      map((res: any) => {
        if (res.data) {
          res.data.forEach(item => {
            blogs.push(new Blog(item));
          });

          return blogs;
        }
        throw new RestError(res.errorMessage, res.errorCode);
      })
    );
  }

  getBlogPostById(id: string) {
    const url = this.config.getApi('GET_BLOG_POST_BY_ID');
    return this.http
      .get(url, { params: { id: id }, headers: this.headers })
      .pipe(
        map((res: any) => {
          if (res.data) {
            return new Blog(res.data);
          }
          throw new RestError(res.errorMessage, res.errorCode);
        })
      );
  }
}
