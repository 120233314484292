import {
  Component,
  ChangeDetectionStrategy,
  ElementRef,
  ViewChild,
  OnInit,
  ChangeDetectorRef,
  HostListener
} from '@angular/core';

@Component({
  selector: 'app-customer-reviews',
  templateUrl: './customer-reviews.component.html',
  styleUrls: ['./customer-reviews.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomerReviewsComponent implements OnInit {
  @ViewChild('reviewsContainer') reviewsContainer: ElementRef;

  public reviews = [
    {
      reviewText:
        'Interesting website for spontaneous and last-minute travellers!',
      name: 'James d.',
      score: 5,
      date: "6 Oct 2024"
    },
    {
      reviewText:
        'Love the concept behind these guys. First website to actually specialize in lastminute flights. I guess there is still margin for improvement but so far so good!',
      name: 'Evelyn M',
      score: 5,
      date: "15 Sep 2024"
    },
    {
      reviewText: 'Excellent website for spontaneous travellers with some good deals.',
      name: 'DeanB',
      score: 5,
      date: '5 Sep 2024'
    },
    {
      reviewText:
        'The information is accurate and easy to make reservations. Website is easy to avivate and booking was Quick and simple.',
      name: 'Simon Ashford',
      score: 5,
      date: '16 Aug 2024'
    },
    {
      reviewText:
        'Very interesting website for last minute flight deals!',
      name: 'Lewis L',
      score: 5,
      date: '16 Aug 2024'
    },
    {
      reviewText:
        'Simple and clean website. Easy to understand and with good promotions for lastminute travellers.',
      name: 'Jose Oliveira',
      score: 5,
      date: '09 Aug 2024'
    },
    {
      reviewText:
        'I like runtofly. It’s simple to book and gives you lots of ideas for some exciting getaways. Prices are between good and very good!',
      name: 'Jason Southgate',
      score: 5,
      date: '27 Jul 2024'
    },
    {
      reviewText:
        'A lovely website, especially for those who have flexibility on their ends. And good choice of flights as well.',
      name: 'emilyb',
      score: 5,
      date: '16 Jun 2024'
    },
  ];
  isMobile = false;
  isProcessing = false;
  isAtStart = true;
  isAtEnd = false;

  public transformStyle: any;
  private debounceTimer;
  private debounceDelay = 800;

  constructor(private cdRef: ChangeDetectorRef) {}

  @HostListener('window:resize', ['$event'])
  onResize() {
    clearTimeout(this.debounceTimer);
    this.debounceTimer = setTimeout(() => {
      if (window.innerWidth > 650) {
        this.isMobile = false;
      } else {
        this.isMobile = true;
      }
    }, this.debounceDelay);
  }

  ngOnInit() {
    if (window.innerWidth > 650) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }

    setTimeout(() => this.checkArrowStates(), 500);
  }

  onPreviousClick() {
    if (this.isProcessing || this.isAtStart) return;
  
    this.isProcessing = true;
    this.checkArrowStates();
  
    const step = this.calcStep();
    const newScrollLeft = this.reviewsContainer.nativeElement.scrollLeft - step;
  
    this.reviewsContainer.nativeElement.scrollTo({
      left: Math.round(newScrollLeft),
      behavior: 'smooth'
    });
  
    setTimeout(() => {
      this.checkArrowStates();
      this.isProcessing = false;
    }, 500);
  }
  
  onNextClick() {
    if (this.isProcessing || this.isAtEnd) return;
  
    this.isProcessing = true;
    this.checkArrowStates();
  
    const step = this.calcStep();
    const newScrollLeft = this.reviewsContainer.nativeElement.scrollLeft + step;
  
    this.reviewsContainer.nativeElement.scrollTo({
      left: Math.round(newScrollLeft),
      behavior: 'smooth'
    });
  
    setTimeout(() => {
      this.checkArrowStates();
      this.isProcessing = false;
    }, 500);
  }

  checkArrowStates() {
    const scrollLeft = this.reviewsContainer.nativeElement.scrollLeft;
    const scrollWidth = this.reviewsContainer.nativeElement.scrollWidth;
    const clientWidth = this.reviewsContainer.nativeElement.clientWidth;
  
    this.isAtStart = scrollLeft <= 10;
    this.isAtEnd = scrollLeft + clientWidth >= scrollWidth - 10;
    this.cdRef.detectChanges();
  }

  calcStep() {
    let reviewsDisplayCount = 1;
    if (this.reviewsContainer.nativeElement.clientWidth > 670) {
      reviewsDisplayCount = 3;
    }

    const margin = reviewsDisplayCount * 10;
    const reviewSize =
      (this.reviewsContainer.nativeElement.clientWidth - margin) /
      reviewsDisplayCount;
    const step = reviewSize;
    return step;
  }
}
