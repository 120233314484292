<div
  *ngIf="
    data.date.isBefore(localTime, 'day') && !data.date.isAfter(from, 'hour')
  "
  class="table-cell previous">
  <span
    *ngIf="data.date.month() !== localTime.month() && data.date.date() === 1"
    class="day-number"
    [ngClass]="
      data.date.weekday() === 6 || data.date.weekday() === 0 ? 'bold ' : ''
    "
    >{{ data.date | amDateFormat: 'MMM' }}
    <span
      [ngClass]="
        data.date.weekday() === 6 || data.date.weekday() === 0 ? 'bold ' : ''
      "
      >{{ data.date | amDateFormat: 'DD' }}</span
    >
  </span>
  <span
    *ngIf="data.date.month() !== localTime.month() && data.date.date() !== 1"
    [ngClass]="
      data.date.weekday() === 6 || data.date.weekday() === 0 ? 'bold ' : ''
    "
    class="day-number"
    >{{ data.date | amDateFormat: 'DD' }}</span
  >
  <span
    *ngIf="data.date.month() === localTime.month() && data.date.date() === 1"
    class="day-number"
    [ngClass]="
      data.date.weekday() === 6 || data.date.weekday() === 0 ? 'bold ' : ''
    "
    >{{ data.date | amDateFormat: 'MMM' }}
    <span
      [ngClass]="
        data.date.weekday() === 6 || data.date.weekday() === 0 ? 'bold ' : ''
      "
      >{{ data.date | amDateFormat: 'DD' }}</span
    >
  </span>
  <span
    *ngIf="data.date.month() === localTime.month() && data.date.date() !== 1"
    [ngClass]="
      data.date.weekday() === 6 || data.date.weekday() === 0 ? 'bold ' : ''
    "
    class="day-number"
    >{{ data.date | amDateFormat: 'DD' }}</span
  >
</div>

<!-- if it is today start-->

<div
  *ngIf="!data.isStartDate && data.date.isSame(localTime, 'day')"
  class="table-cell not-departure">
  <div class="date-with-day-name">
    <span
      *ngIf="data.date.date() === 1"
      class="day-number"
      [ngClass]="
        data.date.weekday() === 6 || data.date.weekday() === 0 ? 'bold ' : ''
      "
      >{{ data.date | amDateFormat: 'MMM' }}
      <span
        [ngClass]="
          data.date.weekday() === 6 || data.date.weekday() === 0 ? 'bold ' : ''
        "
        >{{ data.date | amDateFormat: 'DD' }}</span
      >
    </span>
    <span *ngIf="data.date.date() !== 1" class="day-number">
      <span
        [ngClass]="
          data.date.weekday() === 6 || data.date.weekday() === 0 ? 'bold ' : ''
        "
        >{{ data.date | amDateFormat: 'DD' }}</span
      >
    </span>
  </div>
  <span class="today">Today</span>
</div>
<!-- if it is today end-->

<!-- if it the start date (departure date) start-->
<div
  *ngIf="data.isStartDate && data.date.isAfter(localTime)"
  class="table-cell departure"
  [ngClass]="{ travel: highlighted }">
  <div class="date-with-day-name">
    <img
      class="departure departure-plane"
      src="assets/img/calendar/departure.svg"
      alt="img" />
    <span
      *ngIf="data.date.month() !== localTime.month() && data.date.date() === 1"
      class="day-number"
      [ngClass]="
        data.date.weekday() === 6 || data.date.weekday() === 0 ? 'bold ' : ''
      "
      >{{ data.date | amDateFormat: 'MMM' }}
      <span
        [ngClass]="
          data.date.weekday() === 6 || data.date.weekday() === 0 ? 'bold ' : ''
        "
        >{{ data.date | amDateFormat: 'DD' }}</span
      >
    </span>
    <span
      *ngIf="data.date.month() !== localTime.month() && data.date.date() !== 1"
      [ngClass]="
        data.date.weekday() === 6 || data.date.weekday() === 0 ? 'bold ' : ''
      "
      class="day-number"
      >{{ data.date | amDateFormat: 'DD' }}</span
    >
    <span
      *ngIf="data.date.month() === localTime.month() && data.date.date() === 1"
      class="day-number"
      [ngClass]="
        data.date.weekday() === 6 || data.date.weekday() === 0 ? 'bold ' : ''
      "
      >{{ data.date | amDateFormat: 'MMM' }}
      <span
        [ngClass]="
          data.date.weekday() === 6 || data.date.weekday() === 0 ? 'bold ' : ''
        "
        >{{ data.date | amDateFormat: 'DD' }}</span
      >
    </span>
    <span
      *ngIf="data.date.month() === localTime.month() && data.date.date() !== 1"
      [ngClass]="
        data.date.weekday() === 6 || data.date.weekday() === 0 ? 'bold ' : ''
      "
      class="day-number"
      >{{ data.date | amDateFormat: 'DD' }}</span
    >
  </div>
  <span *ngIf="isTomorrow()" class="today">Tomorrow</span>
  <span *ngIf="data.date.date() - localTime.date() === 0" class="today"
    >Today</span
  >
</div>
<!-- if it the start date (departure date) end-->

<!-- if there is available flight start-->
<div
  *ngIf="
    !data.isStartDate &&
    data.date.isAfter(from) &&
    data.isAvailable &&
    data.isLoaded
  "
  (click)="selectDate(data)"
  (mouseenter)="mouseEnter($event)"
  (mouseleave)="mouseLeave($event)"
  class="table-cell offer lowest-price transition-all"
  [ngClass]="{ travel: highlighted, active: data.isReturnDate }">
  <span
    *ngIf="data.date.month() !== localTime.month() && data.date.date() === 1"
    class="day-number"
    [ngClass]="
      data.date.weekday() === 6 || data.date.weekday() === 0 ? 'bold ' : ''
    "
    >{{ data.date | amDateFormat: 'MMM' }}
    <span
      [ngClass]="
        data.date.weekday() === 6 || data.date.weekday() === 0 ? 'bold ' : ''
      "
      >{{ data.date | amDateFormat: 'DD' }}</span
    >
  </span>
  <span
    *ngIf="data.date.month() !== localTime.month() && data.date.date() !== 1"
    [ngClass]="
      data.date.weekday() === 6 || data.date.weekday() === 0 ? 'bold ' : ''
    "
    class="day-number"
    >{{ data.date | amDateFormat: 'DD' }}</span
  >
  <span
    *ngIf="data.date.month() === localTime.month() && data.date.date() === 1"
    class="day-number"
    [ngClass]="
      data.date.weekday() === 6 || data.date.weekday() === 0 ? 'bold ' : ''
    "
    >{{ data.date | amDateFormat: 'MMM' }}
    <span
      [ngClass]="
        data.date.weekday() === 6 || data.date.weekday() === 0 ? 'bold ' : ''
      "
      >{{ data.date | amDateFormat: 'DD' }}</span
    >
  </span>
  <span
    *ngIf="data.date.month() === localTime.month() && data.date.date() !== 1"
    [ngClass]="
      data.date.weekday() === 6 || data.date.weekday() === 0 ? 'bold ' : ''
    "
    class="day-number"
    >{{ data.date | amDateFormat: 'DD' }}</span
  >
  <p [ngClass]="{ otherOutbound: data.isOtherOutbound }">
    <span class="from" [ngClass]="{ hide: !showFromText }">from</span>
    £{{ data.price | priceRound }}
    <span class="round">return</span>
  </p>
  <img
    *ngIf="!data.isReturnDate"
    class="return rotate"
    src="assets/img/calendar/return.svg"
    alt="img" />
  <img
    *ngIf="data.isReturnDate"
    class="return rotate"
    src="assets/img/calendar/departure.svg"
    alt="img" />
  <span *ngIf="data.isLowestPrice" class="bottom-text lowest green"
    >lowest <span class="for-desktop"> price</span></span
  >
</div>
<!-- if there is available flight end-->

<!-- if there is not available flight start-->
<div
  *ngIf="
    (!data.isStartDate &&
      !data.isAvailable &&
      data.isLoaded &&
      data.date.isAfter(localTime)) ||
    (!data.isStartDate &&
      !data.isLoaded &&
      data.date.isAfter(localTime) &&
      !data.date.isSame(localTime, 'day'))
  "
  class="table-cell no transition-all"
  [ngClass]="{ travel: highlighted }">
  <span
    *ngIf="data.date.month() !== localTime.month() && data.date.date() === 1"
    class="day-number"
    [ngClass]="
      data.date.weekday() === 6 || data.date.weekday() === 0 ? 'bold ' : ''
    ">
    {{ data.date | amDateFormat: 'MMM' }}
    <span
      [ngClass]="
        data.date.weekday() === 6 || data.date.weekday() === 0 ? 'bold ' : ''
      "
      >{{ data.date | amDateFormat: 'DD' }}</span
    >
  </span>
  <span
    *ngIf="data.date.month() !== localTime.month() && data.date.date() !== 1"
    [ngClass]="
      data.date.weekday() === 6 || data.date.weekday() === 0 ? 'bold ' : ''
    "
    class="day-number"
    >{{ data.date | amDateFormat: 'DD' }}</span
  >
  <span
    *ngIf="data.date.month() === localTime.month() && data.date.date() === 1"
    class="day-number"
    [ngClass]="
      data.date.weekday() === 6 || data.date.weekday() === 0 ? 'bold ' : ''
    ">
    {{ data.date | amDateFormat: 'MMM' }}
    <span
      [ngClass]="
        data.date.weekday() === 6 || data.date.weekday() === 0 ? 'bold ' : ''
      "
      >{{ data.date | amDateFormat: 'DD' }}</span
    >
  </span>
  <span
    *ngIf="data.date.month() === localTime.month() && data.date.date() !== 1"
    [ngClass]="
      data.date.weekday() === 6 || data.date.weekday() === 0 ? 'bold ' : ''
    "
    class="day-number"
    >{{ data.date | amDateFormat: 'DD' }}</span
  >
  <span *ngIf="isTomorrow()" class="today tomorrow">Tomorrow</span>
  <img
    *ngIf="!isTomorrow()"
    class="no"
    src="assets/img/calendar/no.png"
    alt="img" />
</div>
