import { Component } from '@angular/core';

@Component({
  selector: 'app-our-advantages',
  templateUrl: './our-advantages.component.html',
  styleUrls: ['./our-advantages.component.scss']
})
export class OurAdvantagesComponent {
  constructor() {}
}
