<div #global class="fake-right-bar right-bar right sticky-right-bar"></div>
<div
  *ngIf="flight"
  #box
  [ngClass]="{
    'fixed-box': fixedBox && currentWindowWith > tabletWithSize,
    'left-zero': currentWindowWith <= tabletWithSize
  }"
  class="right-bar right sticky-right-bar">
  <div (click)="global.showBasket = !global.showBasket">
    <div class="expiration-date for-mobile">
      <ng-container [ngTemplateOutlet]="exp_date"></ng-container>
    </div>
    <span
      *ngIf="currentWindowWith <= tabletWithSize && global.showBasket"
      class="details">
      Hide Details
    </span>
    <span
      *ngIf="currentWindowWith <= tabletWithSize && !global.showBasket"
      class="details">
      Show Details
    </span>
    <div class="n-toggle-icon" [ngClass]="global.showBasket ? 'rotate' : ''">
      <i class="flaticon-agree yellow bold"></i>
    </div>
    <div
      *ngIf="flight.returnTickets && flight.returnTickets.length"
      class="price-mobile">
      <p class="current" *ngIf="!passengerPage && !paymentPage">
        <span class="not-bold">(</span
        ><app-price [value]="flight.price"></app-price
        ><span class="not-bold">)</span>
      </p>

      <p
        class="current"
        *ngIf="
          (passengerPage || paymentPage) && dataOfpassengerDetails !== undefined
        ">
        <span class="not-bold">(</span
        ><app-price [value]="dataOfpassengerDetails.grandTotal"></app-price
        ><span class="not-bold">)</span>
      </p>
      <p
        class="current"
        *ngIf="
          (passengerPage || paymentPage) && dataOfpassengerDetails === undefined
        ">
        Loading ...
      </p>
    </div>
    <div class="main-header">
      <img alt="img" class="icon basket" src="assets/img/icons/basket.png" />
      <h4>
        <span>My basket</span>
      </h4>
    </div>
  </div>
  <!-- content -->
  <app-basket-flight
    [flight]="flight"
    [passengerPage]="passengerPage"
    [paymentPage]="paymentPage"
    [totalDuration]="totalDuration"
    [totalDurationOfReturnFlights]="totalDurationOfReturnFlights"
    [isOneWay]="isOneWay"
    [conditionsVisible]="conditionsVisible"
    (showBreakdownDetailsOutput)="global.showBreakdown = !global.showBreakdown"
    (showFlightDetailsOutput)="global.showDetails = !global.showDetails"
    (showConditions)="showFareRules($event)"
    *ngIf="currentWindowWith > tabletWithSize">
  </app-basket-flight>
  <div
    [@toggleAnimation]
    *ngIf="currentWindowWith <= tabletWithSize && global.showBasket">
    <app-basket-flight
      [flight]="flight"
      [passengerPage]="passengerPage"
      (showBreakdownDetailsOutput)="
        global.showBreakdown = !global.showBreakdown
      "
      [paymentPage]="paymentPage"
      [totalDuration]="totalDuration"
      [isOneWay]="isOneWay"
      [conditionsVisible]="conditionsVisible"
      [totalDurationOfReturnFlights]="totalDurationOfReturnFlights"
      (showConditions)="showFareRules($event)"
      (showFlightDetailsOutput)="
        global.showDetails = !global.showDetails
      "></app-basket-flight>
  </div>
</div>

<!-- FLIGHT DETAILS -->
<div
  *ngIf="flight && global.showDetails"
  [@toggleAnimation]
  class="flight-details-box">
  <div class="flight-details-box-wrapp">
    <h4 class="box-title">
      <span class="title">Flight Details:</span>
      <span>
        {{ flight.outboundTickets[0].departure.code }}
        <img
          *ngIf="!isOneWay"
          src="assets/img/icons/left-and-right-arrows-dark.svg"
          alt="img"
          class="right-arrow" />
        <img
          *ngIf="isOneWay"
          src="assets/img/icons/right-arrow-dark.svg"
          alt="img"
          class="right-arrow" />
        {{
          flight.outboundTickets[flight.outboundTickets.length - 1].arrival.code
        }}
        <span *ngIf="isOneWay" class="return">, one way</span>
        <span *ngIf="!isOneWay" class="return">, return</span>
      </span>
      <button
        (click)="global.showDetails = !global.showDetails"
        class="transition-all close-button">
        <img alt="" src="../../../assets/img/icons/cross-dark-gray.svg" />
      </button>
    </h4>

    <div class="flight-details-box-content">
      <app-flight-details
        *ngIf="flight"
        [flight]="flight"
        type="outbound"
        [highlightDepartureArivalDiff]="
          flight && flight.returnTickets && flight.returnTickets.length
        "></app-flight-details>
      <div
        *ngIf="flight && flight.returnTickets && flight.returnTickets.length"
        class="divider"></div>
      <app-flight-details
        *ngIf="flight && flight.returnTickets && flight.returnTickets.length"
        [flight]="flight"
        [highlightDepartureArivalDiff]="
          flight && flight.returnTickets && flight.returnTickets.length
        "
        type="return"></app-flight-details>
      <div class="app-departure-arrival-diff">
        <app-departure-arrival-diff
          *ngIf="flight && flight.returnTickets && flight.returnTickets.length"
          [flight]="flight"></app-departure-arrival-diff>
      </div>
    </div>
  </div>
</div>

<!-- BreakDown -->
<div
  [@toggleAnimation]
  appLoading
  [loading]="!dataOfpassengerDetails"
  *ngIf="global.showBreakdown"
  id="flight-breakdown"
  class="flight-details-box">
  <div class="flight-details-box-wrapp">
    <h4 class="box-title">
      Flight Cost Details
      <button
        (click)="global.showBreakdown = !global.showBreakdown"
        id="hide-breakdown"
        class="transition-all close-button">
        <img alt="" src="../../../assets/img/icons/cross-dark-gray.svg" />
      </button>
    </h4>

    <div
      *ngIf="dataOfpassengerDetails"
      class="flight-details-box-content price-breakdown desktop-version">
      <div class="row" [ngSwitch]="typeofTable">
        <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 col type">
          <h5>Pax Type</h5>
          <p
            *ngIf="
              dataOfpassengerDetails && dataOfpassengerDetails.adult.count >= 1
            ">
            Adult
          </p>
          <p
            *ngIf="
              dataOfpassengerDetails && dataOfpassengerDetails.child.count > 0
            ">
            Child
          </p>
          <p
            *ngIf="
              dataOfpassengerDetails && dataOfpassengerDetails.infant.count > 0
            ">
            Infant
          </p>
        </div>

        <div *ngSwitchCase="1">
          <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3 col fare">
            <h5>
              Fare
              <span>(p.p.)</span>
            </h5>
            <p
              *ngIf="
                dataOfpassengerDetails && dataOfpassengerDetails.adult.fare >= 0
              ">
              <app-price
                [value]="dataOfpassengerDetails.adult.fare"
                [color_of_fraction]="'var(--dark-gray)'"></app-price>
            </p>
            <p
              *ngIf="
                dataOfpassengerDetails && dataOfpassengerDetails.child.fare >= 0
              ">
              <app-price
                [value]="dataOfpassengerDetails.child.fare"
                [color_of_fraction]="'var(--dark-gray)'"></app-price>
            </p>
            <p
              *ngIf="
                dataOfpassengerDetails &&
                dataOfpassengerDetails.infant.fare >= 0
              ">
              <app-price
                [value]="dataOfpassengerDetails.infant.fare"
                [color_of_fraction]="'var(--dark-gray)'"></app-price>
            </p>
          </div>
          <div class="col-lg-3 col-md-3 col-sm-3 col-xs-4 col taxes">
            <h5>
              Taxes
              <span>(p.p.)</span>
            </h5>
            <p
              *ngIf="
                dataOfpassengerDetails &&
                dataOfpassengerDetails.adult.taxes >= 0
              ">
              <app-price
                [value]="dataOfpassengerDetails.adult.taxes"
                [color_of_fraction]="'var(--dark-gray)'"></app-price>
            </p>
            <p
              *ngIf="
                dataOfpassengerDetails &&
                dataOfpassengerDetails.child.taxes >= 0
              ">
              <app-price
                [value]="dataOfpassengerDetails.child.taxes"
                [color_of_fraction]="'var(--dark-gray)'"></app-price>
            </p>
            <p
              *ngIf="
                dataOfpassengerDetails &&
                dataOfpassengerDetails.infant.taxes >= 0
              ">
              <app-price
                [value]="dataOfpassengerDetails.infant.taxes"
                [color_of_fraction]="'var(--dark-gray)'"></app-price>
            </p>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-5 col-xs-5 col services">
            <h5 class="mobile">
              Disc
              <span>(p.p.)</span>
            </h5>
            <h5 class="desktop">
              Discount
              <span>(p.p.)</span>
            </h5>
            <p
              *ngIf="
                dataOfpassengerDetails &&
                (dataOfpassengerDetails.adult.discount >= 0 ||
                  dataOfpassengerDetails.adult.discount < 0)
              ">
              <app-price
                [value]="dataOfpassengerDetails.adult.discount"
                [color_of_fraction]="'var(--dark-gray)'"></app-price>
            </p>
            <p
              *ngIf="
                dataOfpassengerDetails &&
                (dataOfpassengerDetails.child.discount >= 0 ||
                  dataOfpassengerDetails.child.discount < 0)
              ">
              <app-price
                [value]="dataOfpassengerDetails.child.discount"
                [color_of_fraction]="'var(--dark-gray)'"></app-price>
            </p>
            <p
              *ngIf="
                dataOfpassengerDetails &&
                (dataOfpassengerDetails.infant.discount >= 0 ||
                  dataOfpassengerDetails.infant.discount < 0)
              ">
              <app-price
                [value]="dataOfpassengerDetails.infant.discount"
                [color_of_fraction]="'var(--dark-gray)'"></app-price>
            </p>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-5 col-xs-4 col total">
            <h5>
              Subtotal
              <span>(p.p.)</span>
            </h5>
            <p
              *ngIf="
                dataOfpassengerDetails &&
                dataOfpassengerDetails.adult.subTotal >= 0
              ">
              <app-price
                [value]="dataOfpassengerDetails.adult.subTotal"
                [color_of_fraction]="'var(--dark-gray)'"></app-price>
            </p>
            <p
              *ngIf="
                dataOfpassengerDetails &&
                dataOfpassengerDetails.child.subTotal >= 0
              ">
              <app-price
                [value]="dataOfpassengerDetails.child.subTotal"
                [color_of_fraction]="'var(--dark-gray)'"></app-price>
            </p>
            <p
              *ngIf="
                dataOfpassengerDetails &&
                dataOfpassengerDetails.infant.subTotal >= 0
              ">
              <app-price
                [value]="dataOfpassengerDetails.infant.subTotal"
                [color_of_fraction]="'var(--dark-gray)'"></app-price>
            </p>
          </div>
          <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3 col number">
            <h5>Pax No.</h5>
            <p
              *ngIf="
                dataOfpassengerDetails && dataOfpassengerDetails.adult.count
              ">
              {{ dataOfpassengerDetails.adult.count }}
            </p>
            <p
              *ngIf="
                dataOfpassengerDetails && dataOfpassengerDetails.child.count
              ">
              {{ dataOfpassengerDetails.child.count }}
            </p>
            <p
              *ngIf="
                dataOfpassengerDetails && dataOfpassengerDetails.infant.count
              ">
              {{ dataOfpassengerDetails.infant.count }}
            </p>
          </div>
          <div
            class="col-lg-5 col-md-5 col-sm-3 col-xs-3 col subtotal"
            [ngClass]="{ 'total-bold': mltPassangers !== true }">
            <h5>Total</h5>
            <p
              *ngIf="
                dataOfpassengerDetails &&
                dataOfpassengerDetails.adult.total >= 0
              ">
              <app-price
                [value]="dataOfpassengerDetails.adult.total"
                [color_of_fraction]="'var(--dark-gray)'"></app-price>
            </p>
            <p
              *ngIf="
                dataOfpassengerDetails &&
                dataOfpassengerDetails.child.total >= 0
              ">
              <app-price
                [value]="dataOfpassengerDetails.child.total"
                [color_of_fraction]="'var(--dark-gray)'"></app-price>
            </p>
            <p
              *ngIf="
                dataOfpassengerDetails &&
                dataOfpassengerDetails.infant.total >= 0
              ">
              <app-price
                [value]="dataOfpassengerDetails.infant.total"
                [color_of_fraction]="'var(--dark-gray)'"></app-price>
            </p>
          </div>
        </div>

        <div *ngSwitchCase="2">
          <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3 col fare">
            <h5>
              Fare
              <span>(p.p.)</span>
            </h5>
            <p
              *ngIf="
                dataOfpassengerDetails && dataOfpassengerDetails.adult.fare >= 0
              ">
              <app-price
                [value]="dataOfpassengerDetails.adult.fare"
                [color_of_fraction]="'var(--dark-gray)'"></app-price>
            </p>
            <p
              *ngIf="
                dataOfpassengerDetails && dataOfpassengerDetails.child.fare >= 0
              ">
              <app-price
                [value]="dataOfpassengerDetails.child.fare"
                [color_of_fraction]="'var(--dark-gray)'"></app-price>
            </p>
            <p
              *ngIf="
                dataOfpassengerDetails &&
                dataOfpassengerDetails.infant.fare >= 0
              ">
              <app-price
                [value]="dataOfpassengerDetails.infant.fare"
                [color_of_fraction]="'var(--dark-gray)'"></app-price>
            </p>
          </div>
          <div class="col-lg-3 col-md-3 col-sm-3 col-xs-4 col taxes">
            <h5>
              Taxes
              <span>(p.p.)</span>
            </h5>
            <p
              *ngIf="
                dataOfpassengerDetails &&
                dataOfpassengerDetails.adult.taxes >= 0
              ">
              <app-price
                [value]="dataOfpassengerDetails.adult.taxes"
                [color_of_fraction]="'var(--dark-gray)'"></app-price>
            </p>
            <p
              *ngIf="
                dataOfpassengerDetails &&
                dataOfpassengerDetails.child.taxes >= 0
              ">
              <app-price
                [value]="dataOfpassengerDetails.child.taxes"
                [color_of_fraction]="'var(--dark-gray)'"></app-price>
            </p>
            <p
              *ngIf="
                dataOfpassengerDetails &&
                dataOfpassengerDetails.infant.taxes >= 0
              ">
              <app-price
                [value]="dataOfpassengerDetails.infant.taxes"
                [color_of_fraction]="'var(--dark-gray)'"></app-price>
            </p>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-5 col-xs-5 col services">
            <h5 class="mobile">
              Fee
              <span>(p.p.)</span>
            </h5>
            <h5 class="desktop">
              Service Fee
              <span>(p.p.)</span>
            </h5>
            <p
              *ngIf="
                dataOfpassengerDetails &&
                (dataOfpassengerDetails.adult.serviceFee >= 0 ||
                  dataOfpassengerDetails.adult.serviceFee < 0)
              ">
              <app-price
                [value]="dataOfpassengerDetails.adult.serviceFee"
                [color_of_fraction]="'var(--dark-gray)'"></app-price>
            </p>
            <p
              *ngIf="
                dataOfpassengerDetails &&
                (dataOfpassengerDetails.child.serviceFee >= 0 ||
                  dataOfpassengerDetails.child.serviceFee < 0)
              ">
              <app-price
                [value]="dataOfpassengerDetails.child.serviceFee"
                [color_of_fraction]="'var(--dark-gray)'"></app-price>
            </p>
            <p
              *ngIf="
                dataOfpassengerDetails &&
                (dataOfpassengerDetails.infant.serviceFee >= 0 ||
                  dataOfpassengerDetails.infant.serviceFee < 0)
              ">
              <app-price
                [value]="dataOfpassengerDetails.infant.serviceFee"
                [color_of_fraction]="'var(--dark-gray)'"></app-price>
            </p>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-5 col-xs-4 col total">
            <h5>
              Subtotal
              <span>(p.p.)</span>
            </h5>
            <p
              *ngIf="
                dataOfpassengerDetails &&
                dataOfpassengerDetails.adult.subTotal >= 0
              ">
              <app-price
                [value]="dataOfpassengerDetails.adult.subTotal"
                [color_of_fraction]="'var(--dark-gray)'"></app-price>
            </p>
            <p
              *ngIf="
                dataOfpassengerDetails &&
                dataOfpassengerDetails.child.subTotal >= 0
              ">
              <app-price
                [value]="dataOfpassengerDetails.child.subTotal"
                [color_of_fraction]="'var(--dark-gray)'"></app-price>
            </p>
            <p
              *ngIf="
                dataOfpassengerDetails &&
                dataOfpassengerDetails.infant.subTotal >= 0
              ">
              <app-price
                [value]="dataOfpassengerDetails.infant.subTotal"
                [color_of_fraction]="'var(--dark-gray)'"></app-price>
            </p>
          </div>
          <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3 col number">
            <h5>Pax No.</h5>
            <p
              *ngIf="
                dataOfpassengerDetails && dataOfpassengerDetails.adult.count
              ">
              {{ dataOfpassengerDetails.adult.count }}
            </p>
            <p
              *ngIf="
                dataOfpassengerDetails && dataOfpassengerDetails.child.count
              ">
              {{ dataOfpassengerDetails.child.count }}
            </p>
            <p
              *ngIf="
                dataOfpassengerDetails && dataOfpassengerDetails.infant.count
              ">
              {{ dataOfpassengerDetails.infant.count }}
            </p>
          </div>
          <div
            class="col-lg-5 col-md-5 col-sm-3 col-xs-3 col subtotal"
            [ngClass]="{ 'total-bold': mltPassangers !== true }">
            <h5>Total</h5>
            <p
              *ngIf="
                dataOfpassengerDetails &&
                dataOfpassengerDetails.adult.total >= 0
              ">
              <app-price
                [value]="dataOfpassengerDetails.adult.total"
                [color_of_fraction]="'var(--dark-gray)'"></app-price>
            </p>
            <p
              *ngIf="
                dataOfpassengerDetails &&
                dataOfpassengerDetails.child.total >= 0
              ">
              <app-price
                [value]="dataOfpassengerDetails.child.total"
                [color_of_fraction]="'var(--dark-gray)'"></app-price>
            </p>
            <p
              *ngIf="
                dataOfpassengerDetails &&
                dataOfpassengerDetails.infant.total >= 0
              ">
              <app-price
                [value]="dataOfpassengerDetails.infant.total"
                [color_of_fraction]="'var(--dark-gray)'"></app-price>
            </p>
          </div>
        </div>
        <div *ngSwitchCase="3">
          <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 col fare">
            <h5>
              Price
              <span>(p.p.)</span>
            </h5>
            <p
              *ngIf="
                dataOfpassengerDetails &&
                dataOfpassengerDetails.adult.price >= 0
              ">
              <app-price
                [value]="dataOfpassengerDetails.adult.price"
                [color_of_fraction]="'var(--dark-gray)'"></app-price>
            </p>
            <p
              *ngIf="
                dataOfpassengerDetails &&
                dataOfpassengerDetails.child.price >= 0
              ">
              <app-price
                [value]="dataOfpassengerDetails.child.price"
                [color_of_fraction]="'var(--dark-gray)'"></app-price>
            </p>
            <p
              *ngIf="
                dataOfpassengerDetails &&
                dataOfpassengerDetails.infant.price >= 0
              ">
              <app-price
                [value]="dataOfpassengerDetails.infant.price"
                [color_of_fraction]="'var(--dark-gray)'"></app-price>
            </p>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 col taxes">
            <h5>
              Taxes
              <span>(p.p.)</span>
            </h5>
            <p
              *ngIf="
                dataOfpassengerDetails &&
                dataOfpassengerDetails.adult.taxes >= 0
              ">
              <app-price
                [value]="dataOfpassengerDetails.adult.taxes"
                [color_of_fraction]="'var(--dark-gray)'"></app-price>
            </p>
            <p
              *ngIf="
                dataOfpassengerDetails &&
                dataOfpassengerDetails.child.taxes >= 0
              ">
              <app-price
                [value]="dataOfpassengerDetails.child.taxes"
                [color_of_fraction]="'var(--dark-gray)'"></app-price>
            </p>
            <p
              *ngIf="
                dataOfpassengerDetails &&
                dataOfpassengerDetails.infant.taxes >= 0
              ">
              <app-price
                [value]="dataOfpassengerDetails.infant.taxes"
                [color_of_fraction]="'var(--dark-gray)'"></app-price>
            </p>
          </div>
          <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 col"></div>
          <div class="col-lg-4 col-md-4 col-sm-5 col-xs-5 col total">
            <h5>
              Subtotal
              <span>(p.p.)</span>
            </h5>
            <p
              *ngIf="
                dataOfpassengerDetails &&
                dataOfpassengerDetails.adult.subTotal >= 0
              ">
              <app-price
                [value]="dataOfpassengerDetails.adult.subTotal"
                [color_of_fraction]="'var(--dark-gray)'"></app-price>
            </p>
            <p
              *ngIf="
                dataOfpassengerDetails &&
                dataOfpassengerDetails.child.subTotal >= 0
              ">
              <app-price
                [value]="dataOfpassengerDetails.child.subTotal"
                [color_of_fraction]="'var(--dark-gray)'"></app-price>
            </p>
            <p
              *ngIf="
                dataOfpassengerDetails &&
                dataOfpassengerDetails.infant.subTotal >= 0
              ">
              <app-price
                [value]="dataOfpassengerDetails.infant.subTotal"
                [color_of_fraction]="'var(--dark-gray)'"></app-price>
            </p>
          </div>
          <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3 col number">
            <h5>Pax No.</h5>
            <p
              *ngIf="
                dataOfpassengerDetails && dataOfpassengerDetails.adult.count
              ">
              {{ dataOfpassengerDetails.adult.count }}
            </p>
            <p
              *ngIf="
                dataOfpassengerDetails && dataOfpassengerDetails.child.count
              ">
              {{ dataOfpassengerDetails.child.count }}
            </p>
            <p
              *ngIf="
                dataOfpassengerDetails && dataOfpassengerDetails.infant.count
              ">
              {{ dataOfpassengerDetails.infant.count }}
            </p>
          </div>
          <div
            class="col-lg-5 col-md-5 col-sm-4 col-xs-4 col subtotal"
            [ngClass]="{ 'total-bold': mltPassangers !== true }">
            <h5>Total</h5>
            <p
              *ngIf="
                dataOfpassengerDetails &&
                dataOfpassengerDetails.adult.total >= 0
              ">
              <app-price
                [value]="dataOfpassengerDetails.adult.total"
                [color_of_fraction]="'var(--dark-gray)'"></app-price>
            </p>
            <p
              *ngIf="
                dataOfpassengerDetails &&
                dataOfpassengerDetails.child.total >= 0
              ">
              <app-price
                [value]="dataOfpassengerDetails.child.total"
                [color_of_fraction]="'var(--dark-gray)'"></app-price>
            </p>
            <p
              *ngIf="
                dataOfpassengerDetails &&
                dataOfpassengerDetails.infant.total >= 0
              ">
              <app-price
                [value]="dataOfpassengerDetails.infant.total"
                [color_of_fraction]="'var(--dark-gray)'"></app-price>
            </p>
          </div>
        </div>
      </div>

      <!-- total -->
      <div class="row total-cost">
        <div class="col-lg-16 col-md-16 col-sm-16 col-xs-16 col info"></div>
        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 col cost">
          <p *ngIf="mltPassangers === true">
            Grand Total:
            <span *ngIf="dataOfpassengerDetails">
              <app-price
                [value]="dataOfpassengerDetails.grandTotal"
                [color_of_fraction]="'var(--dark-gray)'"></app-price>
            </span>
          </p>
        </div>
      </div>
    </div>
    <div
      *ngIf="dataOfpassengerDetails"
      class="flight-details-box-content price-breakdown mobile-version">
      <table
        [ngSwitch]="typeofTable"
        [ngClass]="{ allTypeOfPassangers: allTypeOfPassangers === true }">
        <thead>
          <tr class="border_bottom">
            <th
              *ngIf="
                dataOfpassengerDetails &&
                dataOfpassengerDetails.adult.count >= 1
              ">
              Pax Type
            </th>
            <th
              *ngIf="
                dataOfpassengerDetails &&
                dataOfpassengerDetails.adult.count >= 1
              ">
              Adult
            </th>
            <th
              *ngIf="
                dataOfpassengerDetails && dataOfpassengerDetails.child.count > 0
              ">
              Child
            </th>
            <th
              *ngIf="
                dataOfpassengerDetails &&
                dataOfpassengerDetails.infant.count > 0
              ">
              Infant
            </th>
          </tr>
        </thead>
        <tbody
          *ngSwitchCase="1"
          [ngClass]="mltPassangers !== true ? 'two_column_table' : ''">
          <tr>
            <th>
              Fare
              <span class="none-bold"> (p.p)</span>
            </th>
            <td
              *ngIf="
                dataOfpassengerDetails && dataOfpassengerDetails.adult.fare >= 0
              ">
              <app-price
                [value]="dataOfpassengerDetails.adult.fare"
                [color_of_fraction]="'var(--dark-gray)'"></app-price>
            </td>
            <td
              *ngIf="
                dataOfpassengerDetails && dataOfpassengerDetails.child.fare >= 0
              ">
              <app-price
                [value]="dataOfpassengerDetails.child.fare"
                [color_of_fraction]="'var(--dark-gray)'"></app-price>
            </td>
            <td
              *ngIf="
                dataOfpassengerDetails &&
                dataOfpassengerDetails.infant.fare >= 0
              ">
              <app-price
                [value]="dataOfpassengerDetails.infant.fare"
                [color_of_fraction]="'var(--dark-gray)'"></app-price>
            </td>
          </tr>
          <tr>
            <th>Taxes <span class="none-bold"> (p.p)</span></th>
            <td
              *ngIf="
                dataOfpassengerDetails &&
                dataOfpassengerDetails.adult.taxes >= 0
              ">
              <app-price
                [value]="dataOfpassengerDetails.adult.taxes"
                [color_of_fraction]="'var(--dark-gray)'"></app-price>
            </td>
            <td
              *ngIf="
                dataOfpassengerDetails &&
                dataOfpassengerDetails.child.taxes >= 0
              ">
              <app-price
                [value]="dataOfpassengerDetails.child.taxes"
                [color_of_fraction]="'var(--dark-gray)'"></app-price>
            </td>
            <td
              *ngIf="
                dataOfpassengerDetails &&
                dataOfpassengerDetails.infant.taxes >= 0
              ">
              <app-price
                [value]="dataOfpassengerDetails.infant.taxes"
                [color_of_fraction]="'var(--dark-gray)'"></app-price>
            </td>
          </tr>
          <tr class="border_bottom">
            <th>Discount <span class="none-bold"> (p.p)</span></th>
            <td
              *ngIf="
                dataOfpassengerDetails &&
                (dataOfpassengerDetails.adult.discount >= 0 ||
                  dataOfpassengerDetails.adult.discount < 0)
              ">
              <app-price
                [value]="dataOfpassengerDetails.adult.discount"
                [color_of_fraction]="'var(--dark-gray)'"></app-price>
            </td>
            <td
              *ngIf="
                dataOfpassengerDetails &&
                (dataOfpassengerDetails.child.discount >= 0 ||
                  dataOfpassengerDetails.child.discount < 0)
              ">
              <app-price
                [value]="dataOfpassengerDetails.child.discount"
                [color_of_fraction]="'var(--dark-gray)'"></app-price>
            </td>
            <td
              *ngIf="
                dataOfpassengerDetails &&
                (dataOfpassengerDetails.infant.discount >= 0 ||
                  dataOfpassengerDetails.infant.discount < 0)
              ">
              <app-price
                [value]="dataOfpassengerDetails.infant.discount"
                [color_of_fraction]="'var(--dark-gray)'"></app-price>
            </td>
          </tr>
          <tr class="border_bottom">
            <th>Subtotal <span class="none-bold"> (p.p)</span></th>
            <td
              *ngIf="
                dataOfpassengerDetails &&
                dataOfpassengerDetails.adult.subTotal >= 0
              ">
              <app-price
                [value]="dataOfpassengerDetails.adult.subTotal"
                [color_of_fraction]="'var(--dark-gray)'"></app-price>
            </td>
            <td
              *ngIf="
                dataOfpassengerDetails &&
                dataOfpassengerDetails.child.subTotal >= 0
              ">
              <app-price
                [value]="dataOfpassengerDetails.child.subTotal"
                [color_of_fraction]="'var(--dark-gray)'"></app-price>
            </td>
            <td
              *ngIf="
                dataOfpassengerDetails &&
                dataOfpassengerDetails.infant.subTotal >= 0
              ">
              <app-price
                [value]="dataOfpassengerDetails.infant.subTotal"
                [color_of_fraction]="'var(--dark-gray)'"></app-price>
            </td>
          </tr>
          <tr class="border_bottom">
            <th>Pax No.</th>
            <td
              *ngIf="
                dataOfpassengerDetails && dataOfpassengerDetails.adult.count
              ">
              {{ dataOfpassengerDetails.adult.count }}
            </td>
            <td
              *ngIf="
                dataOfpassengerDetails && dataOfpassengerDetails.child.count
              ">
              {{ dataOfpassengerDetails.child.count }}
            </td>
            <td
              *ngIf="
                dataOfpassengerDetails && dataOfpassengerDetails.infant.count
              ">
              {{ dataOfpassengerDetails.infant.count }}
            </td>
          </tr>
          <tr [ngClass]="{ 'total-bold': mltPassangers !== true }">
            <th>Total</th>
            <td
              *ngIf="
                dataOfpassengerDetails &&
                dataOfpassengerDetails.adult.total >= 0
              ">
              <app-price
                [value]="dataOfpassengerDetails.adult.total"
                [color_of_fraction]="'var(--dark-gray)'"></app-price>
            </td>
            <td
              *ngIf="
                dataOfpassengerDetails &&
                dataOfpassengerDetails.child.total >= 0
              ">
              <app-price
                [value]="dataOfpassengerDetails.child.total"
                [color_of_fraction]="'var(--dark-gray)'"></app-price>
            </td>
            <td
              *ngIf="
                dataOfpassengerDetails &&
                dataOfpassengerDetails.infant.total >= 0
              ">
              <app-price
                [value]="dataOfpassengerDetails.infant.total"
                [color_of_fraction]="'var(--dark-gray)'"></app-price>
            </td>
            <td *ngIf="mltPassangers === true" class="grand-total">
              <p class="grand-total-text">Grand Total</p>
              <app-price
                [value]="dataOfpassengerDetails.grandTotal"
                [color_of_fraction]="'var(--dark-gray)'"></app-price>
            </td>
          </tr>
        </tbody>
        <tbody
          *ngSwitchCase="2"
          [ngClass]="mltPassangers !== true ? 'two_column_table' : ''">
          <tr>
            <th>
              Fare
              <span class="none-bold"> (p.p)</span>
            </th>
            <td
              *ngIf="
                dataOfpassengerDetails && dataOfpassengerDetails.adult.fare >= 0
              ">
              <app-price
                [value]="dataOfpassengerDetails.adult.fare"
                [color_of_fraction]="'var(--dark-gray)'"></app-price>
            </td>
            <td
              *ngIf="
                dataOfpassengerDetails && dataOfpassengerDetails.child.fare >= 0
              ">
              <app-price
                [value]="dataOfpassengerDetails.child.fare"
                [color_of_fraction]="'var(--dark-gray)'"></app-price>
            </td>
            <td
              *ngIf="
                dataOfpassengerDetails &&
                dataOfpassengerDetails.infant.fare >= 0
              ">
              <app-price
                [value]="dataOfpassengerDetails.infant.fare"
                [color_of_fraction]="'var(--dark-gray)'"></app-price>
            </td>
          </tr>
          <tr>
            <th>Taxes <span class="none-bold"> (p.p)</span></th>
            <td
              *ngIf="
                dataOfpassengerDetails &&
                dataOfpassengerDetails.adult.taxes >= 0
              ">
              <app-price
                [value]="dataOfpassengerDetails.adult.taxes"
                [color_of_fraction]="'var(--dark-gray)'"></app-price>
            </td>
            <td
              *ngIf="
                dataOfpassengerDetails &&
                dataOfpassengerDetails.child.taxes >= 0
              ">
              <app-price
                [value]="dataOfpassengerDetails.child.taxes"
                [color_of_fraction]="'var(--dark-gray)'"></app-price>
            </td>
            <td
              *ngIf="
                dataOfpassengerDetails &&
                dataOfpassengerDetails.infant.taxes >= 0
              ">
              <app-price
                [value]="dataOfpassengerDetails.infant.taxes"
                [color_of_fraction]="'var(--dark-gray)'"></app-price>
            </td>
          </tr>
          <tr class="border_bottom">
            <th [ngSwitch]="allTypeOfPassangers">
              <span *ngSwitchCase="false"
                >Service Fee <span class="none-bold"> (p.p)</span></span
              >
              <span *ngSwitchCase="true"
                >Fee <span class="none-bold"> (p.p)</span></span
              >
            </th>
            <td
              *ngIf="
                dataOfpassengerDetails &&
                (dataOfpassengerDetails.adult.serviceFee >= 0 ||
                  dataOfpassengerDetails.adult.serviceFee < 0)
              ">
              <app-price
                [value]="dataOfpassengerDetails.adult.serviceFee"
                [color_of_fraction]="'var(--dark-gray)'"></app-price>
            </td>
            <td
              *ngIf="
                dataOfpassengerDetails &&
                (dataOfpassengerDetails.child.serviceFee >= 0 ||
                  dataOfpassengerDetails.child.serviceFee < 0)
              ">
              <app-price
                [value]="dataOfpassengerDetails.child.serviceFee"
                [color_of_fraction]="'var(--dark-gray)'"></app-price>
            </td>
            <td
              *ngIf="
                dataOfpassengerDetails &&
                (dataOfpassengerDetails.infant.serviceFee >= 0 ||
                  dataOfpassengerDetails.infant.serviceFee < 0)
              ">
              <app-price
                [value]="dataOfpassengerDetails.infant.serviceFee"
                [color_of_fraction]="'var(--dark-gray)'"></app-price>
            </td>
          </tr>
          <tr class="border_bottom">
            <th>Subtotal <span class="none-bold"> (p.p)</span></th>
            <td
              *ngIf="
                dataOfpassengerDetails &&
                dataOfpassengerDetails.adult.subTotal >= 0
              ">
              <app-price
                [value]="dataOfpassengerDetails.adult.subTotal"
                [color_of_fraction]="'var(--dark-gray)'"></app-price>
            </td>
            <td
              *ngIf="
                dataOfpassengerDetails &&
                dataOfpassengerDetails.child.subTotal >= 0
              ">
              <app-price
                [value]="dataOfpassengerDetails.child.subTotal"
                [color_of_fraction]="'var(--dark-gray)'"></app-price>
            </td>
            <td
              *ngIf="
                dataOfpassengerDetails &&
                dataOfpassengerDetails.infant.subTotal >= 0
              ">
              <app-price
                [value]="dataOfpassengerDetails.infant.subTotal"
                [color_of_fraction]="'var(--dark-gray)'"></app-price>
            </td>
          </tr>
          <tr class="border_bottom">
            <th>Pax No.</th>
            <td
              *ngIf="
                dataOfpassengerDetails && dataOfpassengerDetails.adult.count
              ">
              {{ dataOfpassengerDetails.adult.count }}
            </td>
            <td
              *ngIf="
                dataOfpassengerDetails && dataOfpassengerDetails.child.count
              ">
              {{ dataOfpassengerDetails.child.count }}
            </td>
            <td
              *ngIf="
                dataOfpassengerDetails && dataOfpassengerDetails.infant.count
              ">
              {{ dataOfpassengerDetails.infant.count }}
            </td>
          </tr>
          <tr [ngClass]="{ 'total-bold': mltPassangers !== true }">
            <th>Total</th>
            <td
              *ngIf="
                dataOfpassengerDetails &&
                dataOfpassengerDetails.adult.total >= 0
              ">
              <app-price
                [value]="dataOfpassengerDetails.adult.total"
                [color_of_fraction]="'var(--dark-gray)'"></app-price>
            </td>
            <td
              *ngIf="
                dataOfpassengerDetails &&
                dataOfpassengerDetails.child.total >= 0
              ">
              <app-price
                [value]="dataOfpassengerDetails.child.total"
                [color_of_fraction]="'var(--dark-gray)'"></app-price>
            </td>
            <td
              *ngIf="
                dataOfpassengerDetails &&
                dataOfpassengerDetails.infant.total >= 0
              ">
              <app-price
                [value]="dataOfpassengerDetails.infant.total"
                [color_of_fraction]="'var(--dark-gray)'"></app-price>
            </td>
            <td *ngIf="mltPassangers === true" class="grand-total">
              <p class="grand-total-text">Grand Total</p>
              <app-price
                [value]="dataOfpassengerDetails.grandTotal"
                [color_of_fraction]="'var(--dark-gray)'"></app-price>
            </td>
          </tr>
        </tbody>
        <tbody
          *ngSwitchCase="3"
          [ngClass]="mltPassangers !== true ? 'two_column_table' : ''">
          <tr>
            <th>Price <span class="none-bold"> (p.p)</span></th>
            <td
              *ngIf="
                dataOfpassengerDetails &&
                dataOfpassengerDetails.adult.price >= 0
              ">
              <app-price
                [value]="dataOfpassengerDetails.adult.price"
                [color_of_fraction]="'var(--dark-gray)'"></app-price>
            </td>
            <td
              *ngIf="
                dataOfpassengerDetails &&
                dataOfpassengerDetails.child.price >= 0
              ">
              <app-price
                [value]="dataOfpassengerDetails.child.price"
                [color_of_fraction]="'var(--dark-gray)'"></app-price>
            </td>
            <td
              *ngIf="
                dataOfpassengerDetails &&
                dataOfpassengerDetails.infant.price >= 0
              ">
              <app-price
                [value]="dataOfpassengerDetails.infant.price"
                [color_of_fraction]="'var(--dark-gray)'"></app-price>
            </td>
          </tr>
          <tr class="border_bottom">
            <th>Taxes <span class="none-bold"> (p.p)</span></th>
            <td
              *ngIf="
                dataOfpassengerDetails &&
                dataOfpassengerDetails.adult.taxes >= 0
              ">
              <app-price
                [value]="dataOfpassengerDetails.adult.taxes"
                [color_of_fraction]="'var(--dark-gray)'"></app-price>
            </td>
            <td
              *ngIf="
                dataOfpassengerDetails &&
                dataOfpassengerDetails.child.taxes >= 0
              ">
              <app-price
                [value]="dataOfpassengerDetails.child.taxes"
                [color_of_fraction]="'var(--dark-gray)'"></app-price>
            </td>
            <td
              *ngIf="
                dataOfpassengerDetails &&
                dataOfpassengerDetails.infant.taxes >= 0
              ">
              <app-price
                [value]="dataOfpassengerDetails.infant.taxes"
                [color_of_fraction]="'var(--dark-gray)'"></app-price>
            </td>
          </tr>
          <tr class="border_bottom">
            <th>Subtotal <span class="none-bold"> (p.p)</span></th>
            <td
              *ngIf="
                dataOfpassengerDetails &&
                dataOfpassengerDetails.adult.subTotal >= 0
              ">
              <app-price
                [value]="dataOfpassengerDetails.adult.subTotal"
                [color_of_fraction]="'var(--dark-gray)'"></app-price>
            </td>
            <td
              *ngIf="
                dataOfpassengerDetails &&
                dataOfpassengerDetails.child.subTotal >= 0
              ">
              <app-price
                [value]="dataOfpassengerDetails.child.subTotal"
                [color_of_fraction]="'var(--dark-gray)'"></app-price>
            </td>
            <td
              *ngIf="
                dataOfpassengerDetails &&
                dataOfpassengerDetails.infant.subTotal >= 0
              ">
              <app-price
                [value]="dataOfpassengerDetails.infant.subTotal"
                [color_of_fraction]="'var(--dark-gray)'"></app-price>
            </td>
          </tr>
          <tr class="border_bottom">
            <th>Pax No.</th>
            <td
              *ngIf="
                dataOfpassengerDetails && dataOfpassengerDetails.adult.count
              ">
              {{ dataOfpassengerDetails.adult.count }}
            </td>
            <td
              *ngIf="
                dataOfpassengerDetails && dataOfpassengerDetails.child.count
              ">
              {{ dataOfpassengerDetails.child.count }}
            </td>
            <td
              *ngIf="
                dataOfpassengerDetails && dataOfpassengerDetails.infant.count
              ">
              {{ dataOfpassengerDetails.infant.count }}
            </td>
          </tr>
          <tr [ngClass]="{ 'total-bold': mltPassangers !== true }">
            <th>Total</th>
            <td
              *ngIf="
                dataOfpassengerDetails &&
                dataOfpassengerDetails.adult.total >= 0
              ">
              <app-price
                [value]="dataOfpassengerDetails.adult.total"
                [color_of_fraction]="'var(--dark-gray)'"></app-price>
            </td>
            <td
              *ngIf="
                dataOfpassengerDetails &&
                dataOfpassengerDetails.child.total >= 0
              ">
              <app-price
                [value]="dataOfpassengerDetails.child.total"
                [color_of_fraction]="'var(--dark-gray)'"></app-price>
            </td>
            <td
              *ngIf="
                dataOfpassengerDetails &&
                dataOfpassengerDetails.infant.total >= 0
              ">
              <app-price
                [value]="dataOfpassengerDetails.infant.total"
                [color_of_fraction]="'var(--dark-gray)'"></app-price>
            </td>
            <td *ngIf="mltPassangers === true" class="grand-total">
              <p class="grand-total-text">Grand Total</p>
              <app-price
                [value]="dataOfpassengerDetails.grandTotal"
                [color_of_fraction]="'var(--dark-gray)'"></app-price>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
