import {
  ViewChildren,
  QueryList,
  Component,
  Inject,
  OnInit,
  EventEmitter,
  Input,
  OnDestroy,
  ViewChild,
  ElementRef,
  HostListener,
  Renderer2
} from '@angular/core';
import * as moment from 'moment';
import {
  toggle,
  openCloseSearch,
  openCloseDepartureHeader
} from 'app/shared/animations';
import {
  Flight,
  FlightsBoard,
  sortFlightsByTime,
  sortFlightsByTimeLeft,
  sortFlightsByStops,
  sortFlightsByDestination,
  sortFlightsByAirline,
  sortFlightsByPrice,
  FlightFilter,
  filterifyFlightByStop,
  filterifyFlightByDestination,
  filterifyFlightByAirline,
  filterifyFlightByDepartureTime,
  filterifyFlightByDestinationType,
  filterifyFlightByPrice,
  filterifyFlightByTopDeals,
  filterifyFlightByTimeLeft,
  DeparturePoint
} from 'app/shared/index';
import { FlightAccordionService } from 'app/components/flight-accordion/flight-accordion.service';
import { FlightAccordionComponent } from 'app/components/flight-accordion/flight-accordion.component';
import { ClockService } from 'services/clock/clock.service';
import { DOCUMENT } from '@angular/common';
import { PageScrollService, PageScrollInstance } from 'ng2-page-scroll';
import { Subscription } from 'rxjs';
import { FlightBoardService } from 'services/flight-board/flight-board.service';
import { AnalyticsService } from 'services/analytics/analytics.service';
import { AlertService } from 'services/alert/alert.service';
import { FiltersService } from 'app/services/filters/filters.service';
import { TableHeaderComponent } from 'components/table-header/table-header.component';
import { FlightsFilterComponent } from 'components/flights-filter/flights-filter.component';
import { TableHeaderService } from 'components/table-header/table-header.service';
import { ApiSessionService } from 'services/api-session/api-session.service';

@Component({
  selector: 'app-departure-board',
  templateUrl: './departure-board.component.html',
  styleUrls: ['./departure-board.component.scss'],
  animations: [toggle, openCloseSearch, openCloseDepartureHeader]
})
export class DepartureBoardComponent implements OnInit, OnDestroy {
  @ViewChildren(FlightAccordionComponent)
  flightAccordions: QueryList<FlightAccordionComponent>;
  @ViewChildren(TableHeaderComponent)
  tableHeaderComponent: QueryList<TableHeaderComponent>;
  @ViewChild('main_container') main_container: ElementRef;
  @ViewChild('topBarContainer') topBarContainer: ElementRef;
  @ViewChild('filterContainer') filterContainer: ElementRef;
  @ViewChild('flight_accordion') flight_accordion: ElementRef;
  @ViewChild(FlightsFilterComponent)
  flightsFilterComponent: FlightsFilterComponent;

  @Input() destinationCityName: string;
  @Input() destinationCountryName: string;
  private minAllowedHoursBeforeDeparture: number;
  public pageScrollFinish: EventEmitter<boolean> = new EventEmitter<boolean>();
  public listenToHeightChange$: Subscription;
  public showFilterPopUp$: Subscription;
  public showPopup = false;

  isDesktop: boolean;
  localTime: moment.Moment = moment();
  clockSubscription: any;
  visibleFlightsLimit: number = 5;
  initialVisibleFlightsLimit: number = 5;
  autoRefreshInterval: number;
  loading: boolean = true;
  flightsLoaded: boolean = false;
  keepWaiting: boolean = false;
  loadingInProgress: boolean = false;
  lastFilter: FlightFilter;
  flightsBoard: FlightsBoard;
  allFlightsList: { oneWay: FlightsBoard; roundTrip: FlightsBoard };
  flights: Array<Flight> = [];
  flightsForFilters: Array<Flight> = [];
  notFilteredFlights: Array<Flight>;
  notFilteredFlightsOfCityOrAirport: Array<Flight>;
  flightsForSearch: Array<Flight> = [];

  filterState = 'inactive';
  isFiltersCleared: boolean = false;
  isFiltersActive: boolean = false;
  dataType: string = 'roundTrip';
  flightsByCategory: {
    roundTrip: Array<Flight>;
    oneWay: Array<Flight>;
  } = {
    roundTrip: [],
    oneWay: []
  };
  departureTree: DeparturePoint;
  labels: { name: string }[];
  selectedCity: any;
  selectedCityCode: string;
  selectedAirportCode: string;
  filterPopUp: HTMLElement;
  public element: HTMLElement;
  exirationSubscription: Subscription;
  listenToResize: any;
  fixedComponent: boolean = false;
  fixedTopBar: boolean = false;
  isSearchOpen: boolean = false;
  searchText: string = '';
  isOneWayFlight: boolean = false;
  activeFiltersList: null | any = null;
  lastUpdateDate: moment.Moment;
  subscription: Subscription;
  flightsSubscription: Subscription;
  locationsSubscription: Subscription;
  activeTableHeaders: {
    time?: boolean;
    destination?: boolean;
    stop?: boolean;
    airline?: boolean;
    price?: boolean;
    timeLeft?: boolean;
  } = {};

  constructor(
    private flightBoardService: FlightBoardService,
    private flightAccordionService: FlightAccordionService,
    private clock: ClockService,
    private pageScrollService: PageScrollService,
    private analyticsService: AnalyticsService,
    private alertService: AlertService,
    private filtersService: FiltersService,
    private renderer: Renderer2,
    private tableHeaderService: TableHeaderService,
    private apiSessionService: ApiSessionService,
    @Inject(DOCUMENT) private document: any
  ) {}

  ngOnInit() {
    this.filtersService.resetFilters();

    this.listenToHeightChange$ =
      this.filtersService.updateHeightOfFilterContainer.subscribe(() => {
        this.setHeightToFiltersParentDiv('active');
      });

    this.onWindowResize();

    const flightType = localStorage.getItem('flightType');
    this.isOneWayFlight = flightType
      ? flightType === 'one-way'
      : this.flightBoardService.getIsOneWay();

    this.element = this.document.getElementsByTagName('html')[0];

    this.showFilterPopUp$ = this.alertService.showFilterPopUp.subscribe(
      showCondition => {
        if (showCondition) {
          const body = document.body;
          body.style.height = '100vh';
          body.style.overflowY = 'hidden';
          this.showPopup = true;
        } else {
          const body = document.body;
          body.style.height = 'auto';
          body.style.overflowY = 'auto';
          this.showPopup = false;
        }
      }
    );

    this.loadingInProgress = true;
    this.loading = true;
    this.flightsLoaded = false;

    const cachedLocation = localStorage.getItem('departureTree');
    if (cachedLocation) {
      this.departureTree = JSON.parse(cachedLocation);
    }
    this.locationsSubscription = this.apiSessionService.locations$.subscribe((locationsResult: any) => {
      this.departureTree = locationsResult.departureTree;
    });

    const cachedLabels = localStorage.getItem('labels');
    if (cachedLabels) {
      this.labels = JSON.parse(cachedLabels);
    }
    this.apiSessionService.labels$.subscribe((labelsResult: any) => {
      this.labels = labelsResult.labels;
    });

    this.flightsSubscription = this.flightBoardService.flightBoard$.subscribe(
      (flightsBoard: any) => {
        this.allFlightsList = flightsBoard;
        this.lastUpdateDate = moment();
        this.dataType = this.isOneWayFlight ? 'oneWay' : 'roundTrip';
        this.flightsByCategory.roundTrip = flightsBoard.roundTrip.flights;
        this.flightsByCategory.oneWay = flightsBoard.oneWay.flights;
        if (flightsBoard[this.dataType].flights.length) {
          this.onFlightBoardDataChanged(flightsBoard[this.dataType]);
        } else {
          this.flightsBoard = flightsBoard[this.dataType];
          this.notFilteredFlightsOfCityOrAirport = [];
          this.flights = [];
          this.notFilteredFlights = [];
        }

        this.loading = false;
        this.flightsLoaded = true;
        this.loadingInProgress = false;
        this.setHeightToFiltersParentDiv('active');
      }
    );

    this.clockSubscription = this.clock.tick.subscribe(change => {
      if (this.localTime)
        this.localTime = moment(this.localTime.add(change, 'ms'));
    });

    this.exirationSubscription = this.clock.checkExpiredFlights.subscribe(
      () => {
        if (this.flights.length) {
          this.checkExpiredFlights();
        }
      }
    );
    this.resetVisibleFlightsLimit();
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    if (window.innerWidth < 1510) {
      this.isDesktop = false;
    } else {
      this.isDesktop = true;
      const body = document.body;
      body.style.height = 'auto';
      body.style.overflowY = 'auto';
      this.showPopup = false;
    }

    if (window.innerWidth > 500) {
      this.setUpBoxPosition();
    } else {
      this.setUpBoxMobilePosition();
    }
  }

  setUpBoxMobilePosition() {
    this.listenToResize = this.renderer.listen('window', 'scroll', () => {
      const diffBetweenMainContainerAndTopBarContainer =
        this.main_container.nativeElement.offsetHeight -
        65 -
        this.topBarContainer.nativeElement.offsetHeight;

      if (
        this.visibleFlightsLimit > 5 &&
        this.main_container.nativeElement.clientHeight - window.scrollY > 70
      ) {
        if (
          this.main_container.nativeElement.offsetTop + 55 < window.scrollY &&
          diffBetweenMainContainerAndTopBarContainer > window.scrollY + 200
        ) {
          this.topBarContainer.nativeElement.style.bottom = 'unset';
          this.topBarContainer.nativeElement.style.top = '0px';
          this.fixedTopBar = true;
        } else if (
          diffBetweenMainContainerAndTopBarContainer <=
          window.scrollY + 200
        ) {
          this.fixedTopBar = false;
          this.topBarContainer.nativeElement.style.position = 'absolute';
          this.topBarContainer.nativeElement.style.top = 'unset';
          this.topBarContainer.nativeElement.style.bottom = '450px';
        } else {
          this.fixedTopBar = false;
          this.topBarContainer.nativeElement.style.position = 'unset';
          this.topBarContainer.nativeElement.style.top = '0px';
          this.topBarContainer.nativeElement.style.bottom = 'unset';
        }
      }
    });
  }

  setUpBoxPosition() {
    this.listenToResize = this.renderer.listen('window', 'scroll', () => {
      const rect = this.main_container.nativeElement.getBoundingClientRect();
      const mainContainerHeight = this.main_container.nativeElement.offsetHeight;
      const mainContainerOffsetTop = this.main_container.nativeElement.offsetTop;
      const filterContainerHeight = this.filterContainer.nativeElement.offsetHeight;
      const topBarContainerHeight = this.topBarContainer.nativeElement.offsetHeight;
      const numberOfScrolls = window.scrollY - 185;
      let departureBoardButtonsAreActive = this.visibleFlightsLimit < this.flights.length ||
        (this.flights.length > this.initialVisibleFlightsLimit && this.flights.length - this.visibleFlightsLimit === 0);
  
      let diffBetweenMainContainerAndFilterContainer = mainContainerHeight - (departureBoardButtonsAreActive ? 65 : 0) - filterContainerHeight;
      let diffBetweenMainContainerAndTopBarContainer = mainContainerHeight - (departureBoardButtonsAreActive ? 65 : 0) - topBarContainerHeight;
  
      const shouldFixFilter = (
        mainContainerOffsetTop + 55 < window.scrollY &&
        mainContainerHeight - 150 > filterContainerHeight &&
        diffBetweenMainContainerAndFilterContainer > numberOfScrolls
      );
  
      const shouldBottomAlignFilter = (
        mainContainerHeight > filterContainerHeight &&
        mainContainerHeight - 150 > filterContainerHeight &&
        diffBetweenMainContainerAndFilterContainer <= numberOfScrolls
      );
  
      // Adjust filter container positioning
      if (shouldFixFilter) {
        this.fixedComponent = true;
        this.filterContainer.nativeElement.style.left = `${rect.left - 180}px`;
        this.filterContainer.nativeElement.style.bottom = 'unset';
        this.filterContainer.nativeElement.style.top = '0px';
      } else if (shouldBottomAlignFilter) {
        this.fixedComponent = false;
        this.filterContainer.nativeElement.style.top = 'unset';
        this.filterContainer.nativeElement.style.bottom = departureBoardButtonsAreActive ? '62px' : '0px';
        this.filterContainer.nativeElement.style.left = '-180px';
      } else {
        this.fixedComponent = false;
        this.filterContainer.nativeElement.style.top = 'unset';
        this.filterContainer.nativeElement.style.bottom = 'unset';
        this.filterContainer.nativeElement.style.left = '-180px';
      }
  
      // Adjust top bar container positioning
      if (this.visibleFlightsLimit > 5 && mainContainerHeight - window.scrollY > 100 && this.flights.length > 10) {
        const shouldFixTopBar = mainContainerOffsetTop + 55 < window.scrollY && diffBetweenMainContainerAndTopBarContainer > window.scrollY + 200;
        const shouldBottomAlignTopBar = diffBetweenMainContainerAndTopBarContainer <= window.scrollY + 200;
  
        if (shouldFixTopBar) {
          this.topBarContainer.nativeElement.style.bottom = 'unset';
          this.topBarContainer.nativeElement.style.top = '0px';
          this.fixedTopBar = true;
        } else if (shouldBottomAlignTopBar) {
          this.fixedTopBar = false;
          this.topBarContainer.nativeElement.style.position = 'absolute';
          this.topBarContainer.nativeElement.style.top = 'unset';
          this.topBarContainer.nativeElement.style.bottom = '500px';
        } else {
          this.fixedTopBar = false;
          this.topBarContainer.nativeElement.style.position = 'unset';
          this.topBarContainer.nativeElement.style.top = '0px';
          this.topBarContainer.nativeElement.style.bottom = 'unset';
        }
      } else {
        this.fixedTopBar = false;
      }
    });
  }

  close() {
    this.showPopup = false;
    this.alertService.showFilterPopUp.next(false);
  }

  toggleElement() {
    setTimeout(() => {
      window.dispatchEvent(new Event('scroll'));
    }, 500);
  }

  sortDepartureTree(departureTree: DeparturePoint) {
    if (departureTree) {
      departureTree.children.sort(function (a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

      if (departureTree.code === 'GB') {
        departureTree.children.forEach((city, index) => {
          if (index === 0 && city.name != 'London') {
            for (let i = 0; i < departureTree.children.length; i++) {
              let storage;
              if (departureTree.children[i].name === 'London') {
                storage = departureTree.children[i];
                departureTree.children.splice(i, 1);
                departureTree.children.unshift(storage);
              }
            }
          }
        });
      }

      departureTree.children.forEach(city => {
        city.children?.sort(function (a, b) {
          if (a.flightsCount > b.flightsCount) {
            return -1;
          }
          if (a.flightsCount < b.flightsCount) {
            return 1;
          }
          if (a.flightsCount === b.flightsCount) {
            if (a.name > b.name) {
              return 1;
            }
            if (a.name < b.name) {
              return -1;
            }
            return 0;
          }
          return 0;
        });
      });
    }
  }

  onFlightBoardDataChanged(flightsBoard) {
    localStorage.setItem('departureTree', JSON.stringify(this.departureTree));
    localStorage.setItem('labels', JSON.stringify(this.labels));
    flightsBoard.minDepartureLimitInMinutes = 180;
    this.minAllowedHoursBeforeDeparture =
      flightsBoard.minDepartureLimitInMinutes * 60;
    flightsBoard.filterVisibilityThreshold = 20;
    this.sortDepartureTree(this.departureTree);
    this.flightsBoard = flightsBoard;
    this.creatFeaturedFlights(this.flightsBoard.flights);

    if (this.destinationCityName || this.destinationCountryName) {
      if (this.destinationCityName) {
        this.getAndFillterFlights(this.destinationCityName);
      } else {
        this.getAndFillterFlightsByCountry(this.destinationCountryName);
      }
    } else {
      const data = this.getFromSessionStorage('departure');
      if (data) {
        this.selectedCityCode = data?.city?.code;
        this.selectedAirportCode = data?.airport?.code;
        this.selectedCity = data?.city;
      } else {
        this.selectedCityCode =
          this.departureTree.children[0]?.code;
        this.selectedCity = this.departureTree.children[0];
      }

      this.flights = this.sortByCity(
        this.selectedCityCode,
        this.flightsBoard.flights
      );
      this.flightsForFilters = this.sortByCity(
        this.selectedCityCode,
        this.flightsBoard.flights
      );
      this.notFilteredFlightsOfCityOrAirport = this.sortByCity(
        this.selectedCityCode,
        this.flightsBoard.flights
      );
    }
    this.notFilteredFlights = this.flightsBoard.flights;

    this.checkExpiredFlights();
    this.tableHeaderService.onClick.emit('setActive');
    this.sort(1, 'time');
    this.resetFilters();
  }

  getAndFillterFlights(destinationName) {
    this.flights = [];
    this.flightsForFilters = [];
    this.notFilteredFlightsOfCityOrAirport = [];
    this.flightsBoard.flights.map(flight => {
      if (
        flight.outboundTickets[flight.outboundTickets.length - 1].arrival
          .city === destinationName
      ) {
        this.flights.push(flight);
        this.flightsForFilters.push(flight);
        this.notFilteredFlightsOfCityOrAirport.push(flight);
      }
    });
    this.selectedCityCode = this.flights[0]?.outboundTickets[0].departure.code;
    this.selectedCity = this.flights[0]?.outboundTickets[0].departure;
    this.flightsByCategory.roundTrip = this.flightsByCategory.roundTrip.filter(
      flight =>
        flight.outboundTickets[flight.outboundTickets.length - 1].arrival
          .city === destinationName
    );
    this.flightsByCategory.oneWay = this.flightsByCategory.oneWay.filter(
      flight =>
        flight.outboundTickets[flight.outboundTickets.length - 1].arrival
          .city === destinationName
    );
  }

  getAndFillterFlightsByCountry(destinationName) {
    this.flights = [];
    this.flightsForFilters = [];
    this.notFilteredFlightsOfCityOrAirport = [];
    this.flightsBoard.flights.map(flight => {
      if (
        flight.outboundTickets[flight.outboundTickets.length - 1].arrival
          .country === destinationName
      ) {
        this.flights.push(flight);
        this.flightsForFilters.push(flight);
        this.notFilteredFlightsOfCityOrAirport.push(flight);
      }
    });
    this.selectedCityCode = this.flights[0]?.outboundTickets[0].departure.code;
    this.selectedCity = this.flights[0]?.outboundTickets[0].departure;
  }

  checkExpiredFlights() {
    const flights = this.flights.filter(flight => {
      return flight.timeLeft.asSeconds() > this.minAllowedHoursBeforeDeparture;
    });
    this.updateDepartureTreeFlightCount();
    if (flights.length != this.flights.length) {
      this.flights = flights;
      this.flightsForFilters = flights;
      this.updateDepartureTreeFlightCount();
    }
    this.notFilteredFlights = this.notFilteredFlights.filter(flight => {
      return flight.timeLeft.asSeconds() > this.minAllowedHoursBeforeDeparture;
    });

    this.flightsForSearch = [...this.notFilteredFlights];

    this.notFilteredFlightsOfCityOrAirport =
      this.notFilteredFlightsOfCityOrAirport.filter(flight => {
        return (
          flight.timeLeft.asSeconds() > this.minAllowedHoursBeforeDeparture
        );
      });
  }

  updateDepartureTreeFlightCount() {
    if (this.departureTree) {
      //city flights count
      let numberOfFlights = 0;

      this.departureTree.children.forEach(city => {
        city.children.forEach(airport => {
          airport.flightsCount = this.notFilteredFlights.filter(
            x => x.outboundTickets[0].departure.code === airport.code
          ).length;
          numberOfFlights += airport.flightsCount;
        });
        city.flightsCount = numberOfFlights;
        numberOfFlights = 0;
      });
    }
  }

  ngOnDestroy() {
    this.clockSubscription.unsubscribe();
    this.locationsSubscription.unsubscribe();
    this.flightsSubscription.unsubscribe();
    if (this.showFilterPopUp$) {
      this.showFilterPopUp$.unsubscribe();
    }
    if (this.listenToHeightChange$) {
      this.listenToHeightChange$.unsubscribe();
    }

    if (this.exirationSubscription) {
      this.exirationSubscription.unsubscribe();
    }

    if (this.listenToResize) {
      this.listenToResize();
    }

    this.subscription?.unsubscribe();
  }

  closeAccordion() {
    this.flightAccordionService.reset();
  }

  sort(order: number, sortBy: string) {
    switch (sortBy) {
      case 'time':
        this.flights = sortFlightsByTime(this.flights, order);
        this.activeTableHeaders = { time: true };
        break;
      case 'time-left':
        this.flights = sortFlightsByTimeLeft(this.flights, order);
        this.activeTableHeaders = { timeLeft: true };
        break;
      case 'stops':
        this.flights = sortFlightsByStops(this.flights, order);
        this.activeTableHeaders = { stop: true };
        break;
      case 'destination':
        this.flights = sortFlightsByDestination(this.flights, order);
        this.activeTableHeaders = { destination: true };
        break;
      case 'airline':
        this.flights = sortFlightsByAirline(this.flights, order);
        this.activeTableHeaders = { airline: true };
        break;
      case 'price':
        this.flights = sortFlightsByPrice(this.flights, order);
        this.activeTableHeaders = { price: true };
        break;
      default:
        break;
    }
  }

  sortByAirport(
    selectedAirportCode: string,
    flights: Array<Flight>
  ): Array<Flight> {
    const flightsResult: Array<Flight> = [];
    for (let i = 0; i < flights.length; i++) {
      if (
        flights[i].outboundTickets[0].departure.code === selectedAirportCode
      ) {
        flightsResult.push(flights[i]);
      }
    }

    return flightsResult;
  }

  sortByCity(selectedCityCode: string, flights: Array<Flight>): Array<Flight> {
    const flightsResult: Array<Flight> = [];
    for (let i = 0; i < flights.length; i++) {
      if (
        flights[i].outboundTickets[0].departure.cityCode === selectedCityCode
      ) {
        flightsResult.push(flights[i]);
      }
    }

    return flightsResult;
  }

  getFromSessionStorage(name) {
    return JSON.parse(sessionStorage.getItem(name));
  }

  onDepartureSelected(departure: any) {
    sessionStorage.setItem('departure', JSON.stringify(departure));
    this.isFiltersActive = false;

    this.resetVisibleFlightsLimit();
    this.flightAccordionService.reset();

    const isSameCity = this.selectedCityCode === departure?.city?.code;
    const isSameAirport = this.selectedAirportCode === departure?.airport?.code;
    const isAirportExist = departure.airport != undefined;

    if (isSameCity) {
      if (isAirportExist === true) {
        //same city different airport

        this.selectedAirportCode = departure.airport.code;
        const flights = this.sortByAirport(
          this.selectedAirportCode,
          this.notFilteredFlights
        );
        this.flights = flights;
        this.flightsForFilters = flights;
        this.notFilteredFlightsOfCityOrAirport = flights;

        this.flightsByCategory.roundTrip = !this.isOneWayFlight
          ? this.flights
          : this.sortByAirport(
              this.selectedAirportCode,
              this.allFlightsList.roundTrip.flights
            );

        this.flightsByCategory.oneWay = this.isOneWayFlight
          ? this.flights
          : this.sortByAirport(
              this.selectedAirportCode,
              this.allFlightsList.oneWay.flights
            );
      } else if (
        isAirportExist === false &&
        this.selectedAirportCode != undefined
      ) {
        //same city show all flights

        this.selectedAirportCode = undefined;
        const flights = this.sortByCity(
          this.selectedCityCode,
          this.notFilteredFlights
        );
        this.flights = flights;
        this.flightsForFilters = flights;
        this.notFilteredFlightsOfCityOrAirport = flights;

        this.flightsByCategory.roundTrip = !this.isOneWayFlight
          ? this.flights
          : this.sortByCity(
              this.selectedCityCode,
              this.allFlightsList.roundTrip.flights
            );

        this.flightsByCategory.oneWay = this.isOneWayFlight
          ? this.flights
          : this.sortByCity(
              this.selectedCityCode,
              this.allFlightsList.oneWay.flights
            );
      }
    } else {
      this.selectedCityCode = departure.city.code;
      this.loadingInProgress = true;
      this.loading = true;
      this.flightsLoaded = false;
      this.selectedAirportCode = undefined;
      this.flightBoardService.refreshFlightBoard(this.selectedCityCode);
    }
    if (!isSameCity || !isSameAirport) {
      this.clearFilters();
    }
  }

  creatFeaturedFlights(flights: Array<Flight>) {
    this.flightsBoard.featuredFlights =
      this.flightBoardService.filterFeaturedFlights(flights);
    this.flightBoardService.featuredFlights$.next(this.flightsBoard);
  }

  resetVisibleFlightsLimit() {
    this.visibleFlightsLimit = 5;
  }

  showLess() {
    this.scrollToTop();
    this.resetVisibleFlightsLimit();
    this.fixedTopBar = false;
    this.topBarContainer.nativeElement.style.position = 'unset';
    this.topBarContainer.nativeElement.style.top = 'unset';
    this.topBarContainer.nativeElement.style.bottom = 'unset';
  }

  showMore() {
    if (this.flights.length <= this.visibleFlightsLimit + 15) {
      this.visibleFlightsLimit +=
        this.flights.length - this.visibleFlightsLimit;
    } else {
      this.visibleFlightsLimit += 10;
    }
    
    setTimeout(() => {
      window.dispatchEvent(new Event('scroll'));
    }, 0);
    this.analyticsService.sendEvent('Departure Board', 'Show More');
  }

  showAll() {
    this.visibleFlightsLimit = this.flights.length;
    setTimeout(() => {
      window.dispatchEvent(new Event('scroll'));
    }, 0);
  }

  setHeightToFiltersParentDiv(type: string) {
    setTimeout(() => {
      if (type === 'active' && this.filterContainer) {
        this.main_container.nativeElement.style.setProperty(
          'min-height',
          this.filterContainer.nativeElement.offsetHeight + 'px'
        );
      } else if (type === 'inactive') {
        this.main_container.nativeElement.style.setProperty(
          'margin-bottom',
          '0'
        );
      }
    }, 500);
  }

  clearFilters() {
    this.filtersService.resetFilters();
    this.resetFilters();
  }

  resetFilters() {
    this.filtersService.isApplied = false;

    this.isFiltersActive = false;
    this.flights = [...this.notFilteredFlightsOfCityOrAirport];
    this.flightsForFilters = [...this.notFilteredFlightsOfCityOrAirport];
    this.flightsByCategory.roundTrip = this.allFlightsList.roundTrip.flights;
    this.flightsByCategory.oneWay = this.allFlightsList.oneWay.flights;

    this.activeFiltersList = null;
    this.searchText = '';

    this.checkExpiredFlights();
    this.isFiltersCleared = true;

    if (this.flights.length >= 2) {
      this.tableHeaderService.onClick.emit('setActive');
      this.sort(1, 'time');
      this.activeTableHeaders = { time: true };
    } else {
      this.activeTableHeaders = {};
    }

    if (this.isDesktop) {
      this.filtersService.setUpFilters.next(true);
    }
  }

  resetFiltersForDesktop() {
    this.filtersService.isApplied = false;
    this.isFiltersActive = false;
    this.activeFiltersList = null;
    this.setHeightToFiltersParentDiv('active');
    this.flights = this.notFilteredFlightsOfCityOrAirport;
    this.flightsForFilters = this.notFilteredFlightsOfCityOrAirport;
    this.flightsByCategory.roundTrip = this.allFlightsList.roundTrip.flights;
    this.flightsByCategory.oneWay = this.allFlightsList.oneWay.flights;
    this.searchText = '';

    this.checkExpiredFlights();
    this.isFiltersCleared = true;
    if (this.flights.length >= 2) {
      this.tableHeaderService.onClick.emit('setActive');
      this.sort(1, 'time');
      this.activeTableHeaders = { time: true };
    } else {
      this.activeTableHeaders = {};
    }

    this.alertService.showFilterPopUp.next(false);
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.fixedTopBar = false;
    this.topBarContainer.nativeElement.style.position = 'unset';
    this.topBarContainer.nativeElement.style.top = '0px';
    this.topBarContainer.nativeElement.style.bottom = 'unset';
  }

  applyFilter(filter: FlightFilter) {
    this.filtersService.isApplied = true;
    this.setHeightToFiltersParentDiv('active');
    //show all flights if filters are empty
    if (
      !filter.airlines.length &&
      !filter.departureTime.length &&
      !filter.destinationType.length &&
      !filter.destinations.length &&
      !filter.price.length &&
      !filter.topDealsChecked &&
      !filter.stops.length &&
      !filter.timeLeft.length
    ) {
      this.flights = this.notFilteredFlightsOfCityOrAirport;
      this.flightsForFilters = this.notFilteredFlightsOfCityOrAirport;
      this.isFiltersActive = false;
      this.flightsForSearch = [...this.flights];
      this.resetSort(true);
      this.activeFiltersList = null;
      this.filtersService.isApplied = false;
      this.flightsByCategory.roundTrip = this.allFlightsList.roundTrip.flights;
      this.flightsByCategory.oneWay = this.allFlightsList.oneWay.flights;
      this.scrollToTop();
      return;
    }

    this.activeFiltersList = {
      destinations: [...filter.destinations],
      departureTime: [...filter.departureTime],
      destinationType: [...filter.destinationType],
      stops: [...filter.stops],
      airlines: [...filter.airlines],
      price: [...filter.price],
      topDealsChecked: filter.topDealsChecked ? ['Top Deals'] : [],
      timeLeft: [...filter.timeLeft]
    };
    this.setFormToActiveFilters(this.activeFiltersList);

    this.isFiltersActive = true;
    
    this.flights = this.notFilteredFlightsOfCityOrAirport.filter(flight => {
      const shouldBeFilteredOut =
        filterifyFlightByStop(flight, filter) ||
        filterifyFlightByDestination(flight, filter) ||
        filterifyFlightByAirline(flight, filter) ||
        filterifyFlightByDepartureTime(flight, filter) ||
        filterifyFlightByDestinationType(flight, filter) ||
        filterifyFlightByPrice(flight, filter) ||
        filterifyFlightByTopDeals(flight, filter) ||
        filterifyFlightByTimeLeft(flight, filter);
      return !shouldBeFilteredOut;
    });

    this.flightsForFilters = [];
    this.flightsForFilters = [...this.flights];
    this.flightsForSearch = [...this.flights];

    this.flightsByCategory.roundTrip = !this.isOneWayFlight
      ? this.flights
      : this.allFlightsList.roundTrip.flights.filter(flight => {
          const shouldBeFilteredOut =
            filterifyFlightByStop(flight, filter) ||
            filterifyFlightByDestination(flight, filter) ||
            filterifyFlightByAirline(flight, filter) ||
            filterifyFlightByDepartureTime(flight, filter) ||
            filterifyFlightByDestinationType(flight, filter) ||
            filterifyFlightByPrice(flight, filter) ||
            filterifyFlightByTopDeals(flight, filter) ||
            filterifyFlightByTimeLeft(flight, filter);
          return !shouldBeFilteredOut;
        });

    this.flightsByCategory.oneWay = this.isOneWayFlight
      ? this.flights
      : this.allFlightsList.oneWay.flights.filter(flight => {
          const shouldBeFilteredOut =
            filterifyFlightByStop(flight, filter) ||
            filterifyFlightByDestination(flight, filter) ||
            filterifyFlightByAirline(flight, filter) ||
            filterifyFlightByDepartureTime(flight, filter) ||
            filterifyFlightByDestinationType(flight, filter) ||
            filterifyFlightByPrice(flight, filter) ||
            filterifyFlightByTopDeals(flight, filter) ||
            filterifyFlightByTimeLeft(flight, filter);
          return !shouldBeFilteredOut;
        });
    this.lastFilter = filter;

    this.tableHeaderService.onClick.emit('setActive');
    this.sort(1, 'time');
    this.scrollToTop();
  }

  setFormToActiveFilters(filtersArray: any) {
    if (filtersArray.timeLeft && filtersArray.timeLeft.length) {
      filtersArray.timeLeft = filtersArray.timeLeft.map(
        (item: string) => item + ' hrs'
      );
    }

    if (filtersArray.price && filtersArray.price.length) {
      filtersArray.price = filtersArray.price.map((item: string) => '£' + item);
    }
  }

  resetSort(isRequired = false) {
    if (this.tableHeaderComponent) {
      const timeSort = this.tableHeaderComponent.first;
      if (!timeSort.isActive) {
        timeSort.flip();
      }
      if (isRequired) {
        timeSort.sort.emit(1);
      }
    }
  }

  updateFlightsCount(filter: FlightFilter) {
    this.setHeightToFiltersParentDiv('active');
    //show all flights if filters are empty
    if (
      !filter.airlines.length &&
      !filter.departureTime.length &&
      !filter.destinationType.length &&
      !filter.destinations.length &&
      !filter.price.length &&
      !filter.topDealsChecked &&
      !filter.stops.length &&
      !filter.timeLeft.length
    ) {
      this.flightsForFilters = this.notFilteredFlightsOfCityOrAirport;
      this.isFiltersActive = false;
      return;
    }

    this.isFiltersActive = true;
    this.flightsForFilters = this.notFilteredFlightsOfCityOrAirport.filter(
      flight => {
        const shouldBeFilteredOut =
          filterifyFlightByStop(flight, filter) ||
          filterifyFlightByDestination(flight, filter) ||
          filterifyFlightByAirline(flight, filter) ||
          filterifyFlightByDepartureTime(flight, filter) ||
          filterifyFlightByDestinationType(flight, filter) ||
          filterifyFlightByPrice(flight, filter) ||
          filterifyFlightByTopDeals(flight, filter) ||
          filterifyFlightByTimeLeft(flight, filter);
        return !shouldBeFilteredOut;
      }
    );
  }

  applyMobileFilters() {
    this.filtersService.applyMobileFilters.next(true);
    this.showPopup = false;
    this.alertService.showFilterPopUp.next(this.showPopup);
  }

  scrollToFlightAccardion(id) {
    if (this.flightAccordionService.isRegistered(id)) {
      this.scrollAndToggle(id);
    } else {
      this.subscription =
        this.flightAccordionService.onFligthCompRegistered.subscribe(
          flightId => {
            if (flightId == id) {
              if (this.subscription) this.subscription.unsubscribe();
              this.scrollAndToggle(id);
            } else if (
              this.flightAccordionService.getRegisteredFlightCount() ==
                this.visibleFlightsLimit &&
              !this.flightAccordionService.isRegistered(id)
            ) {
              this.showMore();
            }
          }
        );
      this.showMore();
    }
  }

  scrollAndToggle(id) {
    this.flightAccordions.some(flightAccardion => {
      if (flightAccardion.flight.id == id) {
        this.scrollTo(id);
        const sub = this.pageScrollFinish.subscribe(done => {
          if (done) {
            flightAccardion.toggle();
            sub.unsubscribe();
            return true;
          }
        });
      }
      return false;
    });
  }

  scrollTo(id, tillTop?: boolean) {
    const pageScrollOffset = tillTop === true ? 380 : 180;

    const pageScrollInstance: PageScrollInstance =
      PageScrollInstance.newInstance({
        document: this.document,
        scrollTarget: '#' + id,
        pageScrollOffset: pageScrollOffset,
        pageScrollDuration: 1200,
        pageScrollFinishListener: this.pageScrollFinish
      });
    this.pageScrollService.start(pageScrollInstance);
  }

  openFiltersPopup(expendAll: boolean) {
    this.showPopup = true;
    this.alertService.showFilterPopUp.next(true);
    if (expendAll) {
      this.flightsFilterComponent.expendAll();
    }
  }

  openSearch() {
    this.isSearchOpen = !this.isSearchOpen;
    if (!this.isSearchOpen) {
      if (this.isDesktop) this.resetFiltersForDesktop();
      else this.resetFilters();
    }
  }

  onFlightsChanged(data: any | undefined) {
    if (data) {
      this.flights = [...data.flights];
      this.tableHeaderService.onClick.emit('setActive');
      this.sort(1, 'time');
      this.searchText = data.text;
    } else {
      this.searchText = '';
      if (this.isDesktop) this.resetFiltersForDesktop();
      else this.resetFilters();
    }
  }

  getFilterKeys() {
    return Object.keys(this.activeFiltersList);
  }

  deleteFilter(filterType: string, filterBy: string) {
    this.filtersService.countOfFilters--;

    if (filterType === 'destinations') {
      const filter = this.filtersService.destinationItems.find(
        filter => filter.filterBy === filterBy
      );
      filter.isChecked = false;
    } else if (filterType === 'departureTime') {
      const filter = this.filtersService.departureTimeItems.find(
        filter => filter.filterBy === filterBy
      );
      filter.isChecked = false;
    } else if (filterType === 'destinationType') {
      const filter = this.filtersService.destinationTypeItems.find(
        filter => filter.filterBy === filterBy
      );
      filter.isChecked = false;
    } else if (filterType === 'stops') {
      const filter = this.filtersService.stopItems.find(
        filter => filter.filterBy === filterBy
      );
      filter.isChecked = false;
    } else if (filterType === 'airlines') {
      const filter = this.filtersService.airlineItems.find(
        filter => filter.filterBy === filterBy
      );
      filter.isChecked = false;
    } else if (filterType === 'price') {
      filterBy = filterBy.substring(1);
      const filter = this.filtersService.priceItems.find(
        filter => filter.filterBy === filterBy
      );
      filter.isChecked = false;
    } else if (filterType === 'topDealsChecked') {
      this.filtersService.priceItems[0].topDeal.isChecked = false;
    } else if (filterType === 'timeLeft') {
      filterBy = filterBy.slice(0, -4);
      const filter = this.filtersService.hoursItems.find(
        filter => filter.filterBy === filterBy
      );
      filter.isChecked = false;
    }

    if (filterType === 'topDealsChecked') {
      this.filtersService.filters[filterType] = false;
    } else {
      for (let i = 0; i < this.filtersService.filters[filterType].length; i++) {
        const filter = this.filtersService.filters[filterType][i];
        if (filter === filterBy) {
          this.filtersService.filters[filterType].splice(i, 1);
        }
      }
    }

    this.applyFilter(this.filtersService.filters);
  }

  toggleIsOneWayFlight(isOneWay: boolean) {
    if (this.flightBoardService.getIsOneWay() != isOneWay) {
      this.flightBoardService.setIsOneWay(isOneWay);
      this.isOneWayFlight = isOneWay;
      this.fixedTopBar = false;
      this.resetVisibleFlightsLimit();
      this.tableHeaderService.onClick.emit('setActive');

      const currentTime = moment();
      const diffInMinutes = currentTime.diff(this.lastUpdateDate, 'minutes');
      if (diffInMinutes <= 10) {
        const dataType = isOneWay ? 'oneWay' : 'roundTrip';
        if (this.allFlightsList[dataType].flights.length) {
          this.onFlightBoardDataChanged(this.allFlightsList[dataType]);
        } else {
          this.flightsBoard = this.allFlightsList[dataType];
          this.notFilteredFlightsOfCityOrAirport = [];
          this.flights = [];
          this.notFilteredFlights = [];
        }
      } else {
        this.loadingInProgress = true;
        this.loading = true;
        this.flightsLoaded = false;
        this.flightBoardService.refreshFlightBoard(this.selectedCityCode);
        this.filtersService.resetFilters();
      }

      const departure = this.getFromSessionStorage('departure');
      if (departure) {
        this.onDepartureSelected(departure);
      }

      if (this.lastFilter) {
        this.applyFilter(this.lastFilter);
      }

      localStorage.setItem('flightType', isOneWay ? 'one-way' : 'return');
      this.scrollToTop();
    }
  }

  updateKeepWaiting(value: boolean) {
    this.keepWaiting = value;
  }

  isLoading(value: boolean) {
    this.flightsLoaded = !value;
    this.loadingInProgress = value;
    this.loading = value;
  }
}
