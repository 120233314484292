<div class="search-container">
  <input
    type="text"
    #myInput
    [placeholder]="placeholderText"
    [(ngModel)]="searchText"
    (input)="onInputChange($event.target.value)" />
  <span class="search-icon">
    <img src="../../../assets/img/icons/search-dark-gray.svg" />
  </span>
  <span class="clear-icon" *ngIf="searchText" (click)="clearSearch()">
    <img src="../../../assets/img/icons/cross.svg" />
  </span>
</div>
