<div
  [ngClass]="cssClasses"
  (click)="showArrows && flip()"
  [ngClass]="{ 'sort-wrapp': showArrows }">
  <ng-content></ng-content>
  <div class="sort-by" *ngIf="showArrows">
    <span
      [ngClass]="{ 'hidden-header': order === -1, 'arrow-up-white': isActive }"
      class="arrow-up transition-all"></span>
    <span
      [ngClass]="{ 'hidden-header': order === 1, 'arrow-down-white': isActive }"
      class="arrow-down transition-all"></span>
  </div>
</div>
