import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnChanges,
  DoCheck
} from '@angular/core';
import * as moment from 'moment';

import { CalendarDay } from 'app/shared';
import { CalendarDayService } from 'app/components/calendar-day/calendar-day.service';

@Component({
  selector: 'app-flight-calendar',
  templateUrl: './flight-calendar.component.html',
  styleUrls: ['./flight-calendar.component.scss']
})
export class FlightCalendarComponent
  implements OnInit, AfterViewInit, OnChanges, DoCheck
{
  @ViewChild('calendar') calendar: ElementRef;
  @ViewChild('monthWrap') monthWrap: Element;

  @Output() daySelected: EventEmitter<CalendarDay> = new EventEmitter();
  @Input() loadedCalendarDays: Array<CalendarDay>;
  @Input() isToday: any;

  calendarDays: Array<CalendarDay> = [];
  drawnMonth: moment.Moment;

  startDate: moment.Moment;
  previouCalendarFlightsLenght: number;

  highlightPreviousDay: Date = null;

  scrollPosition = 0;

  endDate: moment.Moment;
  drawnLastMonth: moment.Moment;

  currentYear: any;
  currentMonth: any;
  maxScroll: any;
  el: any;

  heightOfCell = 0;

  isOtherOutbound = false;
  isFlightUnavailable = false;
  isSearchable = false;

  downImgUrl = 'assets/img/icons/arrow-down-dark-gray.png';
  upImgUrl = 'assets/img/icons/arrow-up-dark-gray.png';

  defaultNumberOfDays = 35;

  constructor(private calendarDayService: CalendarDayService) {}

  ngOnInit() {
    // check screen size or scroll
    this.onResize();

    this.previouCalendarFlightsLenght = this.loadedCalendarDays.length;
    this.calendarDayService.reset();

    this.startDate = this.loadedCalendarDays.find(loadedCalendarDay => {
      return loadedCalendarDay.isStartDate;
    }).date;

    this.endDate =
      this.loadedCalendarDays[this.loadedCalendarDays.length - 1].date;

    this.drawnMonth = moment(this.startDate);
    this.drawnLastMonth = moment(this.endDate);
    this.drawCalendar(this.drawnMonth, this.drawnLastMonth);
    this.getCurrentMonth(0);
    this.maxScroll = (this.calendarDays.length / 7 - 5) * this.heightOfCell;
  }

  onMouseLeave(condition: string) {
    if (condition === 'up') {
      this.upImgUrl = 'assets/img/icons/arrow-up-dark-gray.png';
    }
    if (condition === 'down') {
      this.downImgUrl = 'assets/img/icons/arrow-down-dark-gray.png';
    }
  }

  onHover(condition) {
    if (condition === 'up') {
      this.upImgUrl = 'assets/img/icons/arrow-up-dark-yellow.png';
    }
    if (condition === 'down') {
      this.downImgUrl = 'assets/img/icons/arrow-down-dark-yellow.png';
    }
  }

  ngDoCheck() {
    // we need to check updates on returnFlights and unavailableFlightDates arrays
    // if these are updated then we need to draw calendar again
    if (
      this.previouCalendarFlightsLenght &&
      this.previouCalendarFlightsLenght != this.loadedCalendarDays.length
    ) {
      this.previouCalendarFlightsLenght = this.loadedCalendarDays.length;
      this.drawnMonth = moment(this.startDate);
      this.drawCalendar(this.drawnMonth, this.drawnLastMonth);
    }
  }

  ngAfterViewInit() {
    this.el =
      this.calendar.nativeElement.getElementsByClassName('month-wrapp')[0];
  }

  selectDay(calendarDay: CalendarDay) {
    this.daySelected.emit(calendarDay);
  }

  getCleanedCalendarDays(calendarDays) {
    if (calendarDays.length > 34) {
      let finalDays = [];
      let itemIndex;
      for (let i = 35; i < calendarDays.length; i++) {
        const item = calendarDays[i];
        if (item.price) {
          itemIndex = i;
        }
      }

      if (itemIndex) {
        for (let i = 0; i < calendarDays.length; i++) {
          const item = calendarDays[i];
          if (i >= itemIndex && i % 7 === 0) {
            break;
          }
          finalDays.push(item);
        }
        return finalDays;
      }
      finalDays = [...calendarDays.slice(0, 35)];
      return finalDays;
    }
    return calendarDays;
  }

  drawCalendar(month: moment.Moment, drawnLastMonth?: moment.Moment) {
    let calendarDays = this.createDays(month, drawnLastMonth);
    calendarDays = calendarDays.map(calendarDay => {
      const loadedCalendarDay = this.loadedCalendarDays.find(
        loadedCalendarDay => {
          return loadedCalendarDay.date.isSame(calendarDay.date, 'day');
        }
      );
      return loadedCalendarDay || calendarDay;
    });

    this.calendarDays = this.getCleanedCalendarDays(calendarDays);
  }

  createDays(
    startMonth: moment.Moment,
    endMonth: moment.Moment
  ): Array<CalendarDay> {
    let calendarDays = [];
    const firstDayOfCurrentMonth = this.getDayIndexOfCurrentMonth(startMonth);
    const amountOfDaysOfPreviousMonth =
      this.getAmountOfDaysOfPreviousMonth(startMonth);
    const amountOfDaysOfCurrntMonth =
      this.getAmountOfDaysOfCurrentMonth(startMonth);
    // fill previous month
    const diff = firstDayOfCurrentMonth - startMonth.date();
    if (diff > 0) {
      for (
        let i = amountOfDaysOfPreviousMonth - diff + 1;
        i <= amountOfDaysOfPreviousMonth;
        i++
      ) {
        const previousMonth = moment(startMonth);
        previousMonth.subtract(1, 'month');
        previousMonth.date(i);
        const calendarDay = new CalendarDay(previousMonth);
        calendarDay.isLoaded = false;

        calendarDays.push(calendarDay);
      }
      const daysOfCurrentMonth = this.fillCurrentMonth(
        startMonth,
        endMonth,
        firstDayOfCurrentMonth,
        amountOfDaysOfCurrntMonth,
        false
      );
      calendarDays = [...calendarDays, ...daysOfCurrentMonth];
    } else {
      const daysOfCurrentMonth = this.fillCurrentMonth(
        startMonth,
        endMonth,
        firstDayOfCurrentMonth,
        amountOfDaysOfCurrntMonth,
        true
      );
      calendarDays = daysOfCurrentMonth;
    }

    return calendarDays;
  }

  fillCurrentMonth(
    startMonth: moment.Moment,
    endMonth: moment.Moment,
    firstDayOfCurrentMonth: number,
    amountOfDaysOfCurrntMonth: number,
    fillFromFirstDay: boolean
  ) {
    const day = startMonth.date();
    let calendarDays = [];
    let lengthOfExistingDays: number;

    if (fillFromFirstDay) {
      for (
        let i = day - firstDayOfCurrentMonth + 1;
        i <= amountOfDaysOfCurrntMonth;
        i++
      ) {
        const curruntMonth = moment(startMonth);
        curruntMonth.date(i);
        const calendarDay = new CalendarDay(curruntMonth);
        calendarDay.isLoaded = false;

        calendarDays.push(calendarDay);
      }
    } else {
      for (let i = 1; i <= amountOfDaysOfCurrntMonth; i++) {
        const curruntMonth = moment(startMonth);
        curruntMonth.date(i);
        const calendarDay = new CalendarDay(curruntMonth);
        calendarDay.isLoaded = false;

        calendarDays.push(calendarDay);
      }
    }

    // fill remaining days of next month
    const yearDiff = this.endDate.year() - this.startDate.year();
    let diff = endMonth.month() - startMonth.month();

    if (yearDiff != 0) {
      diff = yearDiff * 12 + diff;
    }

    if (diff === 0) {
      lengthOfExistingDays = calendarDays.length;
      const days = this.fillRemainingDays(startMonth, lengthOfExistingDays);
      calendarDays = [...calendarDays, ...days];
    } else {
      let nextMonth: moment.Moment;
      const currentMonth = startMonth;
      for (let i = 1; i <= diff; i++) {
        currentMonth.add(1, 'month');
        nextMonth = currentMonth;

        const numberOfdays = this.getAmountOfDaysOfCurrentMonth(currentMonth);

        for (let i = 1; i <= numberOfdays; i++) {
          const curruntMonthInLoop = moment(currentMonth);
          curruntMonthInLoop.date(i);
          const calendarDay = new CalendarDay(curruntMonthInLoop);
          calendarDay.isLoaded = false;

          calendarDays.push(calendarDay);
        }
      }
      if (calendarDays.length % this.defaultNumberOfDays != 0) {
        lengthOfExistingDays = calendarDays.length;
        const days = this.fillRemainingDays(nextMonth, lengthOfExistingDays);
        calendarDays = [...calendarDays, ...days];
      }
    }

    return calendarDays;
  }

  fillRemainingDays(startMonth, lengthOfExistingDays) {
    const calendarDays = [];
    if (lengthOfExistingDays > this.defaultNumberOfDays) {
      const nextMonth = moment(startMonth);
      nextMonth.date(1);
      nextMonth.add(1, 'month');

      while (!((nextMonth.day() || 7) == 1)) {
        const nextMonthDays = new CalendarDay(moment(nextMonth));
        nextMonthDays.isLoaded = false;
        calendarDays.push(nextMonthDays);
        nextMonth.add(1, 'days');
      }
    } else if (lengthOfExistingDays < this.defaultNumberOfDays) {
      let numOfIteration = this.defaultNumberOfDays - lengthOfExistingDays;
      const nextMonth = moment(startMonth);
      nextMonth.date(1);
      nextMonth.add(1, 'month');

      while (numOfIteration > 0) {
        numOfIteration--;
        const nextMonthDays = new CalendarDay(moment(nextMonth));
        nextMonthDays.isLoaded = false;
        calendarDays.push(nextMonthDays);
        nextMonth.add(1, 'days');
      }
    }
    return calendarDays;
  }

  getDayIndexOfCurrentMonth(month: moment.Moment) {
    let day = month.day();
    if (day === 0) day = 7;

    return day;
  }

  getAmountOfDaysOfCurrentMonth(month: moment.Moment) {
    const currentMonth = moment(month);
    currentMonth.date(1);

    return currentMonth.daysInMonth();
  }

  getAmountOfDaysOfPreviousMonth(month: moment.Moment) {
    const previousMonth = moment(month);
    previousMonth.subtract(1, 'months');
    previousMonth.date(1);
    const currentMonth = moment(month);
    currentMonth.date(1);

    return currentMonth.diff(previousMonth, 'days');
  }

  getCurrentMonth(startIndex) {
    // by deault these 3 should be false because
    // we areinterested in days which we see in calendar(5 rows)
    this.isOtherOutbound = false;
    this.isFlightUnavailable = false;
    this.isSearchable = false;

    const len = startIndex + this.defaultNumberOfDays;

    const monthsAmountObj: { [k: string]: any } = {};
    for (let i = startIndex; i < len; i++) {
      this.checkDateTypes(this.calendarDays[i]);
      const month = this.calendarDays[i].date.format('MMM');
      if (monthsAmountObj[month]) {
        continue;
      } else {
        monthsAmountObj[month] = [
          this.calendarDays[i].date.format('YYYY'),
          month
        ];
      }
    }

    if (Object.keys(monthsAmountObj).length === 3) {
      this.currentMonth = Object.keys(monthsAmountObj)[1];
      this.currentYear = monthsAmountObj[Object.keys(monthsAmountObj)[1]][0];
    } else if (Object.keys(monthsAmountObj).length === 2) {
      if (
        monthsAmountObj[Object.keys(monthsAmountObj)[0]][0] !=
        monthsAmountObj[Object.keys(monthsAmountObj)[1]][0]
      ) {
        this.currentYear = '';
        const year_1 = monthsAmountObj[Object.keys(monthsAmountObj)[0]][0];
        const year_2 = monthsAmountObj[Object.keys(monthsAmountObj)[1]][0];
        this.currentMonth =
          Object.keys(monthsAmountObj)[0] +
          ' ' +
          year_1 +
          ' - ' +
          Object.keys(monthsAmountObj)[1] +
          ' ' +
          year_2;
        return;
      }
      this.currentMonth =
        Object.keys(monthsAmountObj)[0] + '-' + Object.keys(monthsAmountObj)[1];
      this.currentYear = monthsAmountObj[Object.keys(monthsAmountObj)[0]][0];
    }
  }

  scrollUp() {
    this.scrollPosition =
      this.scrollPosition - this.heightOfCell >= 0
        ? this.scrollPosition - this.heightOfCell
        : this.scrollPosition;
    const startIndex = (this.scrollPosition / this.heightOfCell) * 7;
    this.getCurrentMonth(startIndex);

    setTimeout(() => {
      this.el.scrollTo({
        top: this.scrollPosition,
        left: 0,
        behavior: 'smooth'
      });
    }, 50);
  }

  scrollDown() {
    this.scrollPosition =
      this.scrollPosition + this.heightOfCell <= this.maxScroll
        ? this.scrollPosition + this.heightOfCell
        : this.scrollPosition;
    const startIndex = (this.scrollPosition / this.heightOfCell) * 7;
    this.getCurrentMonth(startIndex);

    setTimeout(() => {
      this.el.scrollTo({
        top: this.scrollPosition,
        left: 0,
        behavior: 'smooth'
      });
    }, 50);
  }

  checkDateTypes(day) {
    const localTime = moment();
    if (day.isOtherOutbound) {
      this.isOtherOutbound = true;
    } else if (
      !day.isStartDate &&
      !day.isAvailable &&
      day.isLoaded &&
      day.date.isAfter(localTime)
    ) {
      this.isFlightUnavailable = true;
    } else if (
      !day.isStartDate &&
      !day.isLoaded &&
      day.date.isAfter(localTime)
    ) {
      this.isSearchable = true;
      // now we don't have feature to search thats why we replace that case with unvailable
      this.isFlightUnavailable = true;
    }
  }

  onResize(event?: any) {
    // for init
    if (!event) {
      if (window.innerWidth >= 1131) {
        this.heightOfCell = 90;
      } else if (window.innerWidth > 500 && window.innerWidth < 1131) {
        this.heightOfCell = 81;
      } else {
        this.heightOfCell = 62;
      }
    } else {
      setTimeout(() => {
        const numberOfScrolls = this.scrollPosition / this.heightOfCell;

        if (event.target.innerWidth >= 1131) {
          this.heightOfCell = 90;
          this.maxScroll =
            (this.calendarDays.length / 7 - 5) * this.heightOfCell;
          this.scrollPosition = this.heightOfCell * numberOfScrolls;
        } else if (
          event.target.innerWidth > 500 &&
          event.target.innerWidth < 1131
        ) {
          this.heightOfCell = 81;
          this.maxScroll =
            (this.calendarDays.length / 7 - 5) * this.heightOfCell;
          this.scrollPosition = this.heightOfCell * numberOfScrolls;
        } else {
          this.heightOfCell = 62;
          this.maxScroll =
            (this.calendarDays.length / 7 - 5) * this.heightOfCell;
          this.scrollPosition = this.heightOfCell * numberOfScrolls;
        }
      }, 1000);
    }
  }

  ngOnChanges() {
    if (this.isToday.status === true) {
      for (let i = 0; i < 8; i++) {
        if (this.calendarDays[i].date.date() === this.isToday.date.date()) {
          this.calendarDays[i].date = this.isToday.date;
          this.calendarDays[i].isStartDate = true;
        } else {
          this.calendarDays[i].isStartDate = false;
        }
      }
    } else {
      this.calendarDays.forEach(day => {
        if (day.date.isSame(this.startDate, 'day')) {
          day.isStartDate = true;
        } else {
          day.isStartDate = false;
        }
      });
    }
  }
}
