import { Injectable } from '@angular/core';
import { FlightFilter } from 'app/shared';
import { Subject } from 'rxjs';

export class FilterItem {
  text: string;
  secondText: string;
  isChecked: boolean;
  count: number;
  filterBy: any;
  topDeal?: {
    count: number;
    isChecked: boolean;
  };
  imageUrl?: string;

  constructor(
    text: string,
    isChecked: boolean,
    filterBy: any,
    count?: number,
    secondText?: string,
    topDeal?: {
      count: number;
      isChecked: boolean;
    },
    imageUrl?: string
  ) {
    this.text = text;
    this.isChecked = isChecked;
    this.filterBy = filterBy;
    this.count = count;
    this.secondText = secondText;
    this.topDeal = topDeal;
    this.imageUrl = imageUrl;
  }

  get value() {
    return this.text;
  }

  get number() {
    return this.count;
  }
}

@Injectable()
export class FiltersService {
  public priceItems: FilterItem[] = [];
  public priceLowValue: number;
  public priceHighValue: number;
  public topDealsChecked: boolean = false;

  public departureTimeItems: FilterItem[] = [];
  public destinationTypeItems: FilterItem[] = [];
  public destinationItems: FilterItem[] = [];
  public stopItems: FilterItem[] = [];
  public airlineItems: FilterItem[] = [];

  public hoursItems: FilterItem[] = [];
  public timeLowValue: number;
  public timeHighValue: number;
  public typeOfAppliedFilterMobile: string;
  countOfFilters: number;

  public applyMobileFilters = new Subject<boolean>();

  public filters: FlightFilter = new FlightFilter();
  public updateHeightOfFilterContainer = new Subject<boolean>();
  public setUpFilters = new Subject<boolean>();
  public isApplied: boolean = false;

  filtersHasLength() {
    if (
      this.filters.destinations.length > 0 ||
      this.filters.destinationType.length > 0 ||
      this.filters.departureTime.length > 0 ||
      this.filters.stops.length > 0 ||
      this.filters.airlines.length > 0 ||
      this.filters.price.length > 0 ||
      this.filters.topDealsChecked ||
      this.filters.timeLeft.length > 0
    ) {
      return true;
    }
    return false;
  }

  resetFilters() {
    this.filters.airlines = [];
    this.filters.departureTime = [];
    this.filters.destinations = [];
    this.filters.destinationType = [];
    this.filters.price = [];
    this.filters.stops = [];
    this.filters.timeLeft = [];
    this.filters.topDealsChecked = false;

    this.airlineItems = [];
    this.departureTimeItems = [];
    this.destinationTypeItems = [];
    this.destinationItems = [];
    this.stopItems = [];
    this.priceItems = [];
    this.hoursItems = [];

    this.isApplied = false;
    this.countOfFilters = 0;
  }
}
