import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';

import { Booker } from 'app/shared';
import { RestService } from 'services/rest/rest.service';

export interface Option {
  value: any;
  label: any;
}

@Component({
  selector: 'app-booker',
  templateUrl: './booker.component.html',
  styleUrls: ['./booker.component.css']
})
export class BookerComponent implements OnInit {
  @Input() booker: Booker;
  @Input() form: any;
  @Input() formSubmitAttempt: boolean;

  dialCodes: Option[] = [];
  countries: Option[] = [];

  codesLoaded: boolean = false;

  constructor(
    private cdr: ChangeDetectorRef,
    private restService: RestService
  ) {
    this.cdr.detach();
  }

  ngOnInit() {
    this.restService.getCountryList().subscribe(countryCodes => {
      this.codesLoaded = true;
      this.countries = countryCodes.map(countryCode => {
        this.dialCodes.push({
          value: countryCode.dialCode,
          label: `${countryCode.name} (${countryCode.dialCode})`
        });
        return {
          value: countryCode.code,
          label: countryCode.name
        };
      });
      this.cdr.reattach();
      this.cdr.detectChanges();
    });
  }
}
