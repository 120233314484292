<div class="flight-wrapper">
  <div class="site-overlay"></div>
  <div
    class="flight-container"
    [ngClass]="{ 'active-flight-cont': state === 'active' }">
    <div class="flight-summary" (click)="toggle()">
      <div
        class="n-header-grid-wrapper"
        [ngClass]="{
          active: state === 'active',
          'border-padding': state === 'active'
        }">
        <div class="n-header-grid">
          <span class="time">{{
            flight.outboundTickets[0].departure.date | amDateFormat: 'HH:mm'
          }}</span>
          <span class="tomorrow-text subtext">
            {{ flight.outboundTickets[0].departure.date | timeSubtext }}
          </span>
          <span
            *ngIf="
              !showCityShortName &&
              flight.outboundTickets[flight.outboundTickets.length - 1].arrival
                .city === 'Santiago de Compostela'
            "
            class="destination yellow for-mobile"
            [innerHTML]="'Santiago de C.' | highlight: searchText"></span>
          <span
            *ngIf="
              !showCityShortName &&
              flight.outboundTickets[flight.outboundTickets.length - 1].arrival
                .city !== 'Santiago de Compostela'
            "
            class="destination yellow for-mobile"
            [innerHTML]="
              flight.outboundTickets[flight.outboundTickets.length - 1].arrival
                .city | highlight: searchText
            "></span>
          <span
            *ngIf="!showCityShortName"
            class="destination yellow for-desktop"
            [innerHTML]="
              flight.outboundTickets[flight.outboundTickets.length - 1].arrival
                .city | highlight: searchText
            "></span>
          <span
            *ngIf="showCityShortName"
            class="destination yellow"
            [innerHTML]="
              flight.outboundTickets[flight.outboundTickets.length - 1].arrival
                .shortCity | highlight: searchText
            "></span>
          <span
            class="country-text subtext for-desktop"
            [innerHTML]="
              flight.outboundTickets[flight.outboundTickets.length - 1].arrival
                .country | highlight: searchText
            "></span>
          <span
            *ngIf="!showCountryShortName"
            class="country-text subtext for-mobile"
            [innerHTML]="
              flight.outboundTickets[flight.outboundTickets.length - 1].arrival
                .country | highlight: searchText
            "></span>
          <span
            *ngIf="showCountryShortName"
            class="country-text subtext for-mobile"
            [innerHTML]="
              flight.outboundTickets[flight.outboundTickets.length - 1].arrival
                .shortCountry | highlight: searchText
            "></span>

          <div
            class="n-stops"
            [ngClass]="{ one: flight.outboundTickets.length !== 1 }">
            <span *ngIf="flight.outboundTickets.length - 1">{{
              flight.outboundTickets.length - 1
            }}</span>
            <span *ngIf="flight.outboundTickets.length === 1">Direct</span>
          </div>

          <div class="n-airline">
            <span *ngIf="isUndisclosed">Undisclosed</span>
            <span
              *ngIf="flight.outboundTickets[0].airline.name && !isUndisclosed"
              >{{
                multiple === true
                  ? 'Multiple airlines'
                  : flight.outboundTickets[0].airline.name
              }}</span
            >
          </div>

          <div class="n-price n-price-desktop">
            <div class="n-price-container">
              <div
                [ngClass]="{ 'n-text-box-size-with-deal': flight.isTopDeal }">
                <span class="bold"> £{{ flight.price | priceRound }} </span>
                <span
                  class="grey tiny line-through"
                  *ngIf="flight.showReferencePrice">
                  £{{ flight.priceBeforeDiscount }}
                </span>
                <span *ngIf="!flight.isOneWay" class="flight-type">return</span>
                <span *ngIf="flight.isOneWay" class="flight-type">one way</span>
                <span
                  class="boxed smaller yellow"
                  *ngIf="flight.showReferencePrice">
                  {{ '-' + flight.discount + '%' }}
                </span>
              </div>
              <div *ngIf="flight.isTopDeal" class="for-desktop">
                <!-- <img
                  class="deal-img"
                  src="../../../assets//img/icons/top-deal(4).png"
                  alt="" /> -->
                <span class="deal-tag">Top Deal</span>
              </div>
            </div>
          </div>

          <div *ngIf="flight.isTopDeal" class="n-price-top-deal">
            <span class="deal-tag">Top Deal</span>
          </div>

          <div class="n-price-sm">
            <div class="n-price-container">
              <div
                [ngClass]="{ 'n-text-box-size-with-deal': flight.isTopDeal }">
                <span class="bold"> £{{ flight.price | priceRound }} </span>
                <!-- <span *ngIf="!flight.isOneWay" class="flight-type-sm">rt</span>
                <span *ngIf="flight.isOneWay" class="flight-type-sm">ow</span> -->
                <span
                  class="line-through tiny"
                  [ngClass]="{ 'small-font': flight.priceBeforeDiscount > 999 }"
                  *ngIf="flight.showReferencePrice">
                  £{{ flight.priceBeforeDiscount }}
                </span>
              </div>
              <div *ngIf="flight.isTopDeal" class="for-desktop">
                <img
                  class="deal-img"
                  src="../../../assets//img/icons/top-deal(4).png"
                  alt="" />
              </div>
            </div>
          </div>

          <div class="n-price-notation subtext">
            <span
              class="yellow discount-mobile for-mobile"
              *ngIf="flight.showReferencePrice">
              {{ '-' + flight.discount + '%' }}
            </span>
            <div class="seats-left" *ngIf="flight.isOneWay && flight.seats && flight.seats < 5">
              <!-- <img
                class="deal-img"
                src="../../../assets//img/icons/top-deal(4).png"
                alt="" /> -->
              <span>{{flight.seats}} {{flight.seats == 1 ? 'seat' : 'seats'}}</span>
              <span class="for-desktop"> left</span>
            </div>
          </div>

          <ng-container *ngIf="!isMobile; else mobileTemplate">
            <div class="n-dep-time">
              <span
                class="n-dep-time-desktop"
                [ngClass]="{ red: flight.timeLeft.asHours() < 5 }">
                <app-show-time
                  [time]="flight.timeLeft | momentDiff"></app-show-time>
              </span>
            </div>
          </ng-container>

          <ng-template #mobileTemplate>
            <div class="n-dep-time-sm">
              <span [ngClass]="{ red: flight.timeLeft.asHours() < 5 }">
                <app-show-time
                  [time]="flight.timeLeft | momentDiff: 'isMobile'"
                  [isMobile]="true"></app-show-time>
              </span>
            </div>
          </ng-template>
          <div
            class="n-toggle-icon"
            [ngClass]="state === 'active' ? 'rotate' : ''">
            <i class="flaticon-agree yellow bold"></i>
          </div>
        </div>
      </div>

      <div class="divider"></div>
    </div>

    <div
      [ngClass]="{ 'active-flight-details': state === 'active' }"
      [@toggleAnimation]
      *ngIf="state === 'active'">
      <app-flight-details
        [flight]="flight"
        [type]="'outbound'"
        [highlightDepartureArivalDiff]="false"
        [homeOrReturnPage]="true"
        [showCountryMessage]="showCountryMessage">
        <div class="global-flight-details-nav">
          <span
            class="last-booked-viewed last-booked-animation"
            [style.animation]="!animate ? 'none' : ''"
            *ngIf="(lastBookedText || lastViewedText) && lastText">
            {{ lastText }}
          </span>
          <div
            *ngIf="!flight?.isOneWay"
            [ngClass]="{
              'change-margin': lastText !== undefined && lastText !== null
            }">
            <div class="price-per-person return-price">
              <!-- <div class="top-deal" *ngIf="flight?.isTopDeal">
                <img src="assets//img/icons/top-deal(4).png" alt="" />
              </div> -->
              <div class="ticket-conditions" (click)="showTicketConditions = true">
                Ticket Conditions
              </div>
              <div class="price-area">
                <div>
                  <div class="small-text">From</div>
                  <div class="small-text">Return per 1 Adult</div>
                </div>
                <div class="price-per-adult">
                  <app-price [value]="flight.price"></app-price>
                </div>
              </div>
            </div>

            <button
              routerLink="/wizard/return"
              [queryParams]="{ flightId: flight.id }"
              class="yellow-button"
              (click)="selectFlight(flight)">
              <span class="btn-content">
                <span class="btn-text">Go to select inbound flight</span>
                <i class="flaticon-agree"></i>
              </span>
            </button>
          </div>
          <div *ngIf="flight?.isOneWay" class="one-way-footer">
            <div class="price-per-person">
              <!-- <div class="top-deal" *ngIf="flight?.isTopDeal">
                <img src="assets//img/icons/top-deal(4).png" alt="" />
              </div> -->
              <div class="ticket-conditions" (click)="showTicketConditions = true">
                Ticket Conditions
              </div>
              <div class="price-area">
                <div>
                  <div class="small-text">One way per 1 Adult</div>
                </div>
                <div class="price-per-adult">
                  <app-price [value]="flight.price"></app-price>
                </div>
              </div>
            </div>
            <div *ngIf="innerWidth > 767">
              <button class="yellow-button" (click)="navigateToPassengerPage()">
                <span class="btn-content">
                  <span class="btn-text">Go to passenger details</span>
                  <i class="flaticon-agree"></i>
                </span>
              </button>
              <app-passengers-chooser
                [isOneWay]="true"
                (passengersUpdated)="
                  onPassengersUpdated($event)
                "></app-passengers-chooser>
            </div>
            <div *ngIf="innerWidth <= 767">
              <app-passengers-chooser
                [isOneWay]="true"
                (passengersUpdated)="
                  onPassengersUpdated($event)
                "></app-passengers-chooser>
              <button class="yellow-button" (click)="navigateToPassengerPage()">
                <span class="btn-content">
                  <span class="btn-text">Go to passenger details</span>
                  <i class="flaticon-agree"></i>
                </span>
              </button>
            </div>
          </div>
        </div>
      </app-flight-details>
      <app-ticket-conditions-popup 
        [showTicketConditions]="showTicketConditions"
        [isOneWay]="flight.isOneWay"
        [fareRulesType]="flight.contentProvider"
        (toggleShowTicketConditions)="showTicketConditions = $event"
      />
    </div>
  </div>
</div>
