import {
  Directive,
  Input,
  ElementRef,
  Renderer2,
  AfterViewInit
} from '@angular/core';

@Directive({
  selector: '[appHighlightText]'
})
export class HighlightTextDirective implements AfterViewInit {
  @Input() charactersToHighlight: number = 0;
  @Input() highlightColor: string = 'yellow';

  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) {}

  ngAfterViewInit() {
    const text = this.el.nativeElement.innerText.trim();

    const highlightedText =
      this.charactersToHighlight > 0
        ? this.highlightText(
            text,
            this.charactersToHighlight,
            this.highlightColor
          )
        : text;
    this.renderer.setProperty(
      this.el.nativeElement,
      'innerHTML',
      highlightedText
    );
  }

  highlightText(
    text: string,
    charactersToHighlight: number,
    highlightColor: string
  ): string {
    if (!text || charactersToHighlight <= 0) {
      return text;
    }

    const highlightedPart = `<span style="background-color: ${highlightColor}">${text.substr(0, charactersToHighlight)}</span>`;
    const remainingPart = text.substr(charactersToHighlight);

    return `${highlightedPart}${remainingPart}`;
  }
}
