import { Component, OnInit } from '@angular/core';
import { FlightBoardService } from 'app/services/flight-board/flight-board.service';

@Component({
  selector: 'app-popular-destinations',
  templateUrl: './popular-destinations.component.html',
  styleUrls: ['./popular-destinations.component.css']
})
export class PopularDestinationsComponent implements OnInit {
  destinations: Array<object>;

  constructor(private flightBoardService: FlightBoardService) {}

  ngOnInit() {
    this.destinations = this.flightBoardService.destinations;
  }
}
