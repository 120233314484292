<div class="main-cont" [ngSwitch]="typeOfTable">
  <ng-templete *ngSwitchCase="1" class="main">
    <div class="type">
      <p>{{ paxType }} Fare (p.p.):</p>
      <p>Taxes (p.p.):</p>
      <p>Discount (p.p.):</p>
      <p>Subtotal (p.p.):</p>
      <p>Pax No.:</p>
      <p [ngClass]="{ bold: grandTotal === true }">Total:</p>
    </div>
    <div class="price">
      <p><app-price [value]="data.fare"></app-price></p>
      <p><app-price [value]="data.taxes"></app-price></p>
      <p><app-price [value]="data.discount"></app-price></p>
      <p><app-price [value]="data.subTotal"></app-price></p>
      <p>{{ data.count }}</p>
      <p [ngClass]="{ bold: grandTotal === true }">
        <app-price [value]="data.total"></app-price>
      </p>
    </div>
  </ng-templete>

  <ng-templete *ngSwitchCase="2" class="main">
    <div class="type">
      <p>{{ paxType }} Fare (p.p.):</p>
      <p>Taxes (p.p.):</p>
      <p>Service Fee (p.p.):</p>
      <p>Subtotal (p.p.):</p>
      <p>Pax No.:</p>
      <p>Total:</p>
    </div>
    <div class="price">
      <p><app-price [value]="data.fare"></app-price></p>
      <p><app-price [value]="data.taxes"></app-price></p>
      <p><app-price [value]="data.serviceFee"></app-price></p>
      <p><app-price [value]="data.subTotal"></app-price></p>
      <p>{{ data.count }}</p>
      <p><app-price [value]="data.total"></app-price></p>
    </div>
  </ng-templete>

  <ng-templete *ngSwitchCase="3" class="main">
    <div class="type">
      <p>{{ paxType }} Price (p.p.):</p>
      <p>Taxes (p.p.):</p>
      <p>Subtotal (p.p.):</p>
      <p>Pax No.:</p>
      <p>Total:</p>
    </div>
    <div class="price">
      <p><app-price [value]="data.price"></app-price></p>
      <p><app-price [value]="data.taxes"></app-price></p>
      <p><app-price [value]="data.subTotal"></app-price></p>
      <p>{{ data.count }}</p>
      <p><app-price [value]="data.total"></app-price></p>
    </div>
  </ng-templete>
</div>
